@import 'src/styles/variables';
@import 'src/styles/mixins';

.userItem {
  display: flex;
  align-items: center;
  padding: 10px 0 8px;
  border-bottom: 1px solid $theme-color-gray;
  font-family: Roboto Condensed, sans-serif;
  &__report {
    cursor: pointer;
    padding: 3px;
  }

  &__textProject {
    text-align: start;
    display: flex;
    align-items: center;

    flex-wrap: wrap;
    &-inner {
      display: flex;
      align-items: center;
      margin-right: 15px;
      white-space: nowrap;
    }
  }
  &__icons {
    min-width: 10px;
    min-height: 10px;
  }
  &__col {
    position: relative;
    box-sizing: border-box;
    width: 14%;
    display: flex;
    flex-direction: column;
    padding-left: 28px;

    &:first-child {
      padding-left: 0;
    }

    & .userItem__content {
      @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
      text-align: left;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &--status {
        display: flex;
        align-items: center;
      }
    }
  }

  &:last-child {
    border-bottom: none;
  }

  &__statusIndicator {
    width: 8px;
    height: 8px;
    margin-right: 5px;
    border-radius: 50%;

    &--Admin {
      background-color: $theme-color-red;
    }

    &--Moderator {
      background-color: $theme-color-violet;
    }

    &--User {
      background-color: $theme-color-green;
    }
    &--Guest {
      background-color: #d9cd22b0;
    }
  }
}

@media (max-width: 1440px) {
  .userItem {
    &__col {
      width: 19%;
      padding-left: 5px;
    }
  }
}
