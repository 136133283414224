@import '../../styles/variables';
@import '../../styles/mixins';

.header {
  display: flex;
  background: $theme-color-black;
  height: 45px;
  width: 100%;
  &__linkWrapper {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &__logo {
    max-width: 240px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      cursor: pointer;
    }
  }

  &__right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 15px;
    @include text($text-secondary, $font-family, $f-size16, 19px, 300, normal);

    img {
      cursor: pointer;
    }

    & p {
      margin: 0;
    }

    .header__create {
      display: flex;
      align-items: center;
    }

    .header__line {
      height: 20px;
      margin: 15px 25px;
      border-left: 1px solid $theme-color-darkGray;
    }

    .header__actions {
      display: flex;
      align-items: center;
      padding-right: 30px;

      .header__link {
        text-decoration: none;
        color: $text-secondary;
        display: flex;
        align-items: center;
        &:hover {
          text-decoration: none;
          color: $theme-color-darkGray;

          &:active {
            color: $theme-color-darkGray;
          }
        }
      }
    }
  }
}

.MyPersonalPanel__modal {
  display: flex;
  position: absolute;
  background-color: black;
  right: 20px;
  top: 45px;
  width: 221px;
  border: none;
  outline: none;
}

.MyPersonalPanel__modal-overlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}
