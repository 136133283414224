@import 'src/styles/variables';
@import 'src/styles/mixins';

.expensesTagCell {
  display: flex;
  padding-right: 5px;
  width: 20%;

  & .tag__item {
    margin-left: 10px;
    cursor: default;
  }

  & .tag__text:hover {
    cursor: default;
  }
}
