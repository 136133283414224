@import 'src/styles/variables';
@import 'src/styles/mixins';

.TredFileList {
  box-sizing: border-box;

  &__table {
    padding: 0 20px;
    margin-top: 10px;
  }

  &__row {
    display: flex;
    min-height: 100px;
  }
  &__column {
    box-sizing: border-box;
    padding: 8px;
    border-left: 1px solid $theme-color-lightGray;
    border-bottom: 1px solid $theme-color-lightGray;
    border-top: 1px solid $theme-color-lightGray;
    text-align: left;
    @include text($text-primary, $font-family, $f-size20, 23px, normal, normal);
    width: 35%;
    &:last-of-type {
      border-right: 1px solid $theme-color-lightGray;
    }
  }
  &__border {
    border-right: 1px solid #303030;
  }
  &__selector {
    position: absolute;
    right: -15px;
    top: 0px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-color: rgb(206, 206, 206);
    margin-right: 15px;
    border-radius: 50%;
  }
}
