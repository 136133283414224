@import 'src/styles/variables';
@import 'src/styles/mixins';

.circles {
  width: 100%;
  background-color: #efefef;
  overflow-x: auto;
  min-height: 88vh;
  position: relative;
  overflow-y: hidden;
  &__element {
    position: absolute;

    width: 110px;
    height: 110px;
    z-index: -999;
  }
  &__panel {
    position: relative;
    min-height: calc(88vh - 50px);
    display: flex;
    align-items: center;
    justify-content: center;
    &-inner {
      position: relative;
      width: auto;
    }
    padding-top: 3px;
  }
  &__block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgb(112, 108, 108);
    border-radius: 3px;
  }
  &__inner {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }
  &__half {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background-color: rgba(255, 0, 0, 0.5);
    }
  }
  &__title {
    &-item {
      position: absolute;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }
  &__letter1 {
    position: absolute;
    top: 0px;
    left: 5px;
    text-transform: uppercase;
    cursor: default;
  }
  &__letter2 {
    position: absolute;
    top: 0px;
    right: 5px;
    text-transform: uppercase;
    cursor: default;
  }
  &__letter3 {
    position: absolute;
    bottom: 0px;
    left: 5px;
    text-transform: uppercase;
    cursor: default;
  }
  &__letter4 {
    position: absolute;
    bottom: 0px;
    right: 5px;
    text-transform: uppercase;
    cursor: default;
  }
}
