@import 'src/styles/variables';
@import 'src/styles/mixins';

.description {
  &__wrapper {
    position: absolute;
    right: 0;
    top: 0;
    background-color: white;
    height: calc(100vh - 55px);
    border-left: 1px solid $theme-color-gray;
    overflow-y: hidden;
    @include text($text-primary, $font-family, $f-size14, 16px, 500, normal);
    .project-block-item {
      margin: 0;
      padding: 0 10px 10px;
    }

    &_open {
      width: 440px;
      z-index: 1;
      &:hover {
        overflow-y: auto;
      }
    }
    &_close {
      width: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-tasks {
      padding: 0 0px 10px 0px;
    }
  }
  &__button {
    background: #303030;
    height: 68px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__icons {
    margin-top: 40px;
    cursor: pointer;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    &-tabs {
      display: flex;
      width: 100%;
    }
    &-tab {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      cursor: pointer;
      border-bottom: 4px solid white;
      @include text($theme-color-darkGray, $font-family, $f-size20, 32px, 400, normal);

      svg {
        margin: 0 8px;
      }

      &_active {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f5f5f5;
        border-bottom: 4px solid #149939;
        cursor: pointer;
        @include text($text-primary, $font-family, $f-size20, 32px, 400, normal);

        svg {
          margin: 0 8px;
        }
      }
    }
    &-arrow {
      height: 60px;
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #303030;
      cursor: pointer;
      border-bottom: 4px solid #303030;
      &_right {
        transform: rotate(180deg);
      }
    }
    &-element {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      font-style: normal;

      &:last-child {
        margin-left: 10px;
      }
    }
  }
  &__instruments {
    &-icons {
      margin-right: 10px;
      cursor: default;
    }
  }
}

.desc__container {
  padding: 15px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #efefef;
    z-index: -1;
  }
}

.elements {
  &__wrapper {
    box-sizing: border-box;
    width: 100%;
    padding: 0 20px;
  }
  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &__name {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
  &__button {
    cursor: pointer;
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 50px;
      height: 36px;
      background-color: #303030;
    }
  }
  &__line {
    &-vertical {
      width: 1px;
      background-color: #ffffff33;
      height: 100%;
      float: left;
    }
    &-horizontal {
      width: 100%;
      background-color: #35313133;
      height: 1px;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
  &__content {
    text-align: initial;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    white-space: pre-wrap;
  }
}
