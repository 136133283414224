@import "src/styles/variables";
@import "src/styles/mixins";


.toggle__wrapper {
  display: flex;
  padding-bottom: 1.2em;

  input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
  }

  input[type=checkbox]:focus {
    outline: 0;
  }

  label {
    cursor: pointer;
    color: $text-primary;
    font-size: $f-size16;
    padding: 0 0 0.1em 0.2em;
  }

  .toggle {
    height: 13.5px;
    width: 23px;
    border-radius: 16px;
    display: inline-block;
    position: relative;
    margin-top: 3px;
    border: 1px solid $text-secondary;
    background: $text-secondary;
    transition: all 0.2s ease;
    box-shadow: 0 1px 2px rgba(48, 48, 48, 0.05);
  }

  .toggle:after {
    content: "";
    position: absolute;
    top: 1.1px;
    left:1px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: $theme-color-darkGray;
    box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
    transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
  }

  .toggle:checked {
    border-color: $text-active;
  }

  .toggle:checked:after {
    transform: translatex(9px);
  }
}