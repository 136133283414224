@import 'src/styles/variables';
@import 'src/styles/mixins';

.userStatisticBlockHeader {
  background-color: $text-primary;
  padding: 0 15px 0 15px;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 10px;

  .horizontalLine {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin: 10px 0px;
  }
  .tasks {
    &__line-wrapper {
      display: flex;
      justify-content: space-between;
    }
    &__item-number {
      margin-left: 5px;
      @include text($text-secondary, $font-family, 16px, 19px, 500, normal);
    }
    &__item-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .verticalLine {
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      margin-left: 25px;
      margin-right: 25px;
    }
  }
  .user {
    &__wrapper {
      display: flex;
      justify-content: space-between;
    }
    &__action {
      display: flex;
      justify-content: center;
      align-items: center;
      &-plus {
        margin-left: 20px;
      }
    }
    &__avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      cursor: pointer;
      &-image {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }

      &-name {
        margin-left: 10px;
        @include text($text-secondary, $font-family, 16px, 19px, 500, normal);
      }
    }
    &__status {
      position: relative;
      &-indicator {
        width: 10px;
        height: 10px;
        position: absolute;
        bottom: 2px;
        right: 1px;
        border-radius: 50%;
        border: 1px solid white;
      }
    }
  }
}
