@import 'src/styles/variables';
@import 'src/styles/mixins';

.chat__item {
  width: 100%;
  display: flex;
  height: 15px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  background-color: $text-secondary;
  text-decoration: none;
  color: #303030;

  &:hover {
    background-color: $section-primary;
    img {
      box-shadow: 0 0 15px -5px #000;
    }
  }

  &:last-child {
    border-bottom: none;
  }

  .chat__item--name {
    display: flex;
    align-items: center;
    position: relative;
    text-align: start;
    padding-right: 5px;

    img {
      border-radius: 50%;
      margin: 0 5px;
    }
  }

  .item__count {
    display: flex;
    span {
      padding-right: 5px;
    }
  }
}
