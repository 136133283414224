.DecideOnTheFlyLogs {
  padding: 20px;
  width: 875px;
  box-sizing: border-box;
  &__warning {
    color: #303030;
    font-family: Roboto Condensed;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    margin-top: 20px;
    text-align: center;
  }
}
