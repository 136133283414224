.AvatarLoad {
  &__errors {
    width: 100%;
    top: 100%;
    font-size: 12px;
    padding: 1px 0 0 4px;
    color: #dc3545;
    text-align: center;
    padding-top: 3px;
  }
  &__avatar {
    &-wrapper {
      padding: 20px;
      background: #b8c3d2;
    }
    &-wrapperOuter {
      width: 320px;
      height: 320px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    &-buttons {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      width: 100%;
      padding-right: 50px;
      margin-top: 10px;
      position: absolute;
      bottom: -0px;
      left: -30px;
    }
    &-label {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;

      color: #303030;
    }
    min-width: 220px;
    width: 220px;
    height: 220px;
    border-radius: 50%;
  }
}
