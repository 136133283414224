@import 'src/styles/variables';

.wrapper {
  text-align: center;
  padding: 0 10px 10px 10px;
  background-color: $theme-color-black;

  .block {
    display: flex;
    height: calc(100vh - 55px);
    position: relative;
  }
}

p {
  margin: 0;
}
