@import 'src/styles/variables';
@import 'src/styles/mixins';

.ForumTreadsList {
  font-family: 'Roboto Condensed';
  margin-right: 20px;
  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
  }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__navigations {
    display: flex;
    background: #fbfbfb;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 20px;
    nav {
      border: none;
      .pagination {
        justify-content: flex-start;
        padding: 10px;
      }
    }
  }
  &__list {
    min-height: calc(#{$forum-page-heigth} - 202px);
    overflow: auto;
  }
}
