.CircleAddMeetModal {
  padding: 20px;
  &__buttons {
    display: flex;
    justify-content: space-between;
  }
  &__title {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
  }
}
