@import 'src/styles/variables';
@import 'src/styles/mixins';

.link-add-modal {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  padding: 18px;
  max-width: 325px !important;
  width: 325px;
  z-index: 10;
  min-width: 325px;
  max-height: 80vh;
  overflow-y: auto;
  .default__button {
    width: 99% !important;
  }
  & button {
    margin-top: 10px;
  }
  .pagination__item {
    text-align: center;
  }

  &__links {
    &-title {
      @include text($text-primary, 'Roboto Condensed', 14px, 16px, 500, normal);
    }
    &-title2 {
      @include text($text-primary, 'Roboto Condensed', 14px, 16px, 500, normal);
      margin-bottom: 10px;
      cursor: pointer;
    }
    &-arrow {
      transform: rotate(90deg);
      margin-right: 15px;
    }
  }
  &__item {
    display: flex;
    background-color: #f5f5f5;
    align-items: center;
    margin-top: 10px;
    padding: 5px 10px;

    &-icons {
      margin-right: 10px;
      cursor: auto;
    }
    &-text {
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .add-more {
    &__wrapper {
      display: flex;
      margin-top: 20px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    &__text {
      @include text($text-primary, 'Roboto Condensed', 14px, 16px, 300, normal);
      border-bottom: 1px solid #afafaf;
      width: 95%;
    }
    &__or {
      text-align: center;
      margin: 10px 0px;
    }
  }
}
