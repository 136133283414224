@import 'src/styles/variables';

.left-align-text-icon {
  &:hover {
    background-color: $theme-color-darkGray;
  }
}

.right-align-text-icon {
  transform: scale(-1, 1);
  &:hover {
    background-color: $theme-color-darkGray;
  }

  &_active {
    background-color: $theme-color-darkGray;
  }
}
