.IsExcludeNullStatusTasks {
  display: flex;
  margin-right: 5px;
  &__star {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  .buttonSelect {
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .buttonSelectBlack {
    background-color: black;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
  }
}
