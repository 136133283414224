@import 'src/styles/variables';
@import 'src/styles/mixins';

.dropdown__content {
  width: 221px;
  padding-top: 25px;
  overflow: auto;
  background-color: $theme-color-black;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.4);

  .content__flex {
    width: 181px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .user__head {
      display: flex;
      align-items: center;
      width: 100%;
      padding-bottom: 20px;
      position: relative;

      .avatar__image-upload {
        border-radius: 50%;
        input {
          display: none;
          position: absolute;
          visibility: hidden;
          left: 0;
          width: 60px;
          height: 60px;
          z-index: 1000;
        }
      }
      .avatar__upload {
        position: absolute;
        left: 0;
        top: 0;
        display: none;
        opacity: 0.8;
        pointer-events: all;
        border-radius: 50%;

        z-index: 3;
      }

      .user__head-avatar:hover .avatar__upload {
        display: block;
      }

      .user__head-avatar {
        border-radius: 50%;
        width: 60px;
        height: 60px;
      }

      & img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        cursor: pointer;
      }

      & span {
        @include text($text-secondary, $font-family, $f-size20, 23px, 300, normal);
        cursor: default;
      }

      .user__info {
        padding-left: 17px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .user__info-name {
          padding-left: 0;
          padding-right: 0;
          @include text($text-secondary, $font-family, $f-size14, 16.41px, 300, normal);

          background: transparent;
          border: none;
          outline: none;
          max-width: 100px;
        }
      }
    }

    .user__note {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 221px;
      height: 30px;
      cursor: default;
      background-color: $theme-color-lightGray;
      &-inner {
        width: 100%;
        text-align: center;
        @include text($text-primary, $font-family, $f-size14, 16px, 400, normal);
      }
    }

    .user__actions {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 15px 0 5px 0;

      span {
        @include text($text-secondary, $font-family, $f-size14, 16px, 300, normal);
        padding: 5px 0;
        cursor: pointer;
      }

      a {
        text-decoration: none;
        @include text($text-secondary, $font-family, $f-size14, 16px, 300, normal);

        &:hover {
          text-decoration: none;
          color: $theme-color-darkGray;

          &:active {
            color: $theme-color-darkGray;
          }
        }
      }

      & span:hover {
        color: $theme-color-darkGray;
      }
    }

    .user__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-bottom: 15px;

      button {
        &:first-child {
          margin-bottom: 1px;
        }
      }
    }
  }
}
