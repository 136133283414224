@import 'src/styles/variables';
@import 'src/styles/mixins';

.tredOpenImageModal {
  overflow: auto;
  padding: 5px;
  &__image {
    max-width: 90vw;
    max-height: 90vh;
  }
  &__text {
    padding: 10px 20px 10px;
    font-family: Roboto Condensed;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
