@import 'src/styles/variables';
@import 'src/styles/mixins';

.userStatisticBlockItem {
  display: flex;

  border-bottom: 1px solid $theme-color-gray;
  padding-bottom: 10px;
  @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
  margin: 10px 10px 10px;
  &__left {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__name {
    margin-left: 15px;
    text-align: initial;
  }

  .popover {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    &-item {
      width: 130px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    &-wrapper {
      position: relative;
      &:hover {
        .popover-inner {
          display: flex;
          left: 0;
          top: 0;
          left: -165px;
          top: 15px;
        }
      }
    }
    &-inner {
      display: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 190px;
      height: 85px;
      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25), 0px 1px 5px rgba(48, 48, 48, 0.1);
      z-index: 9999;
    }
    &-icons {
      margin-right: 20px;
    }
  }
}
