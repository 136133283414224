@import '../../styles/variables';
@import '../../styles/mixins';

.mainContentHeaderItem {
  width: 33%;
  text-align: start;
  display: flex;
  align-items: center;

  .mainContentHeaderItem__title {
    border-left: 1px solid $theme-color-darkGray;
    @include text($text-primary, $font-family, $f-size20, 23px, normal, normal);
    padding-left: 10px;
  }

  .mainContentHeaderItem__title--button {
    @include text($text-secondary, $font-family, $f-size20, 23px, normal, normal);
    cursor: pointer;
    background-color: $text-primary;
    padding: 5px 5px 5px 11px;
    border-left: none;
  }
}
