@import 'src/styles/variables';
@import 'src/styles/mixins';

.projectBlock__headerArchive {
  background-color: $text-secondary;
  color: $text-primary;
  padding: 15px 15px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: relative;
}

.projectBlock__header {
  background-color: $text-primary;
  padding: 0 15px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: relative;
}
