@import 'src/styles/variables';
@import 'src/styles/mixins';

.chat__message {
  border: 1px solid lightgray;
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;

  .message__karma {
    &-wrapper {
      border-left: 1px solid $theme-color-darkGray;
      line-height: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-minus {
      margin: 0px 10px;
      display: flex;
      align-items: center;
    }
    &-plus {
      margin: 0px 10px;
      display: flex;
      align-items: center;
    }
    &-count {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #303030;
      cursor: pointer;
    }
  }

  .message__info {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    .message__user {
      display: flex;
      align-items: center;
      @include text($text-primary, $font-family, $f-size12, 14px, 300, normal);
      margin-left: 2px;

      img {
        width: 16px;
        height: 16px;
        border-radius: 50%;
      }

      span {
        padding: 0 5px;
      }
    }

    .message__date {
      display: flex;
      align-items: center;

      span {
        @include text($theme-color-darkGray, $font-family, $f-size12, 14px, 300, normal);
        border-left: 1px solid $theme-color-darkGray;
        padding: 0 5px;
      }
      img {
        margin-bottom: 3px;
      }
    }

    .message__delete {
      display: flex;
      align-items: center;
      margin-left: 5px;
    }
  }

  .message__text {
    text-align: start;
    padding: 0 5px;
    word-break: break-word;

    p {
      @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
      margin: 0;
      padding: 0;
      white-space: pre-wrap;
    }

    p.bold {
      font-weight: bold;
    }
  }
}
