.ChronometerTaskMonth {
  padding: 20px 20px;
  @media (max-width: 1550px) {
    padding: 20px 10px;
  }
  &__hideIcon {
    display: block;
    @media (max-width: 1250px) {
      display: none;
    }
  }
  width: 100%;
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1550px) {
      flex-wrap: wrap;
      .default__button {
        width: 100% !important;
        margin: 20px 0px !important;
      }
    }
    &-name {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: #303030;
    }
    &-switch {
      display: flex;
      align-items: center;
      margin-left: auto;
      @media (max-width: 1370px) {
        margin-left: 0px;
      }
      .switch {
        margin: 0px 10px;
      }
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      color: #303030;
    }
  }
  &__time {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #303030;
    white-space: nowrap;
    width: 42px;
    margin-left: 3px;
    display: flex;
  }
  .plan {
    &__row {
      display: flex;
    }
    &__titleCol1 {
      @media (max-width: 1370px) {
        width: 37% !important;
      }
    }
    &__titleCol2 {
      margin-left: 5px;
      margin-right: 5px;
      @media (max-width: 1370px) {
        margin-left: 0px;
        margin-right: 0px;
        width: 37% !important;
      }
    }
    &__titleCol {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      color: #303030;
      margin-bottom: 20px;
      text-align: start;
    }
    &__itemCol {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #303030;
      display: flex;
      min-height: 30px;
      align-items: center;
    }
    &__checkCol {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      border-left: 1px solid #efefef;
      border-right: 1px solid #efefef;
      padding: 0px 25px;
      cursor: pointer;
      @media (max-width: 1670px) {
        padding: 0px 15px;
      }
      @media (max-width: 1470px) {
        padding: 0px 5px;
      }
      margin: 0px 5px;
      min-height: 30px;
      align-items: center;
      // fix safari
      transition: 0.01ms;
    }
    &__gray {
      background-color: #f5f5f5;
      min-height: 15px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 5px;
    }
    &__col1 {
      width: 40%;

      display: flex;
      justify-content: flex-start;
      overflow: hidden;
      &-first {
        box-sizing: border-box;
        border-left: 1px solid #efefef;
        padding-left: 10px;
      }

      svg {
        min-width: 16px;
        min-height: 16px;
      }
    }
    &__col2 {
      width: 15%;
      overflow: hidden;
    }
    &__col3 {
      width: 45%;
      display: flex;
      justify-content: flex-start;
      text-align: start;
      overflow: hidden;
      svg {
        min-width: 16px;
        min-height: 16px;
      }
    }
  }
}
