$col1Width: 30%;

.planChronoModal {
  padding: 20px;
  width: 630px;
  box-sizing: border-box;
  font-family: Roboto Condensed;
  &__input {
    color: #afafaf;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    display: flex;
    border-bottom: 1px solid #afafaf;
    width: 80%;
  }
  .appInput__wrapper {
    margin-bottom: 0px;
    border-bottom: none;
    .appInput__field {
      padding: 0px;
    }
    .appInput__text-error {
      left: -45px;
      width: 100px;
    }
  }
  &__title {
    color: #303030;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 15px;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
  }
  &__table {
    max-height: 500px;
    overflow-y: auto;
    width: 100%;
    margin-bottom: 35px;
    &-head {
      display: flex;
      width: 100%;
      color: #303030;
      font-size: 14px;
      font-weight: 300;
      .planChronoModal__table-col {
        height: 18px;
      }
    }
    &-body {
      .planChronoModal__table-col1 {
        border-right: 1px solid #f5f5f5;
        color: #303030;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
      }
      .planChronoModal__table-col2 {
        border-right: 1px solid #f5f5f5;
      }
      .planChronoModal__table-col3 {
        border-right: 1px solid #f5f5f5;
      }
    }
    &-cell {
      width: 100%;
      display: flex;
      color: #303030;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
    }
    &-col {
      width: 25%;
      box-sizing: border-box;
      height: 42px;
      display: flex;
      align-items: center;
    }
    &-col1 {
      width: 40%;
    }
    &-col2 {
      width: calc(((100% - #{$col1Width}) / 3) + 3%);
      justify-content: center;
    }
    &-col3 {
      width: calc(((100% - #{$col1Width}) / 3) - 8%);
      justify-content: center;
    }
    &-col4 {
      width: calc(((100% - #{$col1Width}) / 3) + 5%);
      padding-left: 3%;
    }
  }
}
