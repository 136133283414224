.DecideOnTheFlyCircle {
  width: 570px;
  height: 570px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &__shadow {
    box-sizing: border-box;
    width: 570px;
    height: 570px;
    border: 60px solid rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    position: absolute;
    top: 0px;
    left: 0px;
    background: transparent;
  }
}
.ContainerСircle__buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.ContainerСircle__count {
  color: #afafaf;
  text-align: center;
  font-family: Roboto Condensed;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 160px;
  height: 30px;
  border-radius: 2px;
  background: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ContainerСircle__count-item {
  color: #303030;
  font-family: Roboto Condensed;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 3px;
}

.ContainerСircle {
  position: relative;
}
