@import 'src/styles/variables';
@import 'src/styles/mixins';

.sort {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;
  width: 330px;

  &__title {
    margin-right: 5px;
    @include text($text-primary, $font-family, $f-size16, 19px, normal, normal);
  }
}

.sortUser {
  &__option {
    position: relative;

    &::after {
      position: absolute;
      right: 10px;
      top: 2px;
      width: 10px;
      height: 10px;
    }
  }
}
