.DecideOnTheFlyCreate {
  padding: 40px;
  box-sizing: border-box;
  min-height: 88vh;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  &__head {
    display: flex;
    color: #303030;
    text-align: center;
    font-family: Roboto Condensed;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 20px;
  }
  &__title {
    color: #303030;
    text-align: center;
    font-family: Roboto Condensed;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    text-align: start;
  }
  &__getBack {
    background-color: #303030;
    width: 240px;
    max-width: 100%;
    height: 32px;
    min-height: 32px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    font-family: Roboto Condensed;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 40px;
  }
  &__controll {
    display: flex;
    color: #303030;
    font-family: Roboto Condensed;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    &-item {
      margin-right: 15px;
      border-bottom: 3px solid white;
      cursor: pointer;
    }
    &-select {
      border-bottom: 3px solid #149939;
    }
  }
  &__half {
    display: flex;
    flex-direction: column;
    width: 49%;
    &-outer {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
  &__element {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  &__item {
    display: flex;
    width: 48%;
    flex-direction: column;
    &-head {
      color: #303030;
      text-align: center;
      font-family: Roboto Condensed;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-align: start;
      margin-bottom: 20px;
    }
  }
}
