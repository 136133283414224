@import 'src/styles/variables';
@import 'src/styles/mixins';

.start-button {
  .start-button__dropdown {
    display: flex;
    position: relative;

    .start-button__placeholder {
      display: flex;
      align-items: center;
      background-color: $text-primary;
      padding: 2px 10px;
      cursor: pointer;
      position: relative;

      svg {
        path {
          stroke: $text-secondary;
        }
      }

      & span {
        padding-left: 10px;
        @include text($text-secondary, $font-family, $f-size12, 14px, 300, normal);
      }
    }

    .start-button__options {
      display: flex;
      flex-direction: column;
      border: 1px solid black;
      position: absolute;
      top: 20px;
      right: 0;
    }
  }
}

.start-button__modal-overlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.start-button__modal {
  display: flex;
  position: absolute;
  background-color: black;
  right: 425px;
  top: 112px;
  border: none;
  outline: none;

  .start-button__options {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid black;
  }
}
