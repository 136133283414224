@import 'src/styles/variables';
@import 'src/styles/mixins';

.palette {
  padding: 3px 0 3px 10px;
  border-left: 1px solid $theme-color-darkGray;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  label {
    padding: 2px;
    background-color: $text-secondary;
  }

  .palette__button-submit{
    padding-left: 10px;
    display: flex;
  }

  .palette__box {
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}