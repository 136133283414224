@import 'src/styles/variables';

.long-arrow-top-icon {
  &:hover {
    border-left: none;
  }
}

.long-arrow-bottom-icon {
  transform: rotate(180deg);
}

.long-arrow-top-right-icon {
  transform: rotate(35deg);
  font-weight: 300;
}