@import 'src/styles/variables';
@import 'src/styles/mixins';

.polygonList {
  padding: 15px 20px 0;
  min-height: 88vh;
  background-color: #ffffff;

  .pagination-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .verticalLine {
    height: 100%;
    border-left: 1px solid #afafaf;
    margin-right: 10px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid $theme-color-gray;
  }

  &__title {
    box-sizing: border-box;
    position: relative;
    width: $polygon-table-col2;
    margin: 0 0 18px 0;
    padding-left: 28px;
    @include text($text-primary, $font-family, $f-size20, 23px, normal, normal);
    text-align: left;

    &:first-child {
      width: $polygon-table-col1;
      padding-left: 0;

      &::before {
        width: 0;
      }
    }

    &:last-child {
      width: $polygon-table-col3;
    }
  }

  &__text {
    padding: 100px 0;
    @include text($text-primary, $font-family, $f-size20, 23px, normal, normal);
  }
  &__general {
    font-size: 26px;
  }
}
