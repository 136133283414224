@import 'src/styles/variables';
@import 'src/styles/mixins';

.ForumTreadItem {
  padding: 40px 20px 20px;
  .ForumUserHover {
    align-items: center;
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
  }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__arrow {
    transform: rotate(180deg);
    margin-right: 15px;
    &-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 121%;
      cursor: pointer;
    }
  }
  nav {
    border: none;
    .pagination {
      justify-content: flex-start;
      background: #fbfbfb;
      padding: 10px;
    }
  }
  &__list {
    min-height: calc(#{$forum-page-heigth} - 202px);
    overflow: auto;
    margin-top: 20px;
  }

  .HoverMenu {
    &__wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.ql-editor {
  padding: 0px 0px;
  img {
    float: left;
    width: 60%;
  }
  .iframe {
    width: 60%;
  }
}
.ql-align-right {
  img {
    float: right;
    width: 60%;
  }
}
.ql-editor .ql-blank {
  height: 300px !important;
}

.ql-align-center {
  img {
    float: none;
  }
}

.listEditor {
  &__wrapper {
    background-color: white;
    display: flex;
    flex-direction: row;
    .ql-container,
    .ql-editor {
      min-height: 240px;
    }
  }
}
