$width: 145px;

.TreadItemImage {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, $width);
  grid-template-rows: calc(#{$width} + 20px);
  max-height: 20vh;
  overflow-y: auto;
  margin-bottom: 10px;
  position: relative;
  &__all {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__imge {
    width: calc(#{$width} - 5px);
    height: calc(#{$width} - 5px);
    background-size: cover;
    background-repeat: no-repeat;
    margin: 2px;
    box-sizing: border-box;
    background-position: center;
  }
  &__item {
    width: $width;
    height: calc(#{$width} + 20px);
    box-sizing: border-box;
    background: #fbfbfb;
    border: 1px solid #efefef;
    border-radius: 4px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #303030;
    cursor: pointer;

    &-inner {
      display: flex;
      align-items: center;
    }
  }
}
