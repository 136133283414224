@import 'src/styles/variables';
@import 'src/styles/mixins';

.projectBlock {
  background-color: $text-secondary;
  display: flex;
  flex-direction: column;

  &:hover {
    .projectBlock__items {
      border-left-color: $text-primary;
      border-right-color: $text-primary;
    }

    nav {
      border-left-color: $text-primary;
      border-right-color: $text-primary;
    }

    .projectBlock__line {
      border-bottom: 1px solid $text-primary;
    }
  }

  &__items {
    border-left: 1px solid $text-secondary;
    border-right: 1px solid $text-secondary;
  }

  &__missingProjects {
    padding: 15px 0;
    margin-left: 5px;
    @include text($text-primary, $font-family, 16px, 19px, 300, normal);
    text-align: start;
  }

  &__line {
    border-bottom: 1px solid $text-secondary;
  }
}