.FileFonAndTagsModal {
  width: 525px;
  padding: 20px;
  font-family: Roboto Condensed;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
  &__list {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  &__title1 {
    font-size: 16px;
  }
  &__title2 {
    margin-top: 20px;
  }
  &__buttonsWraper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
