.changeDay {
  font-family: 'Roboto Condensed';
  display: flex;
  margin-top: 25px;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  &__day {
    cursor: pointer;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid #efefef;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    @media (max-width: 1370px) {
      margin-right: 2px;
    }
    &-text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #303030;
    }
    &-date {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #303030;
    }
  }
  &__select {
    background-color: black;
    .changeDay__day-text,
    .changeDay__day-date {
      color: white;
    }
  }
}
