@import 'src/styles/variables';
@import 'src/styles/mixins';

.textarea {
  display: flex;
  overflow-y: visible;
  align-items: flex-start;
  box-sizing: border-box;
  margin-top: 10px;
  background-color: $text-secondary;
  border: 2px solid $theme-color-darkGray;

  textarea {
    width: calc(100vw - 388px);
    min-height: 16px;
    padding: 10px 5px;
    resize: none;
    border: none;
    outline: none;
    background-color: $text-secondary;
    @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
  }

  img {
    padding: 10px;
    cursor: pointer;
  }

  input {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}
