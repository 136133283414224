$heighthead: 200px;
$height: calc((90vh - #{$heighthead} - 30px) / 25);
.pulsometerModals {
  padding: 30px;
  max-width: 90vw;
  min-width: 640px;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  overflow-y: hidden;
  height: 90vh;
  padding-left: 5px;
  display: flex;
  * {
    box-sizing: border-box;
  }

  &__nodata {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    width: 100%;
  }
  &__scale {
    margin-top: 199px;
    position: relative;
    &-item {
      position: absolute;
      left: 5px;
      background-color: black;
      border-radius: 50%;
      color: white;
      width: 12px;
      height: 12px;
      font-size: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 2px;
    }
    &-cell {
      width: 30px;
      height: $height;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid white;
    }
  }
  &__notes {
    margin-top: 5px;
    padding-left: 30px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    &-item {
      display: flex;
      align-items: center;
      padding-right: 20px;
    }
    &-color {
      width: 10px;
      height: 10px;
      background-color: green;
      margin-right: 5px;
    }
    &-text {
      padding-right: 10px;
    }
  }
  &__table {
    display: flex;
    &-center {
      justify-content: center;
    }
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    &-head {
      border-right: 1px solid white;
      border-bottom: 1px solid white;
      border-top: 1px solid white;
      width: 30px;
      height: $heighthead;
      writing-mode: vertical-lr;
      transform: rotate(-180deg);
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: black;
      color: white;
    }
    &-cell {
      width: 30px;
      height: $height;
      border-left: 1px solid black;
      border-bottom: 1px solid black;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-inner {
      width: 100%;
      height: 100%;
    }
    &-item {
      &:nth-last-of-type(1) {
        .pulsometerModals__table-cell {
          border-right: 1px solid black;
        }
        .pulsometerModals__table-head {
          border-left: 1px solid black;
        }
      }
    }
  }
}
