@import 'src/styles/variables';

.polygon-main-arrow-menu__colors {
  .popover {
    display: flex;
    border-radius: unset;
    background-color: $theme-color-black;
    top: 103%;
    right: 50%;
    padding: 10px;
    border-left: none;
  }
}
