@import 'src/styles/variables';
@import 'src/styles/mixins';

.AddPolygonObjects {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  padding: 18px;
  max-width: 325px;
  width: 325px;
  z-index: 10;

  .color {
    &-item {
      width: 16px;
      height: 16px;
      border-radius: 2px;
      margin-left: 10px;
      cursor: pointer;
    }
    &-wrapper {
      background-color: #f5f5f5;
      max-width: 120px;
    }
  }
}
