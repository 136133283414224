.DecideOnTheFlyPackage {
  &__wrapper {
    min-height: 88vh;
    width: 100%;
  }
  padding: 40px;
  box-sizing: border-box;

  background-color: #ffffff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &__content {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
    max-height: 53vh;
    overflow-y: auto;
    justify-self: center;
  }
  &__head {
    display: flex;
    color: #303030;
    text-align: center;
    font-family: Roboto Condensed;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 20px;
  }
  &__title {
    color: #303030;
    text-align: center;
    font-family: Roboto Condensed;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    text-align: start;
    margin-bottom: 20px;
    display: flex;
  }
  &__getBack {
    background-color: #303030;
    width: 240px;
    max-width: 100%;
    height: 32px;
    min-height: 32px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    font-family: Roboto Condensed;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 40px;
  }
  &__controll {
    display: flex;
    color: #303030;
    font-family: Roboto Condensed;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 20px;
    &-item {
      margin-right: 15px;
      border-bottom: 3px solid white;
      cursor: pointer;
    }
    &-select {
      border-bottom: 3px solid #149939;
    }
  }
}
