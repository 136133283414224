@import 'src/styles/variables';
@import 'src/styles/mixins';

.commentCounterModal {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  padding: 18px;
  max-width: 325px;
  width: 425px;
  z-index: 10;
  input {
    width: 100%;
  }
  &__buttons {
    display: flex;
  }
}
