@import 'src/styles/variables';
@import 'src/styles/mixins';

$UserTasks-table-col1: 20%;
$UserTasks-table-col2: 40%;
$UserTasks-table-col3: 40%;

.UserTasks {
  background-color: white;
  padding: 20px;
  min-height: calc(100vh - 120px);
  .verticalLine {
    height: 100%;
    border-left: 1px solid #afafaf;
    margin-right: 10px;
  }

  &__heder {
    display: flex;

    justify-content: space-between;
    align-items: center;
    &-name {
      @include text($text-primary, $font-family, $f-size26, 30px, 400, normal);
    }
  }
  &__tags {
    &-wrapper {
      display: flex;
      width: 60%;
      align-items: center;
    }
    &-item {
      display: flex;
      flex-wrap: wrap;
      .tagsFilter__list {
        display: flex;
        flex-wrap: wrap;
      }
      .tagsFilter__title {
        display: none;
      }
      .tagsFilter__itemWrap {
        margin: 1px 2px;
        border: 1px solid white;
        &.active {
          border: 1px solid #303030;
          border-radius: 2px;
        }
      }
    }
    &-switch {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 200px;
      width: 200px;
    }
    &-label {
      margin-right: 10px;
      @include text($text-primary, $font-family, $f-size16, 20px, 400, normal);
    }
    &-line {
      border-left: 1px solid #afafaf;
      height: 15px;
      margin-left: 40px;
      margin-right: 10px;
    }
    &-name {
      @include text($text-primary, $font-family, $f-size20, 23px, 400, normal);
    }
    &-wrapper-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow-x: hidden;
    }
  }
  &-table {
    &__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 20px;
    }

    &__title {
      box-sizing: border-box;
      position: relative;
      width: $UserTasks-table-col2;
      margin: 0 0 18px 0;
      padding-left: 28px;
      @include text($text-primary, $font-family, $f-size20, 23px, normal, normal);
      text-align: left;

      &:first-child {
        width: $UserTasks-table-col1;
        padding-left: 0;

        &::before {
          width: 0;
        }
      }

      &:last-child {
        width: $UserTasks-table-col3;
      }
    }
  }
}

.ProjectItem {
  &__header {
    background-color: #303030;
    padding: 10px;
    color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 2px;
  }
}

.UserTasksItem {
  display: flex;
  align-items: center;
  padding: 10px 0 8px;
  border-bottom: 1px solid $theme-color-gray;
  &__EditClicked {
    min-width: 20px;
  }
  &__status {
    &-item {
      min-width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 10px;
    }
    &-wrapper {
      display: flex;
      align-items: center;
    }
  }

  &__tag {
    display: flex;
    flex-wrap: wrap;
  }

  &__col {
    position: relative;
    box-sizing: border-box;
    width: $UserTasks-table-col2;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    padding-left: 28px;

    &:first-child {
      width: $UserTasks-table-col1;
      padding-left: 0;
    }

    &:last-child {
      width: $UserTasks-table-col3;
    }

    & .UserTasksItem__content {
      @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
      text-align: left;
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      align-items: center;
      span {
        cursor: pointer;
      }
    }
  }

  &:last-child {
    border-bottom: none;
  }
  &__prev {
    color: #afafaf !important;
  }
}
