.TreadsItem {
  &__header {
    background-color: #303030;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 5px 10px;
    margin-bottom: 20px;
    &-new {
      width: 32px;
      height: 18px;
      background: #149939;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1px 6px;
      margin-left: 10px;
    }
    &-text {
      margin-left: 10px;
      text-decoration: none;
      color: white;
      width: 90%;
    }
    &-title {
      display: flex;
      align-items: center;
    }
  }
  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    padding-right: 10px;
    &-avatar {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      margin: 0px 20px 20px 5px;
    }
    &-user {
      display: flex;
      width: 100%;
    }
    &-userData {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
    &-userWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    &-useName {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
    &-userDate {
      color: #afafaf;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      margin-left: 10px;
    }
    &-text {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: #303030;
      text-align: start;
      width: 100%;
      display: flex;
      &-image {
        height: 400px;
        width: 400px;
      }
      .ql-editor {
        width: 100%;
      }
    }
  }
  &__Line {
    margin: 0px 40px;
    border-left: 1px solid rgb(243, 243, 243);
  }
  &__ansver {
    &-wrapper {
      display: flex;
      width: 130px;
      justify-content: flex-end;
    }
    &-textWrapper {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
    &-avatar {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      border: 1px solid #ffffff;
      margin-right: -8px;
    }
    &-text {
      font-weight: 300;
      font-size: 16px;
      line-height: 16px;
      color: #afafaf;
    }
    &-count {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 16px;
      color: #303030;
      padding-left: 5px;
    }
  }
}
.HoverMenu {
  &__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
