@import 'src/styles/variables';
@import 'src/styles/mixins';

.expensesTaskCell {
  display: flex;
  width: 65%;
  word-break: break-word;
  padding: 0 15px;

  a {
    @include text($text-primary, $font-family, $f-size16, 20px, 500, normal);
    text-decoration: none;
    padding-left: 6px;
    text-align: left;
    word-break: break-all;

    &:hover {
      color: $text-active;
    }
  }
}
