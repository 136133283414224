@import 'src/styles/variables';
@import 'src/styles/mixins';

.CellFirstCol {
  &__child {
    display: none;
  }

  &__state1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    &:hover {
      .CellFirstCol__child {
        display: block;
      }
    }
  }
  &__state2 {
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
    cursor: pointer;
    .edit-color {
      display: none;
    }
    &__gray {
      background-color: #e5dcdc;
      padding: 3px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      padding-left: 10px;
    }
    &__empty {
      display: flex;
      align-items: center;
      padding-left: 10px;
    }
    &-black {
      padding: 8px;
      background-color: #303030;
      color: white !important;
      .CellFirstCol__state2-element {
        border: 1px solid #303030;
      }
      .CellFirstCol__state2-select {
        color: #303030;
        border-radius: 8px;
      }
      .CellFirstCol__state2-button {
        border: 1px solid #303030;
        &:hover {
          background-color: #d5edd8;
          border-radius: 8px;
          color: black;
        }
      }
    }

    &-element {
      margin-bottom: 5px;
      &:hover {
        background-color: #d5edd8;
        border-radius: 8px;
        color: black;
        .CellFirstCol__state2-change {
          display: flex;
        }
      }

      &__black {
        background-color: black;
        border-radius: 8px;
        color: white;
      }
    }
    &-button {
      border: 1px solid white;
      &:hover {
        border: 1px solid #d5edd8;
        border-radius: 8px;
        .CellFirstCol__child {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    &-button,
    &-element {
      border: 1px solid white;

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      min-height: 36px;
      margin-bottom: 5px;
      width: 100%;
      &:hover {
        .edit-color {
          display: block;
          margin-right: 5px;
        }
        .arrowTop,
        .arrowBottom {
          display: block;
        }
      }
    }
    .arrowTop,
    .arrowBottom {
      display: none;
      margin-right: 10px;
    }
    .arrowTop {
      transform: rotate(90deg);
    }
    .arrowBottom {
      transform: rotate(-90deg);
    }
    &-select {
      background-color: #d5edd8;
      border-radius: 8px;
    }
    &-icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    &-change {
      display: none;
      width: 20px;
      height: 20px;
      background-color: rgb(206, 206, 206);
      margin-right: 15px;
      border-radius: 50%;
    }
  }
}
