.buttonLinks__wrap {
  position: absolute;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: 0.5s;
  &:hover {
    opacity: 1;
  }
}
