@import 'src/styles/variables';
@import 'src/styles/mixins';

.menuLeft__item {
  display: flex;
  flex-direction: column;
  text-align: left;
  @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.menuLeftItem__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  .menuLeftItem_name {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 3px;
  }
  .menuLeftItem_user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-left: 1px solid $theme-color-lightGray;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      padding: 0 5px;
    }
  }
}

.menuLeftItem__description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;

  .menuLeftItem__description--link {
    overflow: hidden;
    text-overflow: ellipsis;
    @include text($text-primary, $font-family, $f-size14, 16px, 300, normal);

    text-decoration: none;
    color: $text-primary;

    &:hover {
      text-decoration: underline;
      color: $text-active;

      &:active {
        color: $text-active;
        text-decoration: none;
      }
    }
  }

  .menuLeftItem__description--hash {
    cursor: default;
    padding-right: 5px;
  }

  /*  &-action {
    @include text($text-primary, $font-family, $f-size12, 14px, 300, normal);
  }*/
}

.menuLeft__item-afterClick {
  display: flex;
  flex-direction: column;
  text-align: left;
  @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
