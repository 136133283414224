@import 'src/styles/variables';
@import 'src/styles/mixins';

body,
html {
  background-color: $theme-color-darkGray;
}
.login-page {
  position: relative;
  .input__wrapper label {
    color: white;
  }
  &__canvas {
    position: absolute;
    z-index: -1;
    top: 0;
    background-color: black;
  }
  &__outer-form {
    height: 500px;
    width: 400px;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, 30%);
  }
}

.login__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .form__wrapper {
    width: 100%;
    max-width: 20em;
    background-color: rgba(255, 255, 255, 0.1);
    align-content: center;
    margin-top: 40px;
    padding: 2vh 0;
    box-shadow: $theme-color-black 0 8px 24px;
  }

  .login__fields-wrapper {
    max-width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3vh 0;
  }

  .login__button {
    margin-top: 3em;
    text-decoration: none;
    cursor: pointer;
  }

  .login__title {
    text-align: center;
    color: $text-primary;
    font-weight: 400;
    margin-top: 10px;
    color: white;
  }

  .login__fields-checkbox {
    align-self: flex-start;
    padding: 0.8em 0 0.8em 3.8em;
  }

  .login__error {
    @include text($text-error, $font-family, $f-size16, 18px, 300, normal);
  }
}
