@import 'src/styles/variables';
@import 'src/styles/mixins';

.edit-modal {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  padding: 18px;
  max-width: 325px;
  width: 325px;
  z-index: 10;

  &__title {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    margin-top: 10px;

    p {
      padding-right: 5px;
    }

    input {
      @include text($text-primary, 'Roboto Condensed', 14px, 16px, 300, normal);
      border: none;
      outline: none;
      width: 205px;
      padding: 0;
    }

    & .appInput__wrapper {
      margin-bottom: 20px;
    }
  }

  &__colors {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-container {
      display: flex;
      margin: 0 0 5px 10px;
      max-width: 300px;
      overflow-x: auto;
    }

    .polygon-arrow-color-item {
      max-width: none;
    }
  }

  &__buttons {
    display: flex;
  }
}