@import 'src/styles/variables';
@import 'src/styles/mixins';

.addFileTaskModal {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  padding: 18px;
  max-width: 325px !important;
  width: 325px;
  z-index: 10;
  min-width: 325px;
  max-height: 80vh;
  overflow-y: auto;
  .appInput__wrapper {
    margin-bottom: 0px;
  }
  &__title {
    margin-bottom: 10px;
  }
  &__switch-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
  }
  .icons {
    margin-right: 5px;
  }
  .default__button {
    &:nth-of-type(2) {
      margin-top: 20px !important;
    }
  }
  .appInput__wrapper {
    input {
      width: 100%;
    }
  }
  .appInput__text-error {
    padding: 1px 0 0 25px;
    width: calc(100% - 25px);
  }
  .tagsSelect__optionsList {
    position: relative;
    left: 0;
    background-color: white;
  }
}
