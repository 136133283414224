.ForumCreateMessage {
  min-width: 300px;
  padding: 20px;
  font-family: 'Roboto Condensed';
  width: 100%;
  box-sizing: border-box;
  max-height: 550px;
  overflow-y: auto;

  .ql-editor {
    padding: 10px 10px;
  }
  .ql-snow .ql-tooltip {
    z-index: 9999999999 !important;
  }
  &__errors {
    border: 1px solid red;
  }
  &__quote {
    position: relative;
  }
  &__remove {
    position: absolute;
    top: 0;
    right: 0;
  }
  &__template {
    display: flex;
    width: 100%;
    &-inner {
      width: 100%;
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
    &-title {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    button {
      margin-left: 20px;
    }
  }
  .appInput__wrapper {
    input {
      width: 100%;
    }
  }

  .ql-container {
    min-height: 200px;
  }
  .ql-tooltip {
    left: 0px !important;
  }
}
