.TredsPagination {
  display: flex;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;

  &__active {
    background: #4e89e1 !important;
    color: #ffffff !important;
  }
  &__button {
    width: 32px;
    height: 32px;
    background: #ffffff;
    border-radius: 8px;
    color: #303030;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 2px;
    cursor: pointer;
  }
  &__wrapperSelector {
    display: flex;
    color: #ffffff;
    align-items: center;
  }
  &__selector {
    margin: 0px 10px;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #ffffff;
    border: none;
    padding: 5px;
    font-size: 16px;
    color: #555;
  }
}
