@import 'src/styles/variables';
@import 'src/styles/mixins';

.TreadItemChat {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  height: 100%;
  &__selectChat {
    position: absolute;
    display: flex;
    top: 0px;
    &-button {
      width: 40px;
      height: 20px;
      margin-right: 10px;
      padding: 3px;
      &:hover {
        background-color: white;
        border: 1px solid black;
      }
      transition: 0.2s;
      cursor: pointer;
      border: 1px solid white;
      background-color: azure;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .right {
      & .write {
        @include text($theme-color-darkGray, $font-family, $f-size10, 12px, 300, normal);
      }
    }

    .TreadItemChat__participants {
      display: flex;

      .participants__user,
      .participants__companion {
        cursor: pointer;
        @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
      }
      img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        padding: 0 5px;
      }
    }
  }

  .TreadItemChat__content {
    background-color: #f5f5f5;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px;
    margin-top: 10px;
    border: 2px solid $theme-color-darkGray;
    position: relative;

    .TreadItemChat__messages {
      height: 100%;
      overflow-y: auto;
      padding: 0 10px 10px 10px;
      background-color: $text-secondary;

      .TreadItemChat__fetch-button {
        margin-top: 20px;
        @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
      }

      > button {
        position: absolute;
        bottom: 60px;
        top: auto;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
      }

      & .TreadItemChat__message:last-child {
        border: none;
        padding-bottom: 0;
      }
    }

    .TreadItemChat__absence {
      margin-top: 20px;
      @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
    }
  }
}
