@import 'src/styles/variables';
@import 'src/styles/mixins';

.task-page {
  width: 100%;
  background-color: #efefef;
  overflow-x: auto;
  display: flex;

  .editTitle {
    width: auto;
    margin-bottom: 0;
  }
  &__body {
    width: 100%;
    background-color: $text-secondary;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 5px 20px;
    height: calc(100vh - 105px);
    overflow-x: auto;
    overflow-y: hidden;

    .line {
      width: 100%;
      padding-top: 10px;
      border-bottom: 1px solid $theme-color-darkGray;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &-left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .task-page__header-left-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 20px;

        & .polygon-icon {
          margin-right: 15px;
        }
      }
    }

    &-right {
      display: flex;
      align-items: center;
      min-width: 300px;
      max-width: 300px;
      width: 100%;
      position: relative;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-y: hidden;

    &-left {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-right: 20px;

      & textarea {
        width: 100%;
      }
    }

    &-right {
      width: 300px;
    }
  }
}
