.containerCheckbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 14px;
  height: 15px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      & ~ .checkmarkCheckbox {
        background-color: #303030;
        border-radius: 5px;
        &:after {
          display: block;
        }
      }
    }
  }
  &:hover {
    input {
      & ~ .checkmarkCheckbox {
        background-color: #303030;
      }
    }
  }
  .checkmarkCheckbox {
    &:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
.checkmarkCheckbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #303030;
  border-radius: 5px;
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
}
