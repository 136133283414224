@import 'src/styles/variables';
@import 'src/styles/mixins';

.notes {
  width: 300px;
  margin: 0 auto;

  .notes__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include text($text-primary, $font-family, $f-size20, 23px, 400, normal);
    margin: 10px 10px 10px 0;
    padding-left: 10px;
    border-left: 1px solid $theme-color-darkGray;

    .default__button {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      @include text($text-primary, $font-family, $f-size12, 14px, 300, normal);
      width: 100px !important;
      height: 20px !important;
      margin-right: -10px;
      background-color: $text-secondary !important;
      border: 1px solid $theme-color-lightGray;
      padding: 0;

      &:hover {
        border-color: #149939;
        color: #149939;
        cursor: pointer;
      }
    }
  }

  .notes__text {
    background-color: $section-primary;
    overflow-y: auto;
    overflow-x: hidden;

    textarea {
      @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
      width: 278px;
      height: 38px;
      outline: none;
      resize: none;
      padding: 0 10px;
      border: 1px solid $theme-color-darkGray;
    }
  }
}
