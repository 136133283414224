@import 'src/styles/variables';
@import 'src/styles/mixins';

.input__wrapper {
  padding-bottom: 1.2em;
  display: flex;
  flex-direction: column;
  position: relative;
  .eyeIcons {
    position: absolute;
    right: 5px;
    top: 30px;
  }

  label {
    align-self: flex-start;
    color: $text-primary;
    font-size: $f-size14;
    padding: 0 0 0.1em 0.2em;
  }

  p {
    position: absolute;
    font-size: $f-size12;
    padding: 4.2em 0 0.1em 0.2em;
    color: $text-error;
    pointer-events: none;
  }

  input {
    font-size: $f-size14;
    padding: 6px 12px;
    border: 1px solid $theme-color-darkGray;
    outline: none;
  }

  .input__field-error {
    border: 1px solid $text-error;
  }
}

.input__wrapper-checkbox {
  display: flex;
  flex-direction: row-reverse;

  label {
    color: $text-primary;
    font-size: $f-size14;
    padding: 0.05em 0 0.1em 0.2em;
    cursor: pointer;
  }

  p {
    position: absolute;
    font-size: $f-size12;
    padding: 3.9em 0 0.1em 0.2em;
    color: $text-error;
  }

  input {
    margin-top: 0.25em;
    font: inherit;
    padding: 0.2em 0.5em;
    border: 1px solid $text-primary;
    cursor: pointer;
  }

  .input__field-error {
    border: 1px solid $text-error;
  }
}
