.chronometerTime {
  position: absolute;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  &__zero {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 0;
  }
  &__twelve {
    position: absolute;
    top: 360px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  &__six {
    position: absolute;
    top: 50%;
    left: 90%;

    transform: translate(0%, -50%);
  }
  &__eighteen {
    position: absolute;
    top: 50%;
    left: 0%;

    transform: translate(0%, -50%);
  }
}
