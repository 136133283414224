.polygonTreeModal {
  padding: 20px;
  max-width: 90vw;
  max-height: 80vh;
  overflow: auto;
  &-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .figure {
    &__wrapper-outer {
      display: flex;
    }
    &__wrapper-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__elements {
      &-wrapper {
        border: 1px solid #efefef;
        width: 240px;
        height: 65px;
        display: flex;
        align-items: center;
        margin-left: 10px;
        cursor: pointer;
        position: relative;
      }
      &-count {
        position: absolute;
        top: 5px;
        right: 5px;
      }
      &-number {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
      }
      &-select {
        color: white;
        background-color: green;
      }
      &-deselect {
        color: black;
        background-color: #efefef;
      }
      &-child {
        display: flex;
      }
    }
  }
  .arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;
  }
  .down {
    transform: rotate(45deg);
  }
  .line {
    &__height {
      background-color: #303030;
      width: 2px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
  }
}
