.DecideOnTheFlyLogs {
  &__head {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .ChronometerReport__pickers {
    background-color: #efefef;
    padding: 5px;
  }
  &__title {
    color: #303030;
    font-family: Roboto Condensed;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__statistic {
    display: flex;
    color: #afafaf;
    text-align: center;
    font-family: Roboto Condensed;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 2px;
    background: #efefef;
    justify-content: center;
    align-items: center;
    height: 30px;
    box-sizing: border-box;
    padding: 8px;
    &-count {
      color: #303030;
      font-family: Roboto Condensed;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-right: 5px;
      margin-left: 2px;
    }
  }
  &__right {
    display: flex;
    align-items: center;

    &-calendar {
      border-radius: 2px;
      background: #efefef;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      position: relative;
    }
  }
  &__download {
    border-radius: 2px;
    background: #0d0d0d;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 12px;
    color: #fff;
    text-align: center;
    font-family: Roboto Condensed;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 30px;
    box-sizing: border-box;
    cursor: pointer;
  }
}
