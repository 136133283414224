.DecideOnTheFly {
  padding: 15px 20px 0;
  min-height: 88vh;
  @media (max-height: 900px) {
    min-height: 84vh;
  }

  overflow-y: hidden;
  background-color: #ffffff;
  &__top {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    &-right {
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
    &-left {
      display: flex;
      align-items: flex-start;
    }
    &-title {
      color: #afafaf;
      text-align: center;
      font-family: Roboto Condensed;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 15px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #afafaf;
    height: 30px;
    border-radius: 2px;
    background: #efefef;
    padding: 4px 8px;
    min-width: 410px;
    box-sizing: border-box;
    &-item {
      font-family: Roboto Condensed;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-right: 8px;
    }
    &-number {
      color: #303030;
      font-family: Roboto Condensed;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  &__center {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-select {
      color: #149939;
      text-align: center;
      font-family: Roboto Condensed;
      font-size: 36px;
      font-style: normal;
      font-weight: 500;

      margin-bottom: 10px;

      @media (max-height: 900px) {
        font-size: 20px;
        line-height: 20px;
        min-height: 20px;
      }
    }
    &-heder {
      margin-top: 5px;
      margin-bottom: 10px;
      color: #303030;
      text-align: center;
      font-family: Roboto Condensed;
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      @media (max-height: 900px) {
        font-size: 20px;
        line-height: 20px;
        min-height: 20px;
      }
    }
    &-buttons {
      margin: 20px 0px;
      display: flex;
    }
  }
  &__select {
    box-sizing: border-box;
    border-radius: 10000px;
    background: #efefef;
    min-width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    padding: 8px;
    margin-top: 1px;
    &-color {
      border-radius: 1000000px;
      background: #149939;
      width: 16px;
      height: 16px;
    }
    &-text {
      color: #303030;
      text-align: center;
      font-family: Roboto Condensed;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 5px;
    }
  }
}
