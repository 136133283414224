@import 'src/styles/variables';
@import 'src/styles/mixins';

.forumPage {
  text-align: center;
  background-color: #303030;
  margin-top: 45px;
  &__wrapper {
    position: relative;
    .buttonLinks__wrap {
      position: fixed;
    }
  }
  &__footer {
    background-color: #303030;
    width: 100%;
    height: 20px;
  }
  .header {
    position: fixed;
    top: 0px;
    z-index: 99999;
  }
  &__addButton {
    background-color: #ffffff;
    opacity: 0;
    margin: 4px;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #303030;
    cursor: pointer;
    svg {
      margin-right: 10px;
    }
    transition: 0.5s;
    &:hover {
      opacity: 1;
    }
    width: 270px;
  }

  .block {
    display: flex;
    min-height: calc(#{$forum-page-heigth} - 45px);
    position: relative;
  }
  .sidebar {
    width: 200px;

    background-color: #0000;
    height: calc(100% - 5px);

    &__white-block {
      width: 100%;
      height: 60px;
    }
    &__left {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 20px;
    }
    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &-title {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        margin-right: 5px;
      }
      &-count {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 32px;
        text-align: right;
        color: #ffffff;
        margin-right: 20px;
      }
    }
  }
  .content {
    width: 100%;
    background-color: white;
    &__black-block {
      width: calc(100% - 80px);
      height: 65px;
      background-color: #303030;
      margin: 0px 40px;
      display: flex;
      align-items: center;
      &__inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
      }
      &__text {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #ffffff;
        text-align: start;
        display: flex;
        &-inner {
          width: 55%;
          position: relative;
          .iconRotate {
            transform: rotate(180deg);
            position: absolute;
          }
        }
      }
      &__author {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #ffffff;
        white-space: nowrap;
        margin-bottom: 2px;
        margin-left: 10px;
        margin-top: 20px;
      }
    }
    &__treads {
      margin-left: 320px;
    }
  }
  .navigation-treads {
    z-index: 99999;
    width: 300px;
    background-color: #efefef;
    position: fixed;
    top: 50%;
    left: 120px;
    transform: translate(0%, -50%);
    padding: 20px;
    &__inner {
      background-color: #303030;
      width: 100%;
      min-height: 100%;
    }
  }
}
