@import 'src/styles/variables';
@import 'src/styles/mixins';

.expenses {
  width: 100%;
  background-color: $theme-color-gray;
  overflow-x: auto;
  min-height: 88vh;
  display: flex;

  .expenses__body {
    min-width: calc(1100px - 300px);
    width: 100%;
    background-color: $text-secondary;
    display: flex;
    flex-direction: column;
    min-height: 88vh;
  }
}

