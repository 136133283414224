.chronometerDone {
  width: 100%;
  &__title {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #303030;
    text-align: start;
  }
  &__title2 {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #303030;
    text-align: start;
  }
  &__title3 {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #303030;
    text-align: start;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  &__button {
    &-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
    }
    &-positive {
      background: #149939;
      border: none;
      margin: 0px;
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      height: 25px;
      margin-bottom: 20px;
      cursor: pointer;
      white-space: nowrap;
    }
    &-negative {
      background: #303030;
      border: none;
      margin: 0px;
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      height: 25px;
      margin-bottom: 20px;
      white-space: nowrap;
      cursor: pointer;
    }
    &-tracked {
      border: 1px solid #149939;
      color: #149939;
      background-color: white;
      margin: 0px;
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      height: 25px;
      margin-bottom: 20px;
      white-space: nowrap;
      cursor: pointer;
    }
    &-plan {
      border: 1px solid #debb3f;
      color: #303030;
      background-color: #debb3f;
      margin: 0px;
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      height: 25px;
      margin-bottom: 20px;
      white-space: nowrap;
      cursor: pointer;
    }
  }
  &__line {
    &-wrapper {
      display: flex;
    }
    &-positive {
      background: #debb3f;
      height: 16px;
    }
    &-negative {
      background: #303030;
      height: 16px;
    }
    &-percent {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #303030;
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      margin-bottom: 10px;
    }
  }
  &__task {
    &-text {
      text-align: start;
      width: 95%;
    }
    &-item {
      display: flex;
      justify-content: space-between;
    }
    &-color {
      min-width: 16px;
      height: 16px;
      background: #b3e4c1;
      margin-right: 10px;
      text-align: start;
    }
    &-name {
      display: flex;
      align-items: center;
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #303030;
      padding-bottom: 20px;
    }
    &-clock {
      display: flex;
      align-items: center;
      min-width: 60px;
      border-left: 1px solid #efefef;
      padding-bottom: 20px;
      svg {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    &-time {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #303030;
    }
  }
}
