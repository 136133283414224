@import 'src/styles/variables';
@import 'src/styles/mixins';

.addUserTagsModal {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  padding: 20px;
  width: 320px;
  z-index: 10;

  &__button {
    &-wrapper {
      display: flex;
    }
  }
}
