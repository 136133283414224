@import 'src/styles/variables';
@import 'src/styles/mixins';

.FileGraphics {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  .calendarPicker {
    align-self: flex-end;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-name {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 26px;
      line-height: 121%;
      display: flex;
      align-items: center;
      color: #303030;
    }
  }
  &__line {
    width: 100%;
    height: 1px;
    background-color: $theme-color-gray;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  &__noData {
    height: calc(100vh - 210px);
  }
  &__day-data {
    display: flex;
    margin-top: 20px;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #afafaf;
  }
  &__creative {
    display: flex;
    text-align: start;
    margin-right: 60px;
    &-title {
      width: 140px;
      margin-right: 30px;
    }
    &-title2 {
      width: 90px;
      margin-right: 30px;
    }
    &-date {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      color: #303030;
    }
  }
}
