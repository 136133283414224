.clock {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: #333;
  position: relative;
  --start-hour: 7.5;
  .hour-wrapper {
    position: absolute;
    transform-origin: center center;
    transform: rotate(calc((360deg / 24) * (var(--hour)))) translateY(25%) rotate(-83deg);
    display: flex;
    align-items: center;
  }

  .hour {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    transform-origin: center;
    transform: translate(130px) rotate(90deg);
    cursor: pointer;
    &-number {
      position: absolute;
      transform: rotate(calc((360deg / 24) * (var(--hour) * -1))) rotate(353deg);
      top: -40px;
      z-index: 99999;
      width: 87px;
      height: 35px;
      background: #ffffff;
      box-shadow: 0px 2px 5px rgba(48, 48, 48, 0.15);
      border-radius: 2px;
      z-index: 9999999;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #303030;
    }
  }
}
