@import 'src/styles/variables';
@import 'src/styles/mixins';

.addPolygon {
  &__btn {
    position: relative;
    min-width: 240px;
    margin-left: auto;
    padding: 10px 20px 11px;
    text-align: left;
    background-color: #303030;
    border: none;
    color: #ffffff;

    &:hover {
      cursor: pointer;
      svg {
        fill: $theme-color-green;
      }
    }

    &-text {
      @include text(#ffffff, $font-family, $f-size16, 19px, normal, normal);
    }

    &-icon {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translate(0, -50%);
    }
  }
}

@media (max-width: 1240px) {
  .addUser__btn {
    margin-right: 10px;
  }
}
