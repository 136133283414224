.TreadItemHeader {
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  border-bottom: 1px solid #afafaf;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  &__button {
    width: 40px;
    height: 32px;
    border: 1px solid #303030;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
  }
  &__excess {
    display: flex;
    position: absolute;
    left: 77px;
    bottom: 6px;
    &-hidden {
      display: flex;
    }
  }
  &__exit {
    width: 200px;
    max-width: 100%;
    height: 30px;
    border: 1px solid #303030;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
  }
  &__getBack {
    background-color: #303030;
    width: 200px;
    max-width: 100%;
    height: 32px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &__changeBackground {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__line {
    background: #afafaf;
    width: 1px;
    height: 20px;
    margin: 0px 20px;
  }
  &__wrapper {
    display: flex;
    align-items: center;
  }
  &__avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 20px;
  }
  &__border {
    border: 1px solid black;
  }
  &__items {
    display: flex;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #303030;
    line-height: 14px;
    height: 22px;
  }
  &__item {
    padding: 4px;
    margin-left: 8px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    &-input {
      border: none;
      padding: 0px;
      margin: 0px;
      background-color: transparent;
      outline: none;
      color: #303030;
      display: flex;
      font-family: Roboto Condensed;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      height: 22px;
      line-height: 14px;
      cursor: text;
    }
    &-color {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 4px;
    }
    &-one {
      background-color: #e2e2e2;
      cursor: pointer;
    }
    &-two {
      background-color: #b3e4c1;
    }
    &-three {
      background-color: #ffffff;
    }
  }
}
