@import 'src/styles/variables';
@import 'src/styles/mixins';

.ChangeTagsModal {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  padding: 20px;
  max-width: 360px;
  width: 360px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  .tag__wrapper {
    color: red;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    &-outer {
      max-height: 300px;
      min-height: 300px;
      overflow-y: auto;
    }
    .icons__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .remove-icon {
      margin-left: 10px;
      &:hover {
        background-color: white;
      }
    }
  }
  .button__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tags-search {
    position: relative;

    &__input {
      box-sizing: border-box;
      width: 100%;
      padding: 10px 20px 11px 22px;
      background-color: #ffffff;
      outline: none;
      border: none;
      border-bottom: 1px solid #afafaf;
    }

    &__input,
    &__input::placeholder {
      @include text($text-primary, $font-family, $f-size16, 19px, normal, normal);
    }

    &__icon {
      position: absolute;
      top: 14px;
      left: 5px;
      padding: 0;
      border: none;
      background-color: transparent;

      &:hover {
        cursor: pointer;
        fill: $theme-color-green;
      }
    }
  }
  .change__taq {
    display: flex;
    flex-direction: column;
    align-items: center;
    .appInput__wrapper {
      margin-bottom: 20px;
      width: 100%;
      .appInput__label {
        white-space: nowrap;
      }
      .appInput__field {
        width: 100%;
      }
    }
    .tag-color {
      &__wrapper {
        &-outer {
          display: flex;
          width: 100%;
        }
        &-inner {
          display: flex;
          &:nth-of-type(1) {
            width: 100px;
          }
          background-color: #f5f5f5;
          padding: 0 10px;
        }
      }
      &__text {
        white-space: nowrap;
      }
      &__element {
        width: 15px;
        height: 15px;
        border-radius: 2px;
        border: 1px solid #d7d0d0;
        margin-left: 10px;
      }
    }
  }
}
