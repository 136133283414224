@import 'src/styles/variables';
@import 'src/styles/mixins';

.preloader {
  text-align: center;
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    cursor: default;
  }
}

.preloader__absolute {
  display: flex;
  height: 100vh;
  position: absolute;
  justify-self: center;
  justify-content: center;
  align-items: center;
  z-index: 111;
  top: 0;
  left: 0;
  right: 0;
}
