@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.polygon-palette-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative;
  max-height: 54vh;
  overflow-x: hidden;
  overflow-y: auto;

  &__title {
    @include text($text-primary, $font-family, $f-size14, 16px, 500, normal);
    margin-bottom: 15px;
  }

  &__colors-items {
    display: flex;
    align-items: center;
    margin: 5px 0;

    &-color {
      width: 20px;
      height: 20px;
      border: 1px solid $theme-color-darkGray
    }

    &-colors-hex-input {
      border-width: 0;
      margin: 0 5px;
      background-color: $text-secondary;
      border-bottom: 1px solid $text-primary;
      width: 70px;
    }

    &-colors-description-input {
      border-width: 0;
      background-color: $text-secondary;
      border-bottom: 1px solid $text-primary;
    }
  }

  &__picker {
    background-color: $theme-color-gray;
    padding: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .default__button {
    margin-top: 15px;
  }

  .remove-icon-inner {
    margin-left: 5px;
  }
}
