.pickerWrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  &__inner {
    display: flex;
    align-items: center;
    margin-top: 5px;
    cursor: pointer;
  }
  &__color {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: red;
  }
  &__label {
    color: #303030;
    font-family: Roboto Condensed;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
  }
  &__panel {
    position: absolute;
    top: 50px;
    z-index: 999;
  }
}
