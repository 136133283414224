.file-filter {
  &__wrapper {
    display: flex;
    margin-right: 20px;
  }
  &__item {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &__select {
    background-color: black;
  }
}
