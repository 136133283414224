@import 'src/styles/variables';

.header__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: $text-secondary;
  cursor: pointer;

  svg {
    padding-left: 5px;
  }

  &:hover {
    text-decoration: none;
    color: $theme-color-darkGray;
    svg {
      path {
        fill: $theme-color-darkGray;
      }
    }
  }

  & img {
    margin: 0 5px;
  }

  .img__last {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  p {
    padding-left: 5px;
    padding-right: 5px;
  }
}
