@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.createTasksOrSubtask__main {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  padding: 18px;
  max-width: 325px;
  width: 325px;

  & button {
    margin-top: 10px;
  }

  .createTasksOrSubtask__data {
    display: flex;
    justify-content: space-between;

    .project__name {
      margin-bottom: 10px;
    }

    &-helper-text {
      @include text($theme-color-darkGray, 'Roboto Condensed', 12px, 14px, 400, normal);
      margin-bottom: 10px;
    }

    &-parent-select {
      display: flex;
      align-items: center;

      & .crossColor {
        margin-bottom: 30px;
        margin-left: 10px;
      }
    }
  }

  .createTasksOrSubtask__title {
    width: 100%;
    display: flex;

    p {
      padding-right: 5px;
    }

    input {
      @include text($text-primary, 'Roboto Condensed', 14px, 16px, 300, normal);
      border: none;
      outline: none;
      width: 205px;
      padding: 0;
    }

    & .appInput__wrapper {
      margin-bottom: 10px;
      width: 100%;
    }



    &-subtask {
      & .appInput__label {
        width: 122px;
      }

      & .appInput__wrapper {
        margin-bottom: 0;
      }

      input {
        width: 182px;
      }
    }
  }

}

.default__button {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
}
