@import 'src/styles/variables';
@import 'src/styles/mixins';

.addUser {
  &-from__fields-wrapper{
    input{
      width: 100%;
    }
  }
  &__btn {
    position: relative;
    min-width: 240px;
    margin-right: 40px;
    padding: 10px 20px 11px;
    text-align: left;
    background-color: #ffffff;
    border: none;

    &:hover {
      cursor: pointer;
      svg {
        fill: $theme-color-green;
      }
    }

    &-text {
      @include text($text-primary, $font-family, $f-size16, 19px, normal, normal);
    }

    &-icon {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translate(0, -50%);
    }
  }

  &__modal {
    box-sizing: border-box;
    width: 320px;
    padding: 30px 40px;
  }

  &-form {
    &__title {
      margin: 30px 0 40px;
      @include text($text-primary, $font-family, $f-size20, 24px, normal, normal);
      text-align: center;
    }
  }

  &__notification {
    margin: 10px 0 0;
    left: 50%;
    transform: translate(-50%);
    @include text($text-primary, $font-family, $f-size14, 16px, 300, normal);
    position: absolute;
  }

  &__response-wrap {
    margin-top: 30px;
    min-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__link {
    max-width: 100%;
    @include text($text-primary, $font-family, $f-size14, 16px, 300, normal);
    text-overflow: ellipsis;
    color: $text-primary;
  }

  &__error-message {
    @include text($text-primary, $font-family, $f-size12, 14px, 300, normal);
    color: $text-error;
  }
}

@media (max-width: 1240px) {
  .addUser__btn {
    margin-right: 10px;
  }
}
