@import 'src/styles/variables';
@import 'src/styles/mixins';

.my-tasks-maintainer {
  width: 100%;
  background-color: #efefef;
  overflow-x: auto;
  min-height: 88vh;
  display: flex;

  &__body {
    min-width: calc(1100px - 300px);
    width: 100%;
    background-color: $text-secondary;
    display: flex;
    flex-direction: column;
    min-height: 88vh;
  }

  &__title {
    display: flex;
    justify-content: space-between;

    h2 {
      min-width: 145px;
      text-align: left;
      padding-left: 18px;
      @include text($text-primary, $font-family, 26px, 19px, 500, normal);
    }

    .tagsFilter {
      display: flex;
      justify-content: flex-end;

      &__inner {
        display: flex;
        align-items: center;
        margin-right: 20px;
      }

      &__title {
        position: relative;
        padding: 0 4px 0 14px;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: 1px;
          height: 15px;
          background-color: $theme-color-darkGray;
          transform: translate(0, -50%);
        }
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
      }

      &__itemWrap {
        border-radius: 2px;
        cursor: pointer;
        margin: 3px 0 3px 10px;

        .tag__item {
          cursor: pointer;

          .tag__text {
            cursor: pointer;
          }
        }

        &.active {
          outline: 1px solid $text-primary;
        }
      }
    }
  }

  &__table {
    padding: 0 20px;
  }

  &__row {
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid $theme-color-darkGray;
  }

  &__column {
    border-left: 1px solid $theme-color-darkGray;
    text-align: left;
    @include text($text-primary, $font-family, $f-size20, 23px, normal, normal);
    padding: 0 10px;

    &.my-tasks-maintainer__tags {
      width: 20%;
    }

    &.my-tasks-maintainer__task {
      width: 45%;
    }

    &.my-tasks-maintainer__comment {
      width: 35%;
    }
  }
}
