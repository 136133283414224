.accesStorageModal {
  padding: 20px;
  min-width: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: Roboto Condensed, sans-serif;
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
  }
  &__items {
    max-height: 400px;
    overflow-y: auto;
    width: 100%;
  }
  &__title {
    font-weight: 400;
    font-size: 23px;
    text-align: center;
    margin-bottom: 10px;
  }
}
