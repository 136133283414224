.ShowLinkModal {
  width: 900px;
  padding: 5px;
  overflow-y: auto;
  max-height: 500px;
  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
  }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
