.ChronoWeekReport {
  padding: 20px;
  width: 360px;
  &__title {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #303030;
    margin-bottom: 25px;
  }
  &__textarea {
    width: 100%;
    min-height: 220px;
    padding: 8px 12px;
    box-sizing: border-box;
    border: 1px solid #efefef;
    border-radius: 2px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;

    background-color: transparent;
    resize: none;
    outline: none;

    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #303030;
  }
}
