@import 'src/styles/variables';
@import 'src/styles/mixins';

.userStatusSelect {
  &__item {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__mark {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 50%;
  }
}
