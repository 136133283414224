.HoverMenuTreads {
  z-index: 999999;
  width: 120px;
  position: absolute;
  right: 0px;
  top: 25px;
  background: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #303030;
  padding: 0px 20px 20px 20px;

  box-shadow: 0px 2px 5px rgba(48, 48, 48, 0.15);
  border-radius: 3px;
  &__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__item {
    margin-top: 20px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
    cursor: pointer;
  }
  &__line {
    width: 100%;
    height: 1px;
    background-color: #afafaf;
    margin-top: 20px;
  }
}
