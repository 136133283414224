.ForumTreadsNavigation {
  background-color: white;
  &__cogwheel {
    display: none;
  }

  &__item {
    &:hover {
      .ForumTreadsNavigation__cogwheel {
        display: block;
      }
    }
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5px;
    cursor: pointer;

    &-wrapper {
      background: #303030;
      border-bottom: 1px solid #efefef;
      padding: 5px;
    }
  }
  &__black {
    background: #303030;
    color: #ffffff;
  }
  &__white {
    background: #ffffff;
    color: #303030;
  }
  &__button {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #303030;
    padding: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      margin-right: 10px;
    }
    border: 1px solid #303030;
  }
}
