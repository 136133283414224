.CreateTreadModal {
  width: 930px;
  min-height: 457px;
  padding: 20px;
  font-family: 'Roboto Condensed';
  .ql-container,
  .ql-editor {
    min-height: 240px;
  }
}
