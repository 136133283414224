@import '../../../styles/variables';
@import '../../../styles/mixins';

.chatIconWithNumber {
  position: relative;
  margin-bottom: 20px;
  svg {
    cursor: pointer;
  }

  .chatIconWithNumber__number {
    min-width: 14px;
    min-height: 14px;
    background-color: red;
    @include text($text-secondary, $font-family, 12px, 12px, 500, normal);
    border-radius: 47%;
    border: 2px solid $text-primary;
    position: absolute;
    top: -10px;
    left: 8px;

    p {
      padding: 2px 6px;
      cursor: default;
    }
  }
}
