@import 'src/styles/variables';
@import 'src/styles/mixins';

.scale-list {
  width: 100%;
  background-color: #efefef;
  overflow-x: auto;
  min-height: 88vh;
  display: flex;
  &__h2 {
    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .buttonSelect {
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .buttonArchive {
    height: 25px;
    width: 200px;
    background-color: rgb(20, 153, 57);
    position: absolute;
    color: white;
    border: none;
    right: 0;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    font-family: Roboto Condensed, sans-serif;
  }
  .buttonSelectBlack {
    background-color: black;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
  }
  .selectOuter {
    display: flex;
    align-items: center;
  }
  .button-add__wrapper {
    padding-right: 20px;
    margin-left: 20px;
    display: flex;
    align-items: center;
  }
  .horizontal__line {
    width: calc(100% - 40px);
    border-bottom: 1px solid $theme-color-gray;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
  &__body {
    min-width: calc(1100px - 300px);
    width: 100%;
    background-color: $text-secondary;
    display: flex;
    flex-direction: column;
    min-height: 88vh;
    margin-bottom: 10px;
  }

  &__title {
    display: flex;
    justify-content: space-between;

    h2 {
      min-width: 145px;
      text-align: left;
      padding-left: 18px;
      @include text($text-primary, $font-family, 26px, 19px, 500, normal);
    }

    .tagsFilter {
      display: flex;
      justify-content: flex-end;

      &__inner {
        display: flex;
        align-items: center;
        margin-right: 20px;
      }

      &__title {
        position: relative;
        padding: 0 4px 0 14px;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: 1px;
          height: 15px;
          background-color: $theme-color-darkGray;
          transform: translate(0, -50%);
        }
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
      }

      &__itemWrap {
        border-radius: 2px;
        cursor: pointer;
        margin: 3px 0 3px 10px;

        .tag__item {
          cursor: pointer;

          .tag__text {
            cursor: pointer;
          }
        }

        &.active {
          outline: 1px solid $text-primary;
        }
      }
    }
  }

  &__table {
    padding: 0 20px;
    margin-bottom: 10px;
  }

  &__row {
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid $theme-color-darkGray;
    position: relative;
  }

  &__column {
    border-left: 1px solid $theme-color-darkGray;
    text-align: left;
    @include text($text-primary, $font-family, $f-size20, 23px, normal, normal);
    padding: 0 10px;

    &.scale-list__projects {
      width: $scale-table-col1;
    }

    &.scale-list__task {
      width: $scale-table-col2;
    }

    &.scale-list__name {
      width: $scale-table-col3;
    }
  }
}
