@import 'src/styles/variables';
@import 'src/styles/mixins';

.tag {
  &__item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 2px 4px 2px 15px;
    border-radius: 2px;
  
    &::before {
      content: "";
      position: absolute;
      left: 4px;
      top: 50%;
      transform: translate(0, -50%);
      display: block;
      width: 6px;
      height: 6px;
      background-color: $text-secondary;
      border-radius: 50%;
    }
  }

  &__text {
    @include text($text-primary, $font-family, 13px, 15px, 400, normal);
    color: $text-secondary;
    white-space: nowrap;

    &.hover:hover {
      cursor: default;
    }
  }

  &__closeIcon {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }

}