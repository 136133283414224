.TredItem {
  background-color: #efefef;
  width: 100%;
  &__all {
    position: absolute;
    right: 15px;
    top: 0px;
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    border: 1px solid black;
  }

  .notes__text {
    background-color: white !important;
  }
  &__button {
    position: fixed;
    box-sizing: border-box;
    padding: 10px;
    bottom: 80px;
    right: 200px;
    min-width: 210px;
    height: 40px;
    background: #fbfbfb;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25), 0px -1px 5px rgba(48, 48, 48, 0.1);
    border-radius: 16px;
    z-index: 9999;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #303030;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &__content {
    background-color: #ffffff;
    margin: 20px 20px 5px 20px;
    height: calc(100vh - 80px);
    &-body {
      display: flex;
      padding: 20px;
      height: calc(100vh - 175px);
    }
    &-left {
      box-sizing: border-box;
      width: calc(100% - 300px);
      padding-right: 20px;
      .textarea textarea {
        width: 100%;
      }
    }
    &-right {
      overflow-y: hidden;
      overflow-x: hidden;
    }
  }
}
