@import 'src/styles/variables';
@import 'src/styles/mixins';

.usersList {
  padding: 15px 20px 0;
  min-height: 88vh;
  background-color: #ffffff;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid $theme-color-gray;
  }

  &__title {
    box-sizing: border-box;
    position: relative;
    width: 14%;
    margin: 0 0 18px 0;
    padding-left: 28px;
    @include text($text-primary, $font-family, $f-size20, 23px, normal, normal);
    text-align: left;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 2px;
      display: block;
      width: 1px;
      height: 15px;
      background-color: $theme-color-gray;
    }

    &:first-child {
      padding-left: 0;

      &::before {
        width: 0;
      }
    }
  }

  &__text {
    padding: 100px 0;
    @include text($text-primary, $font-family, $f-size20, 23px, normal, normal);
  }
}

@media (max-width: 1440px) {
  .usersList {
    &__title {
      width: 19%;
      margin: 0 0 18px 0;
      padding-left: 5px;

      &::before {
        bottom: 50%;
        transform: translate(0, 50%);
      }

      &:first-child {
        width: 6%;
      }

      &:last-child {
        width: 18%;
      }
    }
  }
}
