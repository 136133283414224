@import 'src/styles/variables';
@import 'src/styles/mixins';

.ForumUserHover {
  position: relative;
  display: flex;
  justify-content: center;

  &:hover {
    .ForumUserHover__item {
      display: flex;
    }
  }
  &__right {
    right: 50%;
  }
  &__left {
    left: 50%;
  }
  &__data {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    font-family: 'Roboto Condensed';
    color: #ffffff;
    &-left {
      display: flex;
      flex-direction: row;
      text-align: start;
      &:nth-child(1) {
        margin-right: 40px;
      }
    }
    text-align: end;
    &-name {
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 10px;
    }
    &-count {
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
    }
  }
  &__item {
    display: none;
    position: absolute;
    z-index: 9;
    flex-direction: column;
    align-items: flex-start;
    top: 25px;
    background-color: #303030;
    padding: 10px;
    min-width: 280px;
    &-line {
      width: 100%;
      background-color: rgba(255, 255, 255, 0.1);
      height: 1px;
      margin: 10px 0px;
    }
  }
  .user {
    &__avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      &-image {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      &-text {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 126%;
        color: #ffffff;
        margin-left: 10px;
      }
    }

    &__status {
      position: relative;
      &-indicator {
        width: 10px;
        height: 10px;
        position: absolute;
        bottom: 2px;
        right: 1px;
        border-radius: 50%;
        border: 1px solid white;
      }
    }
  }
}
