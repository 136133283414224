@import 'src/styles/variables';
@import 'src/styles/mixins';

.appInput__wrapper {
  position: relative;
  padding-bottom: 1px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $theme-color-darkGray;

  &-error {
    border-bottom: 1px solid $text-error;

    &.appInput__text-error {
      padding-bottom: 20px;
    }
  }

  .appInput__label {
    text-align: left;
    padding: 0 8px 0 0;
    color: $text-primary;
    cursor: pointer;
    white-space: nowrap;
    @include text($text-primary, $font-family, $f-size14, 16px, 300, normal);

    &:hover {
      cursor: pointer;
    }
  }

  input {
    box-sizing: border-box;
    padding: 3px 0;
    outline: none;
    border: none;
    width: 100%;
    @include text($text-primary, $font-family, $f-size14, 16px, 300, normal);
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}
.appInput__text-error {
  width: 100%;
  position: absolute;
  top: 100%;
  font-size: $f-size12;
  padding: 1px 0 0 4px;
  color: $text-error;
  pointer-events: none;
}
