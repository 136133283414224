.devPage {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding: 20px;
  &__icon {
    width: auto;
    height: 20px;
    padding: 10px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
