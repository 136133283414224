@import 'src/styles/variables';

.underline-text-icon {
  padding: 0 1px!important;
  &:hover {

    background-color: $theme-color-darkGray;
  }
  &_active {
    padding: 0 1px!important;
    background-color: $theme-color-darkGray;
  }
}
