@import '../../../styles/variables.scss';

.TredItemPerformers {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  text-align: start;
  max-width: 300px;

  &__header {
    display: flex;
    align-items: center;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #303030;
    margin-bottom: 5px;
    margin-bottom: 5px;
  }

  &__body {
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-control {
      padding: 0px 10px 10px 10px;
    }
    &-line {
      background: #afafaf;
      height: 1px;
      width: 100%;
      margin-bottom: 10px;
    }
    &-items {
      display: flex;
      flex-wrap: wrap;

      // minus title and button
      height: calc(#{$TredItem__content-right} - 24px - 52px);
      padding: 10px 10px 0px 10px;
      overflow-y: auto;
      &__inner {
        display: flex;
        flex-wrap: wrap;
        height: fit-content;
        width: 100%;
      }
    }

    &-item {
      border-radius: 4px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      padding: 3px;
      margin-bottom: 10px;
      height: 20px;
      font-family: Roboto Condensed, sans-serif;
      font-size: 16px;
      line-height: 19px;
      font-weight: 300;
      color: #303030;
      font-style: normal;
      white-space: nowrap;
    }
    &-image {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
}
