@import 'src/styles/variables';
@import 'src/styles/mixins';

.menu-left {
  min-width: 240px;
  max-width: 240px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-right: 10px;
  .icons-row {
    &__wrapper {
      &-outer {
        background-color: white;
        & a {
          text-decoration: none;
        }
      }
      &-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        cursor: pointer;
        border-bottom: 1px solid black;
      }
    }
    &__text {
      display: flex;
      justify-content: center;
      align-items: center;
      @include text($text-primary, $font-family, $f-size16, 19px, 400, normal);
    }
  }

  &.menu-left_close {
    min-width: 54px;
    max-width: 54px;
    align-items: center;
    justify-content: space-between;
  }
}

.menu-left__top {
  min-height: 95%;

  &.menu-left__top_close {
    height: 100%;
    min-height: auto;
    margin: 20px;
  }
}

.menu-left__btn {
  height: 5%;
  background-color: #0d0d0d;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: $text-disabled;
  }

  &.menu-left__btn_close {
    height: 5%;
    background-color: #0d0d0d;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 10px;

    &:hover {
      background-color: $text-disabled;
    }

    svg {
      transform: rotate(180deg);
    }
  }
}
