@import 'src/styles/variables';
@import 'src/styles/mixins';

.calendar__picker {
  position: absolute;
  top: 35px;
  right: 40px;
}

.calendarTable {
  padding: 0 20px;

  &__title {
    margin: 20px;
    padding: 0 0 20px 0 !important;
    border-bottom: 1px solid $theme-color-darkGray;
    display: flex;
  }

  &__days {
    display: flex;
    width: 65%;
    max-width: 992px;
    justify-content: flex-end;
  }

  &__day {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    @include text($text-primary, $font-family, $f-size16, 20px, normal, normal);
    border-right: 1px solid $theme-color-darkGray;

    svg {
      cursor: default;
      pointer-events: none;
    }

    &.today {
      color: $text-secondary;
      background-color: black;
    }

    &:first-child {
      border-left: 1px solid $theme-color-darkGray;
    }
  }
}

.calendar {
  width: 100%;
  background-color: #efefef;
  overflow-x: auto;
  min-height: 88vh;
  display: flex;
  .checkbox-wrapper {
    margin-left: auto;
    margin-right: 350px;
    display: flex;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #303030;
    label {
      margin-left: 5px;
    }
  }
  h2 {
    text-align: left;
    padding-left: 18px;
    @include text($text-primary, $font-family, 26px, 19px, 500, normal);
  }
}

.calendar__list {
  width: 100%;
  background-color: $text-secondary;
  display: flex;
  flex-direction: column;
  min-height: 88vh;
}
