.showKarmaModal {
  font-family: 'Roboto Condensed';
  font-style: normal;
  width: 320px;
  box-sizing: border-box;
  &__header {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-bottom: 1px solid #efefef;
    &-avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    &-title {
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #303030;
    }
    &-userName {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: #303030;
    }
    &-texts {
      padding-left: 10px;
    }
    &-left {
      display: flex;
      align-items: center;
    }

    &-allCount {
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #afafaf;
    }
    &-count {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      display: flex;
      align-items: center;
      text-align: right;
    }
    &-positive {
      color: #149939;
    }
    &-negative {
      color: #ee3944;
    }
  }
  &__body {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background: #f5f5f5;
    box-shadow: 1px 1px 5px rgba(48, 48, 48, 0.1);
    max-height: 380px;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      background: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      background: #303030;
      border-radius: 1px;
    }

    &-left {
      display: flex;
      align-items: center;
    }
    &-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80px;
    }
    &-userName {
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      color: #303030;
      margin-left: 10px;
    }
    &-item {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
    }
    &-avatar {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
    &-time {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: #afafaf;
    }
    &-count {
      margin-left: 25px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      min-width: 25px;
      text-align: start;
    }
    &-positive {
      color: #149939;
    }
    &-negative {
      color: #ee3944;
    }
  }
}
