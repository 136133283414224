@import 'src/styles/variables';
@import 'src/styles/mixins';

.polygonItem {
  display: flex;
  align-items: center;
  padding: 10px 0 8px;
  border-bottom: 1px solid $theme-color-gray;
  cursor: pointer;

  &__col {
    position: relative;
    box-sizing: border-box;
    width: $polygon-table-col2;

    display: flex;
    flex-direction: column;
    padding-left: 28px;

    &:first-child {
      width: $polygon-table-col1;
      padding-left: 0;
    }

    &:last-child {
      width: $polygon-table-col3;
    }

    & .polygonItem__content {
      @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
      text-align: left;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      span {
        cursor: pointer;
      }

      &--status {
        display: flex;
        align-items: center;
      }
    }

    & .polygonItem__content:nth-child(2) {
      padding-top: 11px;
    }
  }

  &:last-child {
    border-bottom: none;
  }

  &__statusIndicator {
    width: 8px;
    height: 8px;
    margin-right: 5px;
    border-radius: 50%;

    &--Admin {
      background-color: $theme-color-red;
    }

    &--Moderator {
      background-color: $theme-color-violet;
    }

    &--User {
      background-color: $theme-color-green;
    }
  }
}
