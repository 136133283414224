@import 'src/styles/variables';
@import 'src/styles/mixins';

.CircleUserModal {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);

  max-width: 650px;
  width: 500px;
  z-index: 10;
  &__outer {
    padding: 18px;
    display: flex;
  }
  input {
    width: 100%;
  }
  &__title {
    .default__button {
      &:hover {
        background-color: red !important;
      }
    }
    margin-block: 5px;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }
  &__colorPanel {
    position: absolute;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    padding: 5px;
  }
  &__selector2 {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 5px;
    margin-bottom: 20px;
    .appSelect__wrapper {
      margin-bottom: 0px;
    }
    border-bottom: 1px solid #afafaf;
  }
  &__textarea {
    width: 100%;
    min-height: 150px;
    padding: 8px 12px;
    box-sizing: border-box;
    border: 1px solid #868686;
    border-radius: 2px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
    outline: none;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #303030;
  }
  &__wrappers {
    width: 105px;
    height: 105px;
    margin-left: 30px;
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
  }
  &__click {
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    padding: 14px 20px;
    box-sizing: border-box;
    border: 1px solid #868686;
    border-radius: 2px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
    outline: none;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #303030;
    cursor: pointer;
  }
  &__select {
    background-color: #868686;
  }
  &__textarea2 {
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    padding: 14px 20px;
    box-sizing: border-box;
    border: 1px solid #868686;
    border-radius: 2px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
    outline: none;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #303030;
  }
  &__counts {
    font-family: Roboto Condensed, sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    &-inner {
      margin: 20px 0px;
    }
    &-item {
      font-size: 14px;
      font-weight: 400;
    }
  }
}
