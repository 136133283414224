@import 'src/styles/variables';
@import 'src/styles/mixins';

.TreadsFile {
  width: 100%;
  background-color: #efefef;
  overflow-x: auto;
  min-height: 88vh;
  overflow-y: hidden;
  display: flex;
  * {
    box-sizing: border-box;
  }
  .horizontal__line {
    width: calc(100% - 40px);
    border-bottom: 1px solid $theme-color-gray;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
  &__body {
    min-width: calc(1100px - 300px);
    width: 100%;
    background-color: $text-secondary;
    display: flex;
    flex-direction: column;
    min-height: 88vh;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    &-name {
      background: #303030;
      color: white;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      padding: 5px;
      cursor: pointer;
    }
    &-wrapper {
      display: flex;
      align-items: flex-end;
      @media (max-width: 1400px) {
        button {
          width: 100px !important;
        }
      }
    }
    &-Checkbox {
      display: flex;
      align-items: flex-end;
    }

    h2 {
      min-width: 145px;
      padding-left: 20px;
      margin-top: 20px;
      margin-bottom: 8px;
      text-align: left;
      @include text($text-primary, $font-family, 26px, 19px, 500, normal);
      cursor: pointer;
    }
  }
}
