.CalendarTredModal {
  .tagsSelect__optionsList {
    position: relative;
    left: 0;
  }
  .tagsSelect__optionsList {
    background-color: white;
  }
  .tagsSelect__header {
    margin-bottom: 10px;
    .appInput__text-error {
      margin-top: 20px;
    }
  }
  .labelTitle {
    font-family: Roboto Condensed, sans-serif;
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
    color: #303030;
    font-style: normal;
  }
}
