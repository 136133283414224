@import 'src/styles/variables';

.cursive-text-icon {
  &:hover {
    background-color: $theme-color-darkGray;
  }

  &_active {
    background-color: $theme-color-darkGray;
  }
}
