.DecideOnTheFlyReport {
  padding: 20px;
  width: 360px;
  box-sizing: border-box;
  &__head {
    color: #303030;
    font-family: Roboto Condensed;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
  }
  &__textarea {
    width: 100%;
    min-height: 160px;
    padding: 8px 12px;
    box-sizing: border-box;

    border: 1px solid #efefef;
    background: #fbfbfb;

    border-radius: 4px;

    font-size: 16px;
    resize: none;
    outline: none;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #303030;
    margin-bottom: 20px;
  }
}
