.storage-item {
  width: 248px;
  height: 325px;
  border: 1px solid #efefef;
  &-imageLink {
    text-decoration: none;
  }

  border-radius: 2px;
  margin-right: 16px;
  margin-bottom: 10px;
  background-color: #fbfbfb;
  @media (width: 1920px) {
    margin-right: 12px;
  }
  a {
    background: #ffffff;
  }

  margin-top: 20px;
  font-family: 'Roboto Condensed';
  &:nth-of-type(6n + 6) {
    @media (width: 1920px) {
      margin-right: 0px;
    }
  }
  &__image {
    width: calc(100% - 16px);
    height: calc(212px - 8px);
    min-width: calc(100% - 16px);
    min-height: calc(212px - 8px);
    padding: 8px 8px 0px;
    border-radius: 2px;
    background: #ffffff;
  }
  &__imageIcons {
    width: 20px;
    height: 22px;
    margin-right: 10px;
  }

  &__task-name {
    text-align: start;
    font-weight: 300;
  }
  &__text-wrapper {
    padding: 10px;
    border-top: 1px solid #efefef;
  }
  &__file {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    svg {
      margin-right: 10px;
    }
    span {
      text-align: start;
    }
  }
  &__data {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-top: 10px;
    &-time {
      color: #afafaf;
      svg {
        margin-right: 5px;
      }
    }
  }
}
