.AllTredImageModal {
  padding: 5px;
  min-width: 290px;
  &__images {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, 290px);
    grid-template-rows: 300px;
    max-height: 89vh;
    overflow-y: auto;
    justify-content: space-evenly;
    width: 100%;
  }
  &__image {
    width: 290px;
    height: 290px;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 2px;
    box-sizing: border-box;
    cursor: pointer;
    background-position: center;
  }
  &__imageModal {
    max-width: 98vw;
    max-height: 90vh;
    padding: 5px;
  }
  &__text {
    padding: 10px 20px 10px;
    font-family: Roboto Condensed;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
