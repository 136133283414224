@import 'src/styles/variables';
@import 'src/styles/mixins';

.taskPrice {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;

  @include text($text-secondary, $font-family, $f-size14, 16px, 400, normal);

  svg {
    margin-right: 7px;
  }

  &:hover {
    cursor: pointer;
    color: $theme-color-darkGray;

    svg {
      cursor: pointer;
      .coinsColor {
        fill: #ea9e14;
      }
    }
  }
}