@import 'src/styles/variables';
@import 'src/styles/mixins';

.tasks-list__header,
.TreadHistory__header {
  display: flex;
  justify-content: space-between;

  h2 {
    min-width: 175px;
  }

  .tagsFilter {
    display: flex;
    justify-content: flex-end;

    &__inner {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }

    &__title {
      position: relative;
      padding: 0 4px 0 14px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 15px;
        background-color: $theme-color-darkGray;
        transform: translate(0, -50%);
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
    }

    &__itemWrap {
      border-radius: 2px;
      cursor: pointer;
      margin: 3px 0 3px 10px;

      .tag__item {
        cursor: pointer;

        .tag__text {
          cursor: pointer;
        }
      }

      &.active {
        outline: 1px solid $text-primary;
      }
    }
  }
}
