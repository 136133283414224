.TreadItemFile {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-template-rows: 65px;
  max-height: 10vh;
  overflow-y: auto;
  &__item {
    width: 300px;
    height: 60px;
    box-sizing: border-box;
    background: #fbfbfb;
    border: 1px solid #efefef;
    border-radius: 4px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #303030;
    &-inner {
      display: flex;
      align-items: center;
    }
    &:hover {
      .TreadItemFile__item-edit {
        opacity: 1;
      }
    }
    &-edit {
      opacity: 0;
      transition: 0.5s;
    }
  }
}
