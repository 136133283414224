@import './variables';

@mixin text($textColor, $fontFamily, $fontSize, $lineHeight, $fontWeight, $fontStyle) {
  font-family: $fontFamily;
  font-size: $fontSize;
  line-height: $lineHeight;
  font-weight: $fontWeight;
  color: $textColor;
  font-style: $fontStyle;
}
