@import 'src/styles/variables';
@import 'src/styles/mixins';

.projectBlockTitle {
  width: 100%;
  max-width: 83%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  padding: 13px 0 10px 0;
  @include text($text-secondary, $font-family, $f-size20, 23px, 400, normal);

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    width: 8px;
    height: 8px;
    cursor: pointer;

    &:hover {
      color: red;
    }
  }

  .title__confirm__remove {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;

    h4 {
      margin: 0;
      padding-bottom: 30px;
    }

    .confirm__buttons {
      display: flex;
      justify-content: space-evenly;
    }
  }
}

.projectBlockTitleArchive {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  padding: 13px 0 10px 0;
  @include text($text-primary, $font-family, $f-size20, 23px, 400, normal);

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    width: 8px;
    height: 8px;
    cursor: pointer;

    &:hover {
      color: red;
    }
  }

  .title__confirm__remove {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;

    h4 {
      margin: 0;
      padding-bottom: 30px;
    }

    .confirm__buttons {
      display: flex;
      justify-content: space-evenly;
    }
  }
}
