@import 'src/styles/variables';
@import 'src/styles/mixins';

.userItem {
  display: flex;
  align-items: center;
  padding: 10px 0 8px;
  border-bottom: 1px solid $theme-color-gray;
  &__report {
    cursor: pointer;
    padding: 3px;
  }
  &__tag {
    &-item {
      margin-right: 8px;
      margin-bottom: 5px;
    }

    &-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
    }
    &-empty {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #afafaf;
    }
    &-more {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #303030;
    }
  }
  &__switch-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  &__col {
    position: relative;
    box-sizing: border-box;
    width: 14%;
    display: flex;
    flex-direction: column;
    padding-left: 28px;

    &:first-child {
      padding-left: 0;
    }

    & .userItem__content {
      @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
      text-align: left;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      span {
        cursor: pointer;
      }

      &--status {
        display: flex;
        align-items: center;
      }
    }

    & .userItem__content:nth-child(2) {
      padding-top: 11px;
    }
  }

  &:last-child {
    border-bottom: none;
  }

  &__statusIndicator {
    width: 8px;
    height: 8px;
    margin-right: 5px;
    border-radius: 50%;

    &--Admin {
      background-color: $theme-color-red;
    }

    &--Moderator {
      background-color: $theme-color-violet;
    }

    &--User {
      background-color: $theme-color-green;
    }
    &--Guest {
      background-color: #d9cd22b0;
    }
  }
}

@media (max-width: 1440px) {
  .userItem {
    &__col {
      width: 19%;
      padding-left: 5px;

      &:first-child {
        width: 6%;
      }

      &:last-child {
        width: 18%;
      }
    }
  }
}
