@import 'src/styles/variables';
@import 'src/styles/mixins';

.add-polygon-block-modal {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  padding: 18px;
  max-width: 325px;
  width: 325px;
  z-index: 10;

  .deleteImageIcons {
    position: absolute;
    right: 5px;
    top: 15px;
  }

  .showImage {
    width: 330px;
    height: 200px;
    margin: 10px 0;
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;

    p {
      padding-right: 5px;
    }

    span {
      margin-right: 10px;
    }
  }

  &__colors-container {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
  }

  &__colors-items {
    display: flex;
    margin-bottom: 5px;
    max-width: 220px;
    overflow-x: auto;
  }

  &__colors-item {
    min-width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  &__file {
    display: none;
    &-button {
      border: 1px solid black;
      padding: 5px;
      display: block;
      cursor: pointer;
      display: flex;
      justify-content: center;
      &:hover {
        background-color: green;
        color: white;
      }
    }
  }

  &__colors-picker {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 300px;
    background-color: white;
    border: 1px solid #c0c0c0;

    &-item {
      width: 20px;
      height: 20px;
    }
  }

  &__label {
    white-space: nowrap;
  }

  &__input {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $theme-color-darkGray;
    margin-bottom: 15px;

    span {
      width: 160px;
      margin-right: 15px;
    }

    & .appInput__wrapper {
      margin-bottom: 0;
      width: 100%;
      border-bottom: none;

      input {
        @include text($text-primary, 'Roboto Condensed', 14px, 16px, 300, normal);
        border: none;
        outline: none;
        padding: 0;
        width: 100%;
      }
    }
  }

  &__colors {
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .polygon-arrow-color-item {
      max-width: none;
    }
  }

  &__buttons {
    display: flex;
  }
}
