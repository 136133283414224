.UserTred {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 138%;
  color: #303030;
  padding: 0px 10px 20px 10px;
  &__item {
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    &:hover {
      .userTagList__remove {
        display: block;
      }
      background: #f5f5f5;
    }
  }
  &__status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }
  &__link {
    margin-left: auto;
  }
}
