@import 'src/styles/variables';
@import 'src/styles/mixins';

.performers__main {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  width: calc(100% - 20px);
  padding: 8px;
  background-color: #f5f5f5 !important;
  .performers {
    &__groups {
      margin: 10px 0px;
      &-title {
        text-align: start;
      }
      &-buttons {
        display: flex;
        flex-wrap: wrap;
      }
      &-button {
        padding: 2px;
        margin: 3px;
        background-color: #cac1c1;
        border-radius: 2px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &-red {
          background-color: red;
          display: flex;
          align-items: center;
          color: white;
          padding-right: 3px;
        }
      }
    }
  }

  .performers__description {
    width: 100%;
    border-bottom: none;
    display: flex;
    margin-top: 0;

    p {
      padding-right: 5px;
    }

    input {
      @include text($text-primary, $font-family, 14px, 16px, 300, normal);
      border: none;
      outline: none;
      width: 206px;
      padding: 0;
    }
  }

  .performers__search {
    width: 100%;
    border-bottom: 1px solid $theme-color-darkGray;
    margin: 15px 0 0 0;
    display: flex;
    align-items: center;

    img {
      width: 8px;
      height: 8px;
    }

    input {
      width: 100%;
      @include text($text-primary, $font-family, 14px, 16px, 300, normal);
      background-color: $theme-color-lightGray;
      outline: none;
      border: none;
      margin: 3px 5px;
    }
  }

  .performers__list {
    max-height: 300px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 10px;
    border-bottom: 1px solid $theme-color-darkGray;

    & .list__item,
    &-maintainer {
      width: 100%;
      display: flex;
      height: 16px;
      justify-content: space-between;
      align-items: center;
      margin: 6px 0;

      &:hover {
        .list__item-add {
          display: block;
        }
      }

      & .list__item-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & svg {
        width: 10px;
        height: 10px;
      }
    }

    p {
      padding: 5px 0;
    }

    .list__item-add {
      transform: rotate(45deg);
      cursor: pointer;

      svg {
        &:hover {
          .crossColor {
            fill: #149939;
          }
        }
      }
    }
  }

  & .default__button {
    margin-top: 10px;
    @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  }
}
