@import '../../styles/variables';
@import '../../styles/mixins';

.mainContentHeaderTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
  height: auto;
  word-break: break-word;
  max-width: 71%;

  .mainContentHeaderTitle__title {
    text-overflow: ellipsis;
    text-align: left;
    @include text($text-primary, $font-family, $f-size20, 23px, bold, normal);
  }
}