.ReportChronographModal {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 500px;
  padding: 20px;
  &__title {
    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
    }
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;

    color: #303030;
  }
  &__labels {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  &__pickers {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  &__popup {
    position: relative;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    &-inside {
      position: absolute;
      z-index: 9999999999;
      background: #ffffff;
      box-shadow: 0px 2px 5px rgba(48, 48, 48, 0.15);
      border-radius: 3px;
      padding: 5px;
    }
    &-item {
      padding: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .datePickerC {
    &__label {
      padding: 0px;
      height: 30px;
    }
    &__title {
      width: 150px;
      height: 30px;
      background: #f5f5f5;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      box-sizing: border-box;
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
    }
    &__container {
      position: absolute;
      left: 0px;
    }
    .react-datepicker {
      background: #ffffff;
      border: none;
      margin-top: 10px;
      box-shadow: 0px 2px 5px rgba(48, 48, 48, 0.15);
    }
    .react-datepicker__input-container {
      display: none;
    }
    .react-datepicker__triangle {
      display: none;
    }
    .react-datepicker-popper {
      transform: none !important;
      padding-top: 0px;
    }
    .react-datepicker__month-container {
      width: 366px;
    }
    .react-datepicker__header {
      background-color: white;
      border-bottom: none;
    }
    .react-datepicker__navigation-icon::before {
      border-width: 1px 1px 0 0;
      border-color: #222730;
    }
    .react-datepicker__current-month {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 125%;
      color: #222730;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  .table {
    &__modal {
      width: 100%;
      min-height: 10px;
      margin-top: 20px;
      max-height: 320px;
      overflow-y: auto;
    }
    &__row {
      width: 33%;
      display: flex;
      align-items: center;
      padding: 10px 0px;
      &:nth-of-type(1) {
        border-right: 1px solid #efefef;
      }
      &:nth-of-type(2) {
        border-right: 1px solid #efefef;
      }
      hyphens: manual;
      overflow-x: hidden;
    }
    &__col {
      display: flex;
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #303030;
    }
  }
}
