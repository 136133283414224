.packageControll {
  display: flex;
  width: 100%;
  align-items: center;
  .appInput__wrapper {
    margin-bottom: 0px;
    width: 260px;
    input {
      padding: 8px 0px;
    }
  }
  &__label {
    text-align: start;
    color: #303030;
    font-family: Roboto Condensed;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
  }
  &__input {
    margin-right: 20px;
  }
  &__picker {
    display: flex;
    flex-direction: column;
    position: relative;

    &-inner {
      display: flex;
      align-items: center;
      margin-top: 5px;
      cursor: pointer;
    }
    &-color {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background-color: red;
    }
    &-label {
      color: #303030;
      font-family: Roboto Condensed;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 14px;
    }
    &-panel {
      position: absolute;
      top: 50px;
      z-index: 999;
    }
  }

  margin-bottom: 40px;
}
