.DoubleSliderFormik {
  font-family: Roboto Condensed, sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
  color: #303030;
  font-style: normal;

  &__inner {
    display: flex;
    align-items: center;
  }
  &__title {
    margin-bottom: 5px;
  }
}
