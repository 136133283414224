.DecideOnTheFlyControlItems {
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #303030;
    text-align: center;
    font-family: Roboto Condensed;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding: 15px;
  }

  &__table {
    border-radius: 4px;
    border: 1px solid #efefef;

    box-sizing: border-box;
    &-body {
      height: 40vh;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 15px;
      padding-top: 0px;
    }
  }
  &__empty {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #303030;
    text-align: center;
    font-family: Roboto Condensed;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

.DecideOnTheFlyControlItem {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: #303030;
  font-family: Roboto Condensed;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid #efefef;
  background: #fbfbfb;
  height: 56px;
  padding: 10px 10px;
  margin-top: 15px;
  &:nth-of-type(1) {
    margin-top: 0px;
  }
  &__name {
    text-align: start;
    width: 65%;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    white-space: nowrap;
  }
  &__count {
    width: 23%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 12%;
  }
}
