.DeleteTredsFile {
  padding: 20px;
  width: 360px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__header {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #303030;
  }
  &__message {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #afafaf;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
