.TreadItem {
  &__quote {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    border-left: 2px solid #303030;
    margin-bottom: 10px;
    &-userData {
      display: flex;
      align-items: center;
    }
    &-user {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #303030;
      margin: 0px 5px;
    }
    &-time {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #afafaf;
    }
    &-content {
      padding: 5px 5px 5px 20px;
    }

    &-header {
      display: flex;
    }
    &-image {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-left: 5px;
      margin-right: 5px;
    }
    &-body {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: #303030;
      text-align: start;
      margin-top: 20px;

      img {
        float: left;
        width: 60%;
      }
      .iframe {
        width: 60%;
      }

      .ql-align-right {
        img {
          float: right;
          width: 60%;
        }
      }
    }
  }
}
