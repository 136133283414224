@import 'src/styles/variables';
@import 'src/styles/mixins';

.users {
  display: flex;
  flex-direction: column;
  min-height: 88vh;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__info-text {
    padding-left: 5px;
    @include text($text-primary, $font-family, $f-size16, 19px, normal, normal);

    span {
      white-space: nowrap;
    }
  }
}
