.chronoEnergyModal {
  width: 360px;
  box-sizing: border-box;
  padding: 20px;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  color: #303030;
  &__title {
    font-size: 14px;
    margin-bottom: 25px;
  }
  &__date {
    font-size: 20px;
  }
  &__time {
    font-size: 20px;
    font-weight: 600;
  }
  &__energy {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #303030;
    margin-bottom: 15px;
  }
  &__tasks {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #303030;
    margin-bottom: 15px;
    margin-top: 30px;
    &-element {
      display: flex;
      align-items: center;
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #303030;
      border-bottom: 1px solid #afafaf;
      height: 30px;
    }
  }
  &__button {
    &-wrapper {
      display: flex;
      padding: 4px;
      background-color: #fbfbfb;
      border: 1px solid #efefef;
    }
    &-positive {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 50%;
      background-color: #fbfbfb;
      color: #303030;
      margin-right: 2px;
      border-radius: 2px;
      cursor: pointer;
      &__select {
        background-color: #debb3f;
        color: #ffffff;
      }
    }
    &-negative {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 50%;
      background-color: #fbfbfb;
      color: #303030;
      margin-left: 2px;
      border-radius: 2px;
      cursor: pointer;
      &__select {
        background-color: #303030;
        color: #ffffff;
      }
    }
  }
}
