.DropdownTemplate {
  position: relative;
  &__open {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0px;
    background: #fbfbfb;
    box-shadow: 1px 1px 5px rgba(48, 48, 48, 0.1);
    border-radius: 2px;
    width: 108px;
    height: 40px;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin: 5px;
    cursor: pointer;
  }
  &__select {
    background-color: black;
  }
}
