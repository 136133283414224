@import 'src/styles/variables';
@import 'src/styles/mixins';

thead {
  tr {
    th {
      padding-bottom: 20px;
      border-bottom: 1px solid $theme-color-darkGray;

      p {
        border-left: 1px solid $theme-color-darkGray;
        text-align: left;
        @include text($text-primary, $font-family, $f-size20, 23px, normal, normal);
        padding: 0 10px;
      }
    }
  }
}
