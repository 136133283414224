@import 'src/styles/variables';
@import 'src/styles/mixins';

.calendarTable {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $theme-color-darkGray;

    &--firstColumn {
      border-left: 1px solid $theme-color-darkGray;
      text-align: left;
      @include text($text-primary, $font-family, $f-size20, 23px, normal, normal);
      padding: 0 10px;
      width: 20%;
    }
  }
}