.DecideOnTheFlyCircle__item {
  position: absolute;
  transform-origin: 50% 50%;
  text-align: center;
  font-size: 20px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  &-text {
    position: absolute;
    transform: rotate(180deg);
    z-index: 999;
    width: 200px;
    left: 0px;
  }
  color: #fff;
  font-family: Roboto Condensed;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
