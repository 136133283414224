.decideOnTheFlyPackageItem {
  font-family: Roboto Condensed;
  color: #303030;
  box-sizing: border-box;
  &__inner {
    background: #efefef;
    padding: 15px;
  }
  * {
    box-sizing: border-box;
  }

  width: 368px;

  &__name {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: start;
    &-text {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &-color {
      min-width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: 8px;
    }
    margin-bottom: 15px;
  }
  &__subscriptions {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: start;
    margin-bottom: 15px;
    max-height: 49px;
    overflow: hidden;
    &-text {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  &__footer {
    display: flex;
    width: 100%;
    height: 28px;
    align-items: center;

    &-percent {
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 50px;
      background: #fff;
      color: #303030;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      width: 50px;
      margin: 0px 12px;
    }
    &-status {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 50px;
      color: #fff;
      height: 28px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      width: 101px;
    }
    &-counter {
      color: #303030;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      padding-left: 8px;
    }
    &-counterName {
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 115px;
      border-radius: 50px;
      background: #fff;
      color: #afafaf;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
}
