.customTooltip {
  width: 395px;
  background: #ffffff;
  border-left: 4px solid #fbe000;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25), 0px 1px 5px rgba(48, 48, 48, 0.1);
  border-radius: 2px;
  padding: 20px;
  font-family: 'Roboto Condensed';

  &__scaleTask {
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-align: start;
    &-date {
      color: #afafaf;
      margin-left: auto;
      white-space: nowrap;
    }
    &-item {
      min-width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 15px;
      margin-left: 5%;
    }
  }

  &__date {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 142%;
    color: #afafaf;
    white-space: nowrap;
  }
  &__inWork {
    box-sizing: border-box;
    padding: 6px 12px 6px 8px;
    margin-top: 15px;
    display: flex;

    align-items: center;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #303030;
    height: 36px;
    border-radius: 4px;
    &-count {
      font-weight: 400;
      font-size: 20px;
    }
    margin-bottom: 15px;
  }
  &__item {
    margin-bottom: 10px;
    &-title {
      box-sizing: border-box;
      padding: 6px 12px 6px 8px;
      font-weight: 300;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      background: #303030;
      border-radius: 1px;
      height: 36px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
    &-task {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: #303030;
      margin: 10px 0px;
    }
    &-name {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }
}
