.addTaskInPlanModal2 {
  width: 520px;
  padding: 20px;

  .appInput__wrapper,
  .appInput__field {
    width: 100%;
  }
  &__errors {
    color: red;
    margin-top: 5px;
    text-align: end;
  }
  &__colorPanel {
    position: absolute;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    padding: 5px;
  }
  .appInput__wrapper {
    margin: 0px;
    border-bottom: 0px;
    margin-left: 5px;
  }
  &__title {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #303030;
    margin-bottom: 40px;
  }
  &__selector {
    display: flex;
    align-items: center;
    .appSelect__wrapper {
      margin-bottom: 0px;
    }
    border-bottom: 2px solid #afafaf;
  }
  &__selector2 {
    display: flex;
    align-items: center;
    .appSelect__wrapper {
      margin-bottom: 0px;
    }
    border-bottom: 2px solid #afafaf;
  }
  &__table {
    margin-top: 20px;
    max-height: 60vh;
    overflow-y: auto;
    &-checkbox {
      margin-left: 12px;
    }
    &-title {
      display: flex;
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: #303030;
      padding-bottom: 5px;
      margin-bottom: 20px;
      border-bottom: 1px solid #d3d2d2;
    }
    &-rows {
      display: flex;
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #303030;
      margin-bottom: 30px;
    }
    &-color {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      border: 1px solid black;
    }
    &-col1 {
      width: 60%;
      display: flex;
      align-items: center;
    }
    &-col2 {
      width: 20%;
    }
    &-col3 {
      width: 40%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 10px;
    }
  }
  &__button {
    &-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 50px;
    }
  }
  &__addRight {
    width: 10%;
    padding-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 7px;
  }
}
