.changeWeeks {
  display: flex;
  align-items: center;
  justify-content: center;
  &__transform {
    transform: rotate(180deg);
  }
  &__text {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;

    color: #303030;
    margin: 0px 20px;
  }
}
