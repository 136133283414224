@import 'src/styles/variables';
@import 'src/styles/mixins';

.EditSingleTagModal {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  padding: 20px;
  max-width: 360px;
  width: 360px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  .change__taq {
    display: flex;
    flex-direction: column;
    align-items: center;
    .appInput__wrapper {
      margin-bottom: 20px;
      width: 100%;
      .appInput__label {
        white-space: nowrap;
      }
      .appInput__field {
        width: 100%;
      }
    }
    .tag-color {
      &__wrapper {
        &-outer {
          display: flex;
          width: 100%;
        }
        &-inner {
          display: flex;
          &:nth-of-type(1) {
            width: 100px;
          }
          background-color: #f5f5f5;
          padding: 0 10px;
        }
      }
      &__text {
        white-space: nowrap;
      }
      &__element {
        width: 15px;
        height: 15px;
        border-radius: 2px;
        border: 1px solid #d7d0d0;
        margin-left: 10px;
      }
    }
  }
}
