@import 'src/styles/variables';
@import 'src/styles/mixins';

$width-avatar: 200px;

.changeAvatar {
  .avatar__image-upload {
    border-radius: 50%;
    input {
      display: none;
      position: absolute;
      visibility: hidden;
      left: 0;
      width: $width-avatar;
      height: $width-avatar;
      z-index: 1000;
    }
  }
  .avatar__upload {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    opacity: 0.8;
    pointer-events: all;
    border-radius: 50%;

    z-index: 3;
  }
  .user__head-avatar:hover .avatar__upload {
    display: block;
  }

  .user__head-avatar {
    border-radius: 50%;
    width: $width-avatar;
    height: $width-avatar;
    position: relative;
  }
  & img {
    cursor: pointer;
    width: $width-avatar;
    height: $width-avatar;
    border-radius: 50%;
  }

  & span {
    @include text($text-secondary, $font-family, $f-size20, 23px, 300, normal);
    cursor: default;
  }
  .user__info {
    padding-left: 17px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    word-break: break-word;

    input:focus {
      cursor: auto;
    }

    .user__info-name {
      padding-left: 0;
      padding-right: 0;
      @include text($text-secondary, $font-family, $f-size14, 16.41px, 300, normal);
      cursor: pointer;
      background: transparent;
      border: none;
      outline: none;
      max-width: 100px;
    }
  }
}
