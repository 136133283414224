.templateAll {
  padding: 20px;
  width: 200px;
  &-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
}
