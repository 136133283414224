@import 'src/styles/variables';

.send-to-top-icon {
  &:hover {
    border-left: none;
  }
}

.send-to-bottom-icon {
  transform: rotate(180deg);
}