.chronometer {
  &__wrapper {
    position: relative;
    width: 400px;

    @media (max-width: 1370px) {
      transform: scale(0.82);
    }
  }
  &__html {
    position: absolute;
    top: 51px;
    left: 51px;
    z-index: 10;
  }
}
