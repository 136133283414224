@import 'src/styles/variables';
@import 'src/styles/mixins';

.subtasks {
  max-height: 55%;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;

    .subtasks__add {
      display: flex;
    }
  }

  &__list {
    max-height: calc(100% - 53px);
    overflow-y: auto;
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $theme-color-lightGray;
    @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);

    .subtasks__item {
      display: flex;
      align-content: center;
      align-items: center;
      width: 100%;
      margin: 5px 0;
      word-break: break-word;

      &-linkWrap {
        display: flex;
        width: 50%;

        & .polygon-icon {
          margin-left: 5px;
        }
      }

      & .project__status {
        display: flex;
        align-items: flex-start;
        align-self: flex-start;
        margin: 3px 10px 0 0;
      }

      &-link {
        display: flex;
        align-items: flex-start;
        align-self: flex-start;
        padding-right: 10px;
        width: 100%;
        cursor: pointer;
        text-decoration: none;
        text-align: start;
        color: $text-primary;
        &:hover p {
          text-decoration: underline;
          color: $text-active;
  
          &:active {
            color: $text-active;
            text-decoration: none;
          }
        }

        span {
          margin-right: 10px;
          word-break: normal;
        }
      }

      & .user__item {
        margin-right: 8px;
        padding-left: 10px;
        height: 16px;
        display: flex;
        white-space: nowrap;

        span {
          margin-bottom: 2px;
        }
      }

      & .tagsList {
        display: flex;
        flex-wrap: wrap;
        cursor: default;

        & .tag__item {
          margin: 0 4px 4px 0;
        }
      }

      &-deadline {
        min-width: auto;
        margin-left: auto;
      }

      .newTask {
        margin-right: 3px;
      }
    }
  }

  &__info-text {
    padding-top: 20px;
    @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
  }
}