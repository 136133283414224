@import 'src/styles/variables';
@import 'src/styles/mixins';

.menu__updates {
  display: flex;
  flex-direction: column;
  height: 50%;
  @media (max-height: 880px) {
    height: 48%;
  }
  // @media (max-height: 845px) {
  //   height: 40%;
  // }
  width: 100%;
  .left-title {
    padding: 15px;
    background-color: #f5f5f5;
    .menu__title {
      padding: 0;
      min-height: 0;
      &-name {
        margin-left: 10px;
        align-items: center;
        @include text(#7c7c7c, $font-family, $f-size16, 19px, 500, normal);
      }
      &-count-container {
        display: flex;
        align-items: center;
        margin-left: auto;
      }
    }
  }

  .menu__content {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .empty__updates-list {
      margin-top: 20px;
      text-align: center;
      @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
      animation: example 0.3s ease-in-out;
    }

    > div {
      margin: 5px 10px 0 10px;
      padding-bottom: 10px;
      animation: example 0.3s ease-in-out;
    }
    @keyframes example {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 100;
      }
    }

    > div {
      border-bottom: 1px solid $theme-color-darkGray;
    }

    background-color: $text-secondary;
  }
}
