@import 'src/styles/variables';
@import 'src/styles/mixins';

.menu__title {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $theme-color-lightGray;
  min-height: 40px;

  &-name {
    margin-left: 10px;
    align-items: center;
    @include text(#7C7C7C, $font-family, $f-size16, 19px, 500, normal);
  }

  &-count {
    @include text($text-active, $font-family, $f-size16, 19px, 300, normal);
    margin-left: auto;
    margin-right: 5px;
  }
}
