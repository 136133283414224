.background__loading {
  background-color: white;
  opacity: 0.4;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 11110;
  top: 0;
  left: 0;
  right: 0;
}
