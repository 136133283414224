@import 'src/styles/variables';
@import 'src/styles/mixins';

.list {
  display: flex;
  align-items: center;
  margin: 0 20px;
  @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
  border-bottom: 1px solid $theme-color-darkGray;
  padding: 10px 0;
  text-decoration: none;

  &:hover {
    background-color: $theme-color-lightGray;
    cursor: pointer;
  }

  .list__date {
    width: 33.3%;
    display: flex;
    justify-content: space-between;

    & span {
      width: 50%;
      text-align: start;
      padding-left: 8px;
    }

    & .user__item {
      width: 50%;
      text-align: start;
      padding-right: 13px;
    }
  }

  .list__projectName,
  .list__taskName {
    text-align: start;
    width: 33%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .list__projectName {
    padding-left: 4px;
    padding-right: 11px;
  }

  .list__taskName {
    display: flex;
    align-items: center;
    margin-left: -4px;

    .list__title {
      padding: 0 8px;
      cursor: pointer;
      text-decoration: none;
      color: $text-primary;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        text-decoration: underline;
        color: $text-active;

        &:active {
          color: $text-active;
          text-decoration: none;
        }
      }
    }
  }
}
