.selectPackageModal {
  width: 380px;
  background: #fff;
  padding: 20px;
  font-family: Roboto Condensed;
  .switch2 {
    min-width: 40px;
  }
  &__title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #303030;
    font-family: Roboto Condensed;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 40px;
  }
  &__body {
    max-height: 50vh;
    overflow-y: auto;
    padding-right: 3px;
  }
  &__color {
    width: 16px;
    min-width: 16px;
    height: 16px;
    border-radius: 1000000px;
    background: #149939;
    margin-right: 10px;
  }
  &__switchWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-outer {
      margin-bottom: 35px;
    }
    &-inner {
      display: flex;
      align-items: center;
      color: #303030;
      font-family: Roboto Condensed;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
    &-text {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  &__description {
    color: #303030;
    font-family: Roboto Condensed;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 10px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
