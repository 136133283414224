$windowWidth: 1520px;
$windowHeight: 880px;

.treadsCard {
  width: 333px;
  @media (max-width: $windowWidth), (max-height: $windowHeight) {
    width: 260px;
  }
  border-radius: 4px;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #303030;
  &__new {
    color: green;
    font-weight: 600;
    width: 100%;
    text-align: start;
  }

  &__header {
    background: #303030;
    box-sizing: border-box;
    background-size: cover;
    z-index: 1;
    width: 100%;
    height: 65px;
    position: relative;

    @media (max-width: $windowWidth), (max-height: $windowHeight) {
      height: 45px;
      padding: 0px 5px;
      height: 30px;
      padding: 0px 5px;
    }
    border-radius: 4px 4px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    &-status {
      box-sizing: border-box;
      display: flex;
      background: white;
      border-radius: 2px;
      padding: 4px;
      @media (max-width: $windowWidth), (max-height: $windowHeight) {
        margin-bottom: 8px;
      }
      height: 10px;
      width: 60%;
    }
    &-tags {
      position: absolute;
      top: 55px;
      right: 20px;
      display: flex;
      max-width: 220px;
      flex-wrap: wrap;
      justify-content: flex-end;
      @media (max-width: $windowWidth), (max-height: $windowHeight) {
        top: 20px;
        right: 5px;
      }
    }
  }
  &__body {
    height: 128px;
    position: relative;

    @media (max-width: $windowWidth), (max-height: $windowHeight) {
      height: 110px;
      padding: 0px 5px;
      height: 93px;
    }
    background: white;
    border-radius: 0px 0px 4px 4px;
    box-sizing: border-box;
    padding: 0px 20px;

    &-two {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-top: 35px;
      height: 50px;
      @media (max-width: $windowWidth), (max-height: $windowHeight) {
        height: 40px;
        padding-top: 18px;
      }
      &__item {
        height: 22px;
        min-height: 22px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        padding: 0px 5px;
        margin-bottom: 4px;
        @media (max-width: $windowWidth), (max-height: $windowHeight) {
          margin-bottom: 1px;
          height: 18px;
          min-height: 18px;
        }

        &-color {
          height: 6px;
          width: 6px;
          border-radius: 50%;
          margin: 0px 5px;
        }
      }
    }
    &-tree {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;

      margin-top: auto;
      bottom: 15px;
      left: 20px;
      right: 20px;
      @media (max-width: $windowWidth), (max-height: $windowHeight) {
        height: 10px;
        padding-top: 25px;
        left: 5px;
        right: 5px;
      }
      .tag__item {
        height: 24px;
        box-sizing: border-box;
      }
    }
  }
  &__avatar {
    border: 2px solid white;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    margin-top: 60px;

    @media (max-width: $windowWidth), (max-height: $windowHeight) {
      width: 35px;
      height: 35px;
      margin-top: 25px;
    }
  }
}
