@import '../../../styles/variables';
@import '../../../styles/mixins';

.polygon-block-menu__colors {
  .popover {
    display: flex;
    border-radius: unset;
    background-color: $theme-color-black;
    top: 103%;
    right: 50%;
    padding: 10px;
  }
}