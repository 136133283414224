@import 'src/styles/variables';
@import 'src/styles/mixins';

.executors {
  .executors__header {
    padding: 10px 0;
  }
  .show__modal {
    &:hover {
      .WrapperUserStatisticBlock {
        display: block;
      }
    }
  }

  .executors__content {
    background-color: $theme-color-lightGray;
    @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
    padding: 10px;

    .executors__title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 7px;
      border-bottom: 1px solid $theme-color-darkGray;
      cursor: pointer;

      img {
        margin: 0 5px;
      }
    }

    .executors__users {
      max-height: 69px;
      display: flex;
      flex-wrap: wrap;
      padding: 7px 0;
      border-bottom: 1px solid $theme-color-darkGray;
      overflow-y: auto;
      margin-left: -5px;

      .concealer {
        position: absolute;
        height: 60%;
        width: 3px;
        z-index: 2;
      }

      .executors__maintainer {
        & .user__item {
          margin-left: -5px;

          &:first-child {
            @include text($text-active, $font-family, $f-size16, 19px, 700, normal);
          }
        }
      }

      & .user__item {
        margin: 5px 10px 5px -5px;
        height: 13px;

        &::before {
          content: '|';
          margin-right: 6px;
        }

        &:hover {
          text-decoration: underline;
          cursor: default;
        }
      }
    }

    .executors__managing {
      display: flex;
      padding-top: 7px;
    }
  }
}
