.NewLine {
  width: 720px;
  padding: 20px;
  .appInput__wrapper,
  .appInput__field {
    width: 100%;
  }
  &__errors {
    color: red;
    margin-top: 5px;
    text-align: end;
  }
  &__colorPanel {
    position: absolute;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    padding: 5px;
  }
  .appInput__wrapper {
    margin: 0px;
    border-bottom: 0px;
    margin-left: 5px;
  }

  &__selector2 {
    display: flex;
    align-items: center;
    .appSelect__wrapper {
      margin-bottom: 0px;
    }
    border-bottom: 2px solid #afafaf;
  }

  &__addRight {
    width: 8%;
    padding-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 7px;
  }
}
