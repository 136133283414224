@import 'src/styles/variables';

.center-align-text-icon {
  &:hover {
    background-color: $theme-color-darkGray;
  }
  &_active {
    background-color: $theme-color-darkGray;
  }
}
