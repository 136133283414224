@import '../../styles/variables';
@import '../../styles/mixins';

.user-chat {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 135px);
  padding: 20px;
  margin-right: 0;
  background-color: $text-secondary;

  & .chat {
    .chat__content {
      background-color: $section-primary !important;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    max-width: 300px;

    .notes {
      width: 100%;
      height: 380px;

      .notes__text {
        padding: 10px;
        height: calc((100vh - 495px) - 25px) !important;

        textarea {
          width: 100%;
          padding: 0;
          min-height: auto;
          max-height: none;
        }
      }
    }

    .files {
      margin: 10px 0 15px 0;
      height: 400px !important;

      .mainContentHeaderItem {
        height: 23px;
        padding: 0;
        margin-bottom: 10px;
      }

      .main {
        padding: 0;
        width: 300px;
        height: 283px;
        min-height: 283px;

        .line {
          padding-top: 10px;
          margin: 0 10px;
          width: 280px;
        }

        .files__list {
          width: 280px;
          height: 100%;

          .list__item {
            .item__left {
              span {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}
