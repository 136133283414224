@import 'src/styles/variables';

.editBlock {
  display: flex;
  align-items: center;
  padding: 3px 0 3px 10px;

  .editBlock__items {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    & .start-button {
      padding: 0 3px;
    }

    & .editTitle {
      padding: 0 5px;
    }

    & .taskPrice {
      padding: 0 5px;
    }

    & .deleteBlock {
      padding-left: 5px;
    }

    & .polygon-icon {
      margin-right: 10px;
    }

    .options-wrapper {
      margin-top: 2px;

      &:hover {
        .popover {
          display: flex;
          position: absolute;
          top: 15px;
          right: 10px;
        }
      }
    }

    & .popover {
      padding: 20px;
      background-color: $theme-color-black;
      border: 1px solid white;

      span {
        margin-left: 5px;
      }

      & div {
        margin: 10px 0;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
