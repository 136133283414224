@import 'src/styles/variables';
@import 'src/styles/mixins';

.polygon-search {
  width: 32%;
  max-width: 520px;
  min-width: 240px;
  position: relative;
  margin-right: 40px;

  &__input {
    box-sizing: border-box;
    width: 100%;
    padding: 10px 20px 11px 42px;
    background-color: #ffffff;
    outline: none;
    border: 1px solid #efefef;
  }

  &__input,
  &__input::placeholder {
    @include text($text-primary, $font-family, $f-size16, 19px, normal, normal);
  }

  &__icon {
    position: absolute;
    top: 14px;
    left: 20px;
    padding: 0;
    border: none;
    background-color: transparent;

    &:hover {
      cursor: pointer;
      fill: $theme-color-green;
    }
  }
}

@media (max-width: 1240px) {
  .polygon-search {
    margin-right: 10px;
  }
}
