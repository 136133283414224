@import 'src/styles/variables';

svg {
  cursor: pointer;
  &:hover {
    .path {
      fill: $theme-color-lightBlue;
    }
  }
}

.link-icon {
  &:hover {
    background-color: $theme-color-darkGray;
    border-left: none;
  }
}