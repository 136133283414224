@import 'src/styles/variables';
@import 'src/styles/mixins';

.calendarPicker {
  width: 304px;

  &__inputWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
  }

  &__navIcon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    height: 32px;
    border-radius: 2px;

    div {
      width: 8px;
      height: 8px;
      border-color: black;
      border-style: solid;
      border-width: 0;
      border-bottom-width: 1px;
      border-left-width: 1px;
    }

    &--prev div {
      transform: rotate(45deg);
    }

    &--next div {
      transform: rotate(225deg) translate(1px, -2px);
    }

    &:hover {
      background-color: $theme-color-black;
      cursor: pointer;
      
      & div {
        border-color: #ffffff;
      }
    }
  }
  
  .react-datepicker {
    font-family: $font-family;
    border-radius: 0;
    border: none;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25), 0px 1px 5px rgba(48, 48, 48, 0.1);
  }

  .react-datepicker__input-container {
    input {
      width: 140px;
      @include text($text-primary, $font-family, $f-size20, 24px, 500, normal);
      outline: none;
      border: none;
      padding: 0;
      text-align: center;
      text-transform: capitalize;
      
      &:hover {
        cursor: pointer;
      }
    }
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation {
    margin-top: 24px;
    width: 16px;
    height: 16px;

    &--previous {
      margin-left: 20px;
    
    }

    &--next {
      margin-right: 20px;
      
    }

    &-icon {
      &::before {
        border-color: $theme-color-black;
        border-width: 1px 1px 0 0;
      }
    }

  }

  .react-datepicker__header {
    margin:  20px 20px 0;
    padding: 0 0 15px 0;
    @include text($theme-color-black, $font-family, $f-size20, 24px, 500, normal);
    background-color: #ffffff;
    border-bottom: 1px solid $theme-color-darkGray
  }

  .react-datepicker-year-header {
     border-bottom: none;
  } 

  .react-datepicker__monthPicker {
    margin: 0 8px 8px;

    .react-datepicker__month-wrapper {
      display: flex;
    }

    .react-datepicker__month-text {
      box-sizing: border-box;
      width: 50px;
      height: 30px;
      margin: 10px 12px;
      @include text($theme-color-black, $font-family, $f-size16, 30px, 400, normal);
      border-radius: 3px;
      text-transform: capitalize;
      overflow: hidden;
    }

    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--keyboard-selected {
      color: #ffffff;
      background-color: $theme-color-black;
    }
  }
  
}