.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  /* Rounded sliders */
  .slider {
    position: absolute;
    cursor: pointer;
    background-color: #149939;
    box-shadow: 0 0 1px #149939;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: '';
      height: 19px;
      width: 19px;
      left: 4px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  .slider.round {
    border-radius: 34px;
    &:before {
      border-radius: 50%;
    }
  }
  .slider.slider__true {
    &:before {
      position: absolute;
      content: url('../../assets/akar-icons_check.svg');
      height: 19px;
      width: 19px;
      left: 4px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
}
