.SelectTypeChat {
  &__selectChat {
    position: absolute;
    display: flex;
    top: 0px;
    background-color: white;
    padding: 3px;
    &-button {
      width: 40px;
      height: 20px;

      padding: 3px;
      &:hover {
        background-color: white;
        border: 1px solid black;
      }
      transition: 0.2s;
      cursor: pointer;
      border: 1px solid white;
      background-color: azure;
    }
    &-circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
      top: 5px;
    }
    &-circle2 {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
      top: 5px;
      right: 38px;
    }
  }
}
