@import 'src/styles/variables';
@import 'src/styles/mixins';

.projectBlockArchive {
  background-color: $text-secondary;
  display: flex;
  flex-direction: column;
  border: 1px solid $text-secondary;

  &:hover {
    border: 1px solid $text-primary;
  }

  .line {
    margin: 0 15px;
    border-bottom: 1px solid $text-primary;
  }

  .projectBlock__itemsArchive {
    padding: 0 20px 0 20px;
  }

  .projectBlock__missingProjects {
    padding: 15px 0;
    margin-left: -5px;
    @include text($text-primary, $font-family, 16px, 19px, 300, normal);
    text-align: start;
  }
}