@import 'src/styles/variables';
@import 'src/styles/mixins';

.UpdateFileScaleModal {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  padding: 18px;
  max-width: 325px !important;
  width: 325px;
  z-index: 10;
  min-width: 325px;
  max-height: 80vh;
  overflow-y: auto;
  .appInput__wrapper {
    input {
      width: 100%;
    }
  }
}
