@import 'src/styles/variables';
@import 'src/styles/mixins';

.userStatisticBlock {
  background-color: $text-secondary;
  display: flex;
  flex-direction: column;

  &__items {
    border-left: 1px solid $text-secondary;
    border-right: 1px solid $text-secondary;
  }

  &__missingProjects {
    padding: 15px 0;
    margin-left: 5px;
    @include text($text-primary, $font-family, 16px, 19px, 300, normal);
    text-align: start;
  }

  &__line {
    border-bottom: 1px solid $text-secondary;
  }
  &__button {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #303030;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 50%;
    &-item {
      height: 40px;
      margin: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    &-select {
      background-color: #303030;
      color: white;
    }

    &-wrapper {
      display: flex;
      border-bottom: 1px solid #efefef;
    }
  }
}
