@import 'src/styles/variables';
@import 'src/styles/mixins';

.notes__item-container {
  min-height: 38px;
  border-bottom: 1px solid $theme-color-darkGray;
  padding: 5px 0;
  word-break: break-word;

  &:last-child {
    border-bottom: none;
  }

  .notes__item {
    display: flex;
    align-items: flex-start;
    margin: 3px;


    &--text {
      @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
      width: 100%;
      padding-right: 0;
      height: 100%;
      padding-left: 10px;
      text-align: start;
      overflow-y: hidden;
    }

    &--actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      & img,
      svg {
        padding: 5px;
        cursor: pointer;
      }
    }
  }

  .notes__item--user {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 5px 0;
    cursor: default;
    @include text($theme-color-darkGray, $font-family, $f-size12, 14px, 300, normal);

    & .user__item {
      margin-right: 5px;
      margin-left: 0;

      & img {
        margin-right: 0;
      }
    }

    span {
      padding: 0 5px;
    }
  }
}
