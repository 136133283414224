.MoveTredModal {
  padding: 20px;
  width: 300px;
  &__name {
    margin-bottom: 5px;
  }
  &__item {
    margin-bottom: 5px;
  }
}
