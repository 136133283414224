.RenameTredFileModal {
  padding: 20px;
  box-sizing: border-box;
  min-width: 450px;
  &__title {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #303030;
    margin-bottom: 20px;
  }
  &__input {
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding-bottom: 2px;
    border-bottom: 1px solid #afafaf;
    &-svg {
      padding-bottom: 2px;
      cursor: default;
    }
    &-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #afafaf;
      margin-right: 5px;
      .appInput__field {
        font-weight: 500;
      }
    }
    .appInput__wrapper {
      margin: 0px;
      border-bottom: none;
      font-weight: 400;
      width: 100%;
    }
  }
}
