@import 'src/styles/variables';
@import 'src/styles/mixins';

.ActionTreadsModal {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  padding: 18px;
  max-width: 325px !important;
  width: 325px;
  z-index: 10;
  min-width: 325px;
  max-height: 80vh;
  overflow-y: auto;

  &__title {
    font-weight: 400;
    font-size: 30px;
    text-align: center;
    margin-bottom: 30px;
  }

  &__icons {
    margin-right: 10px;
  }
}
