@import 'src/styles/variables';
@import 'src/styles/mixins';

.expensesAccordionHeader {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid $theme-color-darkGray;

  &__column {
    border-left: 1px solid $theme-color-darkGray;
    text-align: left;
    @include text($text-primary, $font-family, $f-size20, 23px, normal, normal);
    padding: 0 10px;

    &.tags {
      width: 20%;
    }

    &.price {
      width: 15%;
    }

    &.task {
      width: 65%;
    }
  }
}

