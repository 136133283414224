.chronoTimeModal {
  width: 360px;
  box-sizing: border-box;
  padding: 20px;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  color: #303030;
  &__title {
    font-size: 14px;
    margin-bottom: 25px;
  }
  &__date {
    font-size: 20px;
  }
  &__time {
    font-size: 20px;
    font-weight: 600;
  }
  &__selector {
    display: flex;
    align-items: center;
    margin-top: 40px;
    .appSelect__wrapper {
      margin-bottom: 0px;
    }
    border-bottom: 2px solid #afafaf;
  }
}
