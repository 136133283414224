.ImageLoad {
  &__image {
    min-width: 320px;
    width: 320px;
    height: 320px;
  }
  &__errors {
    width: 100%;
    top: 100%;
    font-size: 12px;
    padding: 1px 0 0 4px;
    color: #dc3545;
  }
}
