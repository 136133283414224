@import 'src/styles/variables';
@import 'src/styles/mixins';

.tagsSelect {
  margin: 8px 0;

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tagsSelect__icon {
      padding-right: 2px;
      transform: rotate(45deg);
      &:hover {
        .crossColor {
          fill: $theme-color-green;
        }
      }
    }
  }

  &__selectedList {
    display: flex;
    flex-wrap: wrap;
    max-width: 345px;
    margin: 0 -5px;

    &-item {
      margin: 5px;
      cursor: default;
    }
  }

  &__optionsList {
    position: absolute;
    left: 18px;
    right: 18px;
    padding: 10px;
    background-color: $theme-color-gray;
    box-shadow: 1px 1px 5px rgba(48, 48, 48, 0.1);
    z-index: 10;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $theme-color-darkGray;
      margin-bottom: 10px;

      .tagsSelect__icon {
        transform: rotate(0);
      }
    }

    &-inner {
      max-height: 270px;
      overflow-y: auto;
    }
    &-items {
      margin-top: 5px;
      margin-left: 5px;
      overflow: hidden;
    }
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      padding-right: 5px;

      .tagsSelect__icon {
        transform: rotate(45deg);
        &:hover {
          .crossColor {
            fill: $theme-color-green;
          }
        }
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
