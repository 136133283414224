@import 'src/styles/variables';
@import 'src/styles/mixins';

.userStatistic {
  &__header {
    @include text($text-primary, $font-family, 26px, 121%, 400, normal);
    text-align: left;
    padding: 0px 0px 30px 30px;
  }
}
