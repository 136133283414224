.ChronometerReport {
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  &__pickers {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  &__buttonWrapper {
    box-sizing: border-box;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
}
.datePickerC {
  &__label {
    padding: 0px;
    height: 30px;
  }
  &__title {
    width: 150px;
    height: 30px;
    background: #f5f5f5;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    box-sizing: border-box;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }
  &__container {
    position: absolute;
    left: 0px;
  }
  .react-datepicker {
    background: #ffffff;
    border: none;
    margin-top: 10px;
    box-shadow: 0px 2px 5px rgba(48, 48, 48, 0.15);
  }
  .react-datepicker__input-container {
    display: none;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker-popper {
    transform: none !important;
    padding-top: 0px;
  }
  .react-datepicker__month-container {
    width: 366px;
  }
  .react-datepicker__header {
    background-color: white;
    border-bottom: none;
  }
  .react-datepicker__navigation-icon::before {
    border-width: 1px 1px 0 0;
    border-color: #222730;
  }
  .react-datepicker__current-month {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    color: #222730;
    &::first-letter {
      text-transform: uppercase;
    }
  }
}
