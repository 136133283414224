@import 'src/styles/variables';
@import 'src/styles/mixins';

.addFileTaskStatusModal {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  padding: 22px;
  max-width: 325px !important;
  width: 325px;
  z-index: 10;
  min-width: 325px;
  max-height: 80vh;
  overflow-y: auto;
  &__attach {
    position: absolute;
    left: -18px;
    transform: rotate(90deg);
    width: 12px;
  }
  &__message {
    margin-bottom: 20px;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    &-black {
      font-weight: 500;
    }
  }
  &__comment {
    margin-top: 20px;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    &-black {
      font-weight: 500;
    }
    &-underline {
      text-decoration: underline;
    }
  }
  &__title {
    margin-bottom: 10px;
  }
  .icons {
    margin-right: 5px;
  }
  .default__button {
    &:nth-of-type(2) {
      margin-top: 20px !important;
    }
  }
  .appInput__wrapper {
    input {
      width: 100%;
    }
  }
  .status {
    &__wrapper {
      box-sizing: content-box;
      display: flex;
      align-items: center;
      background-color: #f5f5f5;
      height: 25px;
      padding: 0px 10px;
    }
    &__element {
      width: 54px;
      height: 15px;
      margin-left: 10px;
      cursor: pointer;
      border-radius: 2px;
      border: 1px solid white;
    }
    &__select-border {
      border: 1px solid black;
    }
  }
  &__custom {
    &-wrapper {
      margin-bottom: 30px;
      .checkbox .labelTitle {
        font-size: 14px;
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 300;
      }
      .checkbox {
        margin-right: 0px;
      }
    }

    margin-top: 25px;
    margin-bottom: 5px;
    display: flex;
    text-align: left;
    color: #303030;
    cursor: pointer;
    font-family: Roboto Condensed, sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    color: #303030;
    font-style: normal;
    border-bottom: 1px solid #afafaf;
    .checkbox {
      input {
        width: 0%;
      }
      margin-right: 0px;
    }
    &-title {
      padding-right: 8px;
    }
    &-inner {
      display: flex;
      align-items: center;
      width: calc(100% - 20px);
      margin-right: 5px;
    }
    input {
      box-sizing: border-box;
      padding: 3px 0;
      outline: none;
      border: none;
      width: 100%;
      @include text($text-primary, $font-family, $f-size14, 16px, 300, normal);
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
