@import '../../styles/variables';
@import '../../styles/mixins';

.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  margin-left: -30px;
}
.my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
  max-width: 390px;
}

.my-masonry-grid_column > div {
  margin-bottom: 30px;
}

.empty-projects {
  &__text {
    padding: 100px 0;
    @include text($text-primary, $font-family, $f-size20, 23px, normal, normal);
  }
}