@import 'src/styles/variables';
@import 'src/styles/mixins';

.simple-form-modal {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  padding: 18px;
  max-width: 325px;
  width: 325px;
  z-index: 10;

  & button {
    margin-top: 10px;
  }

  &__title {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    margin-top: 10px;

    p {
      padding-right: 5px;
    }

    input {
      @include text($text-primary, 'Roboto Condensed', 14px, 16px, 300, normal);
      border: none;
      outline: none;
      width: 205px;
      padding: 0;
    }

    & .appInput__wrapper {
      margin-bottom: 20px;
    }

    .simple-form-modal__links {
      &-select {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .appSelect__wrapper {
          width: min-content;
          margin: 10px 0;
        }
      }

      &-title {
        @include text($text-primary, 'Roboto Condensed', 14px, 16px, 500, normal);
      }

      &_remove {
        margin-left: 20px;
      }

      .add-page-icon {
        padding-right: 10px;
      }

      &_add {
        cursor: pointer;
        margin-bottom: 10px;
        @include text($theme-color-darkGray, 'Roboto Condensed', 11px, 16px, 300, normal);

        &:hover {
          @include text($theme-color-green, 'Roboto Condensed', 11px, 16px, 300, normal);
        }
      }
    }
  }

  &__colors {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .polygon-arrow-color-item {
      max-width: none;
    }
  }

  &__buttons {
    display: flex;
  }
}