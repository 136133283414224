@import 'src/styles/variables';
@import 'src/styles/mixins';

.changeImageModal {
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
  color: #303030;
  font-style: normal;
  padding: 20px;
  width: 320px;
  z-index: 10;

  .deleteImageIcons {
    position: absolute;
    right: 5px;
    top: 15px;
  }

  .showImage {
    width: 330px;
    height: 200px;
    margin: 10px 0;
  }
}
