@import 'src/styles/variables';

.buttonLink {
  width: 50px;
  min-height: 50px;
  margin-top: 10px;
  background-color: $text-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: $theme-color-green;
  }
}
