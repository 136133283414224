.tredsModal {
  box-sizing: border-box;
  position: absolute;
  z-index: 999999;
  width: 360px;
  .TredFonsModal {
    padding: 0;
    width: auto;
  }
  background-color: white;
  padding: 20px;
  color: #303030;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  max-height: 515px;
  overflow-y: auto;
  .performers__list {
    max-height: 200px;
  }
  .noFormikSelect__menu-list {
    max-height: 240px;
  }
  .tagsSelect__header {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }
  &__images {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }
  &__crossing {
    transform: rotate(45deg);
    padding-right: 2px;
  }

  &__form {
    box-sizing: border-box;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .TredFonsModal {
      padding: 0;
      width: auto;
    }
    .tagsSelect__optionsList {
      position: relative;
      left: 0;
    }
    .tagsSelect__header {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .appInput__wrapper {
    margin-bottom: 15px;
  }
  &__one {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__icons {
    display: flex;
  }
  &__icon {
    border-radius: 2px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-select {
      background: #303030;
    }
  }
  &__title {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
}
