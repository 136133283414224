@import '../../../styles/variables';
@import '../../../styles/mixins';

.bellIconWithNumber {
  position: relative;
  margin-bottom: 20px;

  svg {
    cursor: pointer;
  }

  .bellIconWithNumber__number {
    min-width: 10px;
    min-height: 10px;
    background-color: red;
    @include text($text-secondary, $font-family, 12px, 12px, 500, normal);
    border-radius: 45%;
    border: 2px solid $text-primary;
    position: absolute;
    top: -8px;
    left: 8px;

    p {
      padding: 2px 6px;
      cursor: pointer;
    }
  }
}
