@import '../../styles/variables';

.new-task__responsible {
  & p {
    cursor: pointer;
    margin: 8px 0;
    padding-right: 8px;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  & img {
    padding: 5px;
    cursor: pointer;
  }

  .new-task__user {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .new-task__add {
      padding-right: 2px;
      transform: rotate(45deg);
      cursor: pointer;
    }

    .new-task__actions {
      display: flex;
      align-items: center;

      img {
        &:first-child {
          border-radius: 50%;
        }

        width: 12px;
        height: 12px;
      }

      svg {
        padding-left: 5px;
      }

      .new-task__line {
        height: 12px;
        border-left: 1px solid $theme-color-darkGray;
        margin: 10px 5px 10px 10px;
      }
    }
  }
}
