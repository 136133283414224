$text-primary: #303030;
$text-secondary: #ffffff;
$text-active: #149939;
$text-error: #dc3545;
$text-disabled: #4b4b4b;
$text-deadline: #e26363;
$section-primary: #d5edd8;
$section-secondary: #e4efff;

$theme-color-red: #e80025;
$theme-color-green: #149939;
$theme-color-yellow: #fbe000;
$theme-color-orange: #e5580a;
$theme-color-blue: #141a66;
$theme-color-lightBlue: #2a52be;
$theme-color-violet: #650b78;
$theme-color-gray: #efefef;
$theme-color-darkGray: #afafaf;
$theme-color-lightGray: #f5f5f5;
$theme-color-black: #303030;

$font-family: Roboto Condensed, sans-serif;
$f-size10: 10px;
$f-size12: 12px;
$f-size14: 14px;
$f-size16: 16px;
$f-size20: 20px;
$f-size26: 26px;

$polygon-table-col1: 50%;
$polygon-table-col2: 20%;
$polygon-table-col3: 30%;

$scale-table-col1: 35%;
$scale-table-col2: 10%;
$scale-table-col3: 55%;

$forum-page-heigth: 150vh;

// базовая высота одного из элементов блока
$TredItem__content-right: calc((100vh - 270px) / 3);
