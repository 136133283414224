.TredFonsModal {
  width: 360px;
  padding: 20px;
  box-sizing: border-box;

  &__colorPanel {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    padding: 5px;

    &-wraper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      box-sizing: border-box;
    }
  }

  &__tite1 {
    font-family: Roboto Condensed;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 30px;
  }
  &__tite2 {
    font-family: Roboto Condensed;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__list {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    &-wrapper {
      width: 32px;
      height: 32px;
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      &-outer {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Roboto Condensed;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-right: 15px;
        margin-bottom: 10px;
        position: relative;
        &:hover {
          .TredFonsModal__list-icon {
            opacity: 1;
            transition: 0.5s;
          }
        }
      }
    }

    &-inner {
      width: 24px;
      height: 24px;
    }
    &-text {
      width: 50px;
    }
    &-icon {
      position: absolute;
      top: 50%;
      left: 95%;
      transform: translate(50%, -50%);
      opacity: 0;
    }
  }
}
