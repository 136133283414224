@import 'src/styles/variables';
@import 'src/styles/mixins';

.project-block-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);

  margin: 0 10px 10px;

  &:after {
    content: '';
    border-bottom: 1px solid $theme-color-gray;
    width: 100%;
    display: block;
  }

  &:last-child {
    border-bottom: none;
  }

  &__title {
    display: flex;
    text-align: start;
    width: 100%;

    span {
      margin-right: 3px;
      padding-top: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: inherit;

      a {
        text-decoration: none;
        color: $text-primary;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          text-decoration: underline;
          overflow: hidden;
          text-overflow: ellipsis;

          &:active {
            color: $text-active;
            text-decoration: none;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  &__status {
    svg {
      margin: 20px 10px 0 0;
      cursor: default;
    }
  }

  &__user {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 0 10px 22px;
    }

    &-item {
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
      }
      p {
        margin: 8px 5px 7px 22px;
      }
    }
  }

  &__tags {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &-items {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 0 14px 22px;

      .tag__item {
        margin: 0 8px 8px 0;
        cursor: default;

        p {
          margin: 0;
        }
      }

      .tag__item:last-child {
        margin-right: 0;
      }
    }

    & .polygon-icon {
      margin-bottom: 14px;

      &:hover {
        path {
          fill: $text-primary;
        }
      }
    }
  }
}
