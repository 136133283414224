@import 'src/styles/variables';

.status__select {
  display: flex;
  margin-top: 30px;

  color: black;

  svg {
    margin-left: 5px;
    margin-bottom: 2px;
  }
}

.status__admin {
  display: flex;
  position: absolute;
  color: black;

  svg {
    margin-left: 5px;
    margin-bottom: 2px;
  }
  margin-top: -25px;
}

.status__select-window {
  margin-top: -25px;
  margin-left: -12px;
  position: absolute;
  z-index: 11;
  display: flex;
  flex-direction: column;
  text-align: start;
  height: 153px;
  width: 171px;
  background-color: #efefef;
  box-shadow: 1px 1px 5px rgba(48, 48, 48, 0.1);
  padding: 10px;
  font-size: $f-size16;

  .selectors {
    display: flex;
    padding-bottom: 11px;

    &:first-child {
      font-weight: 400;
    }

    &:first-child::before {
      content: '';
      width: 151px;
      height: 1px;
      background: #afafaf;
      position: absolute;
      margin-top: 20px;
    }

    svg {
      margin-left: 5px;
      margin-top: 6px;
    }
  }

  .status__select-switcher {
    .toggle__wrapper::after {
      content: '';
      width: 151px;
      height: 1px;
      background: #afafaf;
      position: absolute;
      margin-top: -10px;
      margin-left: 6px;
    }
    .toggle__wrapper {
      padding: 10px 0 0 0;
    }
    margin-left: -6px;
  }

  p {
    color: $text-primary;
  }
  p:hover {
    font-weight: 400;
  }
}

.selectors__icon-wrapper {
  padding-right: 5px;
  padding-top: 5px;

  .selectors__icon {
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }
}
