.EditTagsTredModal {
  width: 360px;
  padding: 20px;
  box-sizing: border-box;
  .tagsSelect__optionsList {
    position: relative;
    left: 0;
  }
  .tagsSelect__optionsList {
    background-color: white;
  }
  .tagsSelect__header {
    margin-bottom: 30px;
    .appInput__text-error {
      margin-top: 20px;
    }
  }
}
