@import 'src/styles/variables';
@import 'src/styles/mixins';

.appSelect {
  &__item {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__mark {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 50%;
  }

  &__wrapper {
    position: relative;
    margin-bottom: 30px;
    width: 100%;
    height: 30px;

    &-error {
      outline: 1px solid $text-error;
    }
  }

  &__text-error {
    position: absolute;
    top: 100%;
    font-size: $f-size12;
    padding: 4px 0 0;
    color: $text-error;
    pointer-events: none;
  }
}
