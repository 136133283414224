@import './styles/variables';

.App {
  text-align: center;
  padding: 0 10px 10px 10px;
  background-color: $theme-color-black;

  .block {
    display: flex;
    height: calc(100vh - 55px);
    position: relative;
  }
}

p {
  margin: 0;
}

.container {
  width: 100%;
  background-color: #efefef;
  padding: 20px 20px 4.5px 20px;
  overflow-x: hidden;

  &_relative {
    position: relative;
  }
}

.react-tooltip {
  z-index: 9999999;
}
