@import 'src/styles/variables';
@import 'src/styles/mixins';

.polygon-actions-wrapper {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: $theme-color-black;
  box-shadow: 0 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 3px;
  z-index: 10;

  svg {
    margin: 7px;
    padding: 7px;
  }

  .polygon-actions-wrapper-line {
    border-left: 1px solid $theme-color-darkGray;
    height: 20px;
    margin: 0 10px;
  }
}
