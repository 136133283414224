.TreadItem {
  &__link {
    display: flex;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    cursor: pointer;

    &-wrapper {
      background-color: #303030;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      padding: 2px;
      margin-bottom: 10px;
    }
    &-item {
      margin-right: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin-right: 5px;
      }
    }
  }
}
