@import 'src/styles/variables';
@import 'src/styles/mixins';

.UserGroupModal {
  width: 310px;
  padding: 20px;
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);

  .appInput__wrapper {
    margin-bottom: 10px;
  }
  &__groups {
    margin-bottom: 5px;
    max-height: 400px;
    overflow-y: auto;
  }
  &__group {
    margin-bottom: 15px;
    &-name {
      font-size: 18px;
      border-bottom: 1px solid rgb(59, 55, 55);
      display: flex;
      justify-content: space-between;
    }
    &-icons {
      display: flex;
      align-items: center;
    }
    &-users {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__user {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    &-avatar {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }
}
