.DecideOnTheFlyTask {
  padding: 20px;
  box-sizing: border-box;
  width: 360px;
  &__title {
    color: #303030;
    font-family: Roboto Condensed;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 30px;
  }
  &__title2 {
    padding: 20px;
    color: white;
    font-family: Roboto Condensed;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 30px;
    display: flex;
    align-items: center;
    background-color: #303030;
    border-radius: 4px 4px 0 0;
  }
  .appSelect__menu {
    z-index: 9999;
  }
  .appInput__wrapper {
    margin-bottom: 15px;
    input {
      width: 100%;
    }
  }

  &__selectLine {
    border-bottom: 1px solid #afafaf;
    display: flex;
    text-align: left;
    padding: 0 8px 0 0;
    font-family: Roboto Condensed, sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    color: #303030 !important;

    font-style: normal;
    .appSelect__wrapper {
      margin-bottom: 0px;
    }
    &-title {
      display: flex;
      align-items: center;
    }
  }
  &__color {
    margin-top: 20px;
    border-bottom: 1px solid #afafaf;
    padding-bottom: 5px;
    width: 100%;
    .pickerWrapper__color {
      border: 1px solid black;
    }
    .pickerWrapper__inner {
      width: 100px;
    }
  }
}
