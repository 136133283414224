@import 'src/styles/variables';
@import 'src/styles/mixins';

.TreadHistory {
  width: 100%;
  background-color: #efefef;
  overflow-x: auto;
  min-height: 88vh;
  display: flex;
  .pagination__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tagsFilter__title {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #303030;
  }
  &__header {
    justify-content: flex-start;
    .tagsFilter__inner {
      margin-left: 20px;
    }
    thead {
      tr {
        th {
          border-bottom: none;
        }
      }
    }
  }
  &__line {
    border-bottom: 1px solid #ece8e8;

    box-sizing: border-box;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
  }
  &__body {
    min-width: calc(1100px - 300px);
    width: 100%;
    background-color: $text-secondary;
    display: flex;
    flex-direction: column;
    min-height: 88vh;

    h2 {
      text-align: left;
      padding-left: 18px;
      @include text($text-primary, $font-family, 26px, 19px, 500, normal);
    }

    tbody > tr {
      position: relative;
      min-height: 45px;
      height: 100%;
      width: calc(100vw - 300px);
      td {
        text-align: left;
        padding-right: 10px;
        min-height: 35px;
        @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
        border-bottom: 1px solid #a29d9d;

        & > div {
          display: flex;
          align-items: center;
          width: 100%;
          min-width: 72px;
          margin: 5px 0;
          height: 100%;
          min-height: 27px;
          text-decoration: none;
          word-break: break-word;
          @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);

          & .project__status {
            padding-right: 8px;
          }
        }
      }

      & td:first-child {
        padding: 0 8px;
      }

      &:hover {
        background-color: $theme-color-lightGray;
        cursor: pointer;
      }

      &:last-child td {
        border-bottom: none;
      }
    }
  }
}
