@import 'src/styles/variables';
@import 'src/styles/mixins';

.addSubtask {
  display: flex;

  &:hover {
    cursor: pointer;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 20px;

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: left;
      @include text($text-primary, $font-family, $f-size20, 23px, normal, normal);
    }
  }
}
