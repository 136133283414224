@import 'src/styles/variables';
@import 'src/styles/mixins';

.files {
  width: 100%;
  max-height: 300px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & .mainContentHeaderItem {
    padding: 9px 0;
  }

  .main {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    width: 300px;
    height: 100%;
    background-color: $section-primary;
    @include text($text-primary, $font-family, $f-size12, 14px, 300, normal);

    .files__upload {
      background-color: $text-secondary;
      width: 280px;
      min-height: 31px;
      display: flex;
      align-items: center;
      margin-top: 10px;

      p {
        margin-left: 20px;
      }

      label {
        margin-left: 15px;
        cursor: pointer;
      }

      img {
        pointer-events: none;
      }

      span {
        margin-left: 10px;
        color: $theme-color-darkGray;
      }

      input[type='file'] {
        display: none;
      }
    }

    .line {
      width: 280px;
      border-bottom: 1px solid $theme-color-darkGray;
    }

    .files__list {
      margin-top: 15px;
      width: 280px;
      height: 115px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      .list__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        overflow-y: hidden;

        &:hover {
          svg:first-child {
            display: block;
          }
        }

        &--left {
          display: flex;
          align-items: center;

          a {
            max-width: 190px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: start;
            text-decoration: none;
            color: $text-primary;
          }
        }

        &--buttons {
          display: flex;
          align-items: center;
          svg {
            padding-left: 5px;
            &:first-child {
              display: none;
            }
          }
        }

        span {
          color: $theme-color-darkGray;
        }

        .item__line {
          height: 10px;
          border-left: 1px solid $theme-color-darkGray;
          margin: 0 5px;
        }
      }
    }
  }

  .files__line {
    width: 280px;
    padding-top: 10px;
    border-bottom: 1px solid #afafaf;
  }
}
