@import 'src/styles/variables';
@import 'src/styles/mixins';

.projectAccordion {

  &__header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: $theme-color-black;

    &:hover {
      cursor: pointer;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-content: center;
    transform: rotate(180deg);
    &.open {
      transform: rotate(0);
    }
  }

  &__title {
    margin: 0 0 0 20px;
    text-align: start;
    @include text($text-secondary, $font-family, $f-size16, 20px, 500, normal);
  }
  
  &__taskItem {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid $theme-color-darkGray;
  }

  &__taskItemCell {
    display: flex;
    padding-right: 5px;

    &.tags {
      width: 20%;
     
      & .tag__item {
        margin-left: 10px;
        cursor: default;
      }

      & .tag__text:hover {
        cursor: default;
      }
    }

    &.task {
      width: 45%;
      word-break: break-word;
      margin-right: 5px;

      a {
        @include text($text-primary, $font-family, $f-size16, 20px, 500, normal);
        text-decoration: none; 
        padding-left: 6px;
        text-align: left;
        word-break: break-all;

        &:hover {
          color:$text-active
        }
      }
    }

    &.comment {
      width: 35%;
      word-break: break-word;
    }

    &.price {
      width: 15%;
      word-break: break-word;
    }

    &.taskExpenses {
      width: 65%;
      word-break: break-word;
    }
  }
}