.scalePopup2 {
  padding: 10px 10px 25px 10px;
  background-color: #f5f5f5;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #303030;
  overflow-y: auto;
  &__A {
    font-size: 18px;
    color: white;
    margin-left: 3px;
  }
  &__comment {
    box-sizing: content-box;
    background: #e2e2e2;
    border-radius: 2px;
    padding: 4px 6px;
    margin-left: 3px;
    margin-top: 1px;
    display: flex;
    margin-bottom: 1px;
    border-left: 1px solid #e2e2e2;
    align-items: center;
    &-icons {
      margin-left: 10px;
    }
  }
  &__star {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 4px;
  }
  &__link {
    display: none;
  }
  &__plus {
    &-outer {
      align-items: center;
      justify-content: center;
      display: none;
    }
    &-inner {
      margin-left: 4px;
      width: 26px;
      height: 26px;
      border: 1px solid #303030;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
    }
  }
  &__line {
    display: flex;
    border-left: 1px solid #afafaf;

    &:hover {
      .scalePopup2__plus-outer {
        display: flex;
      }
    }
    &-inner {
      display: flex;
      align-items: center;
      border: 1px solid #f5f5f5;
      border-radius: 4px;
      margin-left: 4px;
      margin-top: 1px;
      &:hover {
        border: 1px solid #303030;
        .scalePopup2__plus-outer {
          display: flex;
        }
        .scalePopup2__link {
          display: block;
        }
        .scalePopup2__line-vrticalHover {
          border-right: 1px solid #303030 !important;
        }
      }
    }
    &-vrticalLine {
      border-right: 1px solid #f5f5f5;
    }
    &-select {
      background-color: #303030;
    }
  }
  &__emptyItem {
    border-left: 1px solid #afafaf;
    margin-left: 40px;
  }
  &__parent {
    margin-right: 10px;
    margin: 3px;
    padding: 4px 6px;
    background: #b3e4c1;
    border-radius: 2px;
    &-first {
      background: #e2e2e2;
    }
    display: flex;
    align-items: center;
  }
  &__name {
    background: #ffffff;
    border-radius: 2px;
    padding: 4px 6px;
    margin: 3px;
    display: flex;
    align-items: center;
  }
  &__dayList {
    position: relative;
    display: flex;
  }
  &__dayNumber {
    position: absolute;
    border-left: 1px solid #afafaf;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
  }
  &__status {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 5px;
  }
}
