.DecideOnTheFlyLogsTable {
  margin-top: 20px;
  &__head {
    display: flex;
    color: #303030;
    font-family: Roboto Condensed;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
    &-item {
      width: 25%;
    }
    margin-bottom: 20px;
  }
  &__body {
    display: flex;
    color: #303030;
    font-family: Roboto Condensed;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    border-bottom: 1px solid #afafaf;
    padding: 10px 0px;
    &-item {
      width: 25%;
      display: flex;
      align-items: center;
    }
    &-outer {
      max-height: 500px;
      overflow-y: auto;
    }
  }
}
