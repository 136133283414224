@import 'src/styles/variables';
@import 'src/styles/mixins';

@mixin statistic-page-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.statistic-page {
  width: 100%;
  height: 100%;
  color: #303030;
  font-family: 'Roboto Condensed', serif;
  &__name {
    text-align: initial;
    font-weight: 400;
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 20px;
  }
  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;

    &-instruments {
      display: flex;
      justify-content: space-between;
    }
  }
  &__instruments {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-right: 5px;
    &-inner-polygon {
      justify-content: normal;
    }
    &-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &-button {
      @include statistic-page-button;
      &-arrow {
        cursor: pointer;
        display: flex;
        align-items: center;
        svg {
          width: auto;
          margin-right: 10px;
        }
        span {
          width: 100%;
        }
      }
    }
    &-button_save {
      margin-right: 4%;
      @include statistic-page-button;
    }
    &-button_m0 {
      @include statistic-page-button;
      position: relative;

      .link-icon,
      .link-add-icon {
        &:hover {
          background-color: inherit;
        }
      }

      & .options-menu {
        .popover {
          left: 0;
          top: 30px;
          width: 150px;

          .options-menu__container {
            width: 100%;

            .popover:last-child {
              left: 195px;
              top: 30px;
            }
          }
        }
      }
    }
    &-button_mr35 {
      @include statistic-page-button;

      .remove-icon {
        &:hover {
          background-color: inherit;
        }
      }
    }
    &-button_tree {
      @include statistic-page-button;
      min-width: 200px;
      height: 30px;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      color: #ffff;
      padding: 4px 35px;
      margin: 5px 45px 5px 0;
      background-color: #303030;
    }
    &-arrow {
      transform: rotate(90deg);
      margin-right: 25px;
      @include statistic-page-button;
    }
    &_father {
      font-size: 18px;
      justify-content: normal;
    }
    &_add {
      background-color: #149939;
      min-width: 120px;
      padding: 4px 35px;
      height: 30px;
      color: white;
    }
    &-icons {
      margin-right: 10px;
      transform: rotate(135deg);
    }
    &-group {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 4%;
    }
    &-cancel {
      margin-right: 20px;
      cursor: pointer;
    }
    &-forward {
      cursor: pointer;
      transform: scaleX(-1);
    }
  }
  &__polygon {
    margin-top: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__container {
    overflow-y: scroll;
    overflow-x: scroll;
    background-color: white;
    width: 100%;
    margin-right: 35px;
  }
  .vertical-line {
    height: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 10px 4%;
  }
}
