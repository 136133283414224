@import 'src/styles/variables';
@import 'src/styles/mixins';

.user__item {
  display: flex;
  align-items: center;
  height: 13px;

  &--image {
    position: relative;
    display: flex;
    align-items: center;
    height: auto;
    border-radius: 50%;
  }

  &--avatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
  }

  &--status {
    position: absolute;
    bottom: -1px;
    right: 4px;
    width: 6px;
    height: 6px;
    border: 1px solid $text-secondary;
    border-radius: 50%;
    z-index: 10;
  }
}
