@import 'src/styles/variables';

.edit-color {
  &:hover {
    fill: #149939;
    stroke: #149939;
  }
}

.edit-color_with-background {
  &:hover {
    background-color: $theme-color-darkGray;
    border-left: none;
  }
}
