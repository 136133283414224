@import 'src/styles/variables';
@import 'src/styles/mixins';

.customDatePicker {
  &__wrap {
    display: flex;
    justify-content: space-between;
  }

  &__labelText {
    @include text($text-primary, $font-family, $f-size14, 17px, 300, normal);
    word-break: normal;
    margin: 8px 0;
  }
  &__setMonth {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #f8f2f2;
    padding: 3px;
  }

  &__inputWrap {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__input {
    position: relative;

    .react-datepicker__portal {
      width: 100%;
      height: 100%;
      right: auto;
      left: 0;
      bottom: 10px;
      top: auto;
    }
  }

  &__labelIcon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
    display: flex;

    &:hover {
      cursor: pointer;
    }
  }

  &__closeIcon {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }

  .react-datepicker {
    font-family: $font-family;
    border-radius: 0;
    border: none;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25), 0 1px 5px rgba(48, 48, 48, 0.1);
  }

  .react-datepicker__input-container {
    input {
      max-width: 88px;
      position: relative;
      @include text($text-primary, $font-family, $f-size14, 17px, 300, normal);
      outline: none;
      border: none;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation {
    margin-top: 22px;
    width: 16px;
    height: 16px;

    &--previous {
      margin-left: 56px;
    }

    &--next {
      margin-right: 56px;
    }

    &-icon {
      &::before {
        border-color: $theme-color-black;
        border-width: 1px 1px 0 0;
      }
    }
  }

  .react-datepicker__header {
    margin: 20px 20px 0;
    padding: 0 0 15px 0;
    background-color: #ffffff;
    border-bottom: 1px solid $theme-color-darkGray;
  }

  .react-datepicker__current-month {
    @include text($theme-color-black, $font-family, $f-size20, 24px, 500, normal);
    text-transform: capitalize;
  }

  .react-datepicker__month {
    margin: 0 10px 10px;
  }

  .react-datepicker__day-names {
    margin-top: 20px;
  }

  .react-datepicker__day-name {
    width: 30px;
    height: 30px;
    margin: 0 20px 0 0;
    @include text($theme-color-darkGray, $font-family, $f-size16, 30px, 500, normal);
    text-transform: capitalize;

    &:last-child {
      margin: 0;
    }
  }

  .react-datepicker__day {
    width: 30px;
    height: 30px;
    margin: 10px;
    @include text($theme-color-black, $font-family, $f-size16, 30px, 400, normal);
    border-radius: 2px;

    &--outside-month {
      @include text($theme-color-darkGray, $font-family, $f-size16, 30px, 300, normal);
    }

    &--selected,
    &--keyboard-selected {
      color: #ffffff;
      background-color: $theme-color-black;
    }
  }
}
