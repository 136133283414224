@import 'src/styles/variables';
@import 'src/styles/mixins';

.changeCommentModal {
  box-sizing: border-box;
  width: 360px;
  padding: 20px;

  &__inputWrap {
    margin-bottom: -10px;
  }

  input {
    width: 100%;
  }
}
