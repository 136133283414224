@import 'src/styles/variables';
@import 'src/styles/mixins';

.start-button__option {
  display: flex;
  align-items: center;
  background-color: $text-secondary;
  color: $text-primary;
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    background-color: $text-primary;

    svg {
      path {
        stroke: $text-secondary;
      }
    }

    span {
      color: $text-secondary;
      padding-left: 10px;
    }
  }

  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
  }

  & span {
    @include text($text-primary, $font-family, $f-size12, 14px, 300, normal);

    padding-left: 10px;
  }
}

.start-button__option_active {
  background-color: $text-primary;

  span {
    color: $text-secondary;
    padding-left: 10px;
  }

  svg {
    path {
      stroke: $text-secondary;
    }
  }
}
