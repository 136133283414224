@import 'src/styles/variables';
@import 'src/styles/mixins';

.TredSelectUser {
  width: 350px;
  display: flex;
  position: relative;
  &__history {
    margin-right: 20px;
    max-width: 20px;
    max-height: 20px;
    border-radius: 50%;
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    &.active {
      background-color: #ffffff;
    }
  }
  &__line {
    margin-right: 20px;
    width: 1px;
    height: 20px;
    background-color: #afafaf;
  }
  &__input {
    box-sizing: border-box;
    width: 185px;
    padding: 5px 5px 5px 40px;
    background-color: #ffffff;
    outline: none;
    border: none;
    border: 1px solid #efefef;
    border-radius: 2px;
    color: #303030;
  }
  &__item {
    display: flex;
    align-items: center;
    padding: 5px 5px 5px 5px;
    cursor: pointer;
    &:hover {
      background: #efefef;
    }
  }
  &__select {
    display: flex;
    align-items: center;
  }
  &__list {
    position: absolute;
    background-color: white;
    min-width: 185px;
    top: 40px;
    z-index: 99999;
    color: black;
    text-align: start;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #303030;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 150px);
    box-sizing: border-box;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }
  &__avatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
  }
  &__icon {
    position: absolute;
    top: 4px;
    left: 8px;
    padding: 0;
    border: none;
    background-color: transparent;

    // for safari selector
    @media not all and (min-resolution: 0.001dpcm) {
      top: 8px;
    }

    &:hover {
      cursor: pointer;
      fill: $theme-color-green;
    }
  }
}
