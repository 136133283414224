.polygon-arrow-color-item {
  max-width: 250px;
  display: flex;
  flex-direction: row;
  overflow-x: auto;

  &__item {
    margin: 5px;
    cursor: pointer;
    min-width: 10px;
    height: 10px;
  }
}