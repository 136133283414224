@import 'src/styles/variables';
@import 'src/styles/mixins';

.appTextarea {
  &__wrap {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__label {
    margin-bottom: 10px;
    font-weight: 500;
  }

  &__textarea {
    min-height: 160px;
    max-width: 320px;
    min-width: 320px;
    padding: 0;
    border: none;
    border-bottom: 1px solid $theme-color-darkGray;

    &::placeholder {
      color: $theme-color-darkGray;
    }

    &:focus {
      outline: none;
    }
  }

  &__error {
    width: 100%;
    position: absolute;
    top: 100%;
    font-size: $f-size12;
    padding: 1px 0 0 4px;
    color: $text-error;
    pointer-events: none;
  }
}
