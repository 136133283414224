@import 'src/styles/variables';
@import 'src/styles/mixins';

.deadline {
  display: flex;
  align-items: center;

  &__icon {
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }

  &__text {
    @include text($theme-color-darkGray, $font-family, $f-size14, 19px, 400, normal);
  }
}