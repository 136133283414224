.chronometerPage {
  background-color: #f5f5f5;
  width: 100%;
  font-family: 'Roboto Condensed';
  overflow-y: auto;
  height: calc(100vh - 55px);

  padding: 20px;
  @media (max-width: 1370px) {
    padding: 10px;
  }
  &__buttonWrapper {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  &__alert {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    &-icons {
      width: 20px;
      height: 20px;
    }
    &-title {
      display: flex;
      align-items: center;
    }
    &-text {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #303030;
      width: 320px;
      text-align: start;
      margin-left: 20px;
    }
  }

  &__content {
    box-sizing: border-box;
    width: 100%;

    background-color: white;
    padding: 20px;
    @media (max-width: 1370px) {
      padding: 20px 10px;
    }
    min-height: calc(100vh - 90px);
  }
  &__template {
    width: 380px;
    display: flex;
    justify-content: flex-end;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #303030;
    white-space: nowrap;
  }
  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 32px;
    color: #303030;
    text-align: start;
    padding-bottom: 20px;
    border-bottom: 1px solid #efefef;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__section {
    width: 90%;
    box-sizing: border-box;
    display: flex;

    &:nth-of-type(1) {
      border-right: 1px solid #efefef;
      width: 110%;
    }
    &-wrapper {
      display: flex;
      box-sizing: border-box;
      height: calc(100% - 40px);
    }
  }
  &__subsection {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:nth-of-type(1) {
      border-right: 1px solid #efefef;
      height: 100%;
      width: 55%;
      min-width: 400px;
      padding: 20px 20px 20px 0px;
    }
    &:nth-of-type(2) {
      border-right: 1px solid #efefef;
      height: 100%;
      width: 45%;
      padding: 20px;
      @media (max-width: 1370px) {
        box-sizing: border-box;
        min-width: 288px;
      }
    }
  }
}
