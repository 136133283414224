@import 'src/styles/variables';
@import 'src/styles/mixins';

.menu__chats {
  display: flex;
  flex-direction: column;
  height: 52%;
  width: 100%;

  .chat__items {
    background: $text-secondary;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    padding-right: 20px;
    @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
  }

  .chat__search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 40px;
    background: $theme-color-lightGray;
    @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);

    & img,
    span {
      padding-left: 15px;
    }

    input {
      padding: 0 10px;
      width: 100%;
      border: none;
      background-color: $theme-color-lightGray;
      outline: none;
      text-align: start;
    }
  }
}
