.changeColNameModal {
  padding: 20px;
  width: 400px;
  &__inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__title {
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    margin-bottom: 15px;
  }
  .appInput__wrapper {
    width: 100%;
  }
}
