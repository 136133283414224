@import 'src/styles/variables';
@import 'src/styles/mixins';

.CircleCreateModal {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  padding: 18px;
  max-width: 650px;
  width: 500px;
  z-index: 10;
  &__outer {
    padding: 18px;
    display: flex;
  }
  input {
    width: 100%;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
  }
  &__colorPanel {
    position: absolute;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    padding: 5px;
  }
  &__selector2 {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 5px;
    margin-bottom: 20px;
    .appSelect__wrapper {
      margin-bottom: 0px;
    }
    border-bottom: 1px solid #afafaf;
  }
  &__textarea {
    width: 100%;
    min-height: 150px;
    padding: 8px 12px;
    box-sizing: border-box;
    border: 1px solid #868686;
    border-radius: 2px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
    outline: none;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #303030;
  }
}
