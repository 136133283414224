@import 'src/styles/variables';
@import 'src/styles/mixins';

body {
  margin: 0;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: $text-primary;
  }

  ::-webkit-scrollbar-thumb {
    padding: 1px;
    width: 6px;
    background-color: $text-secondary;
    border-radius: 3px;
    border: 2px solid $text-primary;
  }

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@-moz-document url-prefix() {
  body {
    scrollbar-color: #030003 white;
    scrollbar-width: thin;
  }
}
.d-flex {
  display: flex;
}
.pl {
  padding-left: 2px;
}
.pr {
  padding-right: 2px;
}
.ml2 {
  margin-left: 2px;
}
.mr2 {
  margin-right: 2px;
}
.mt2 {
  margin-top: 2px;
}
.mb2 {
  margin-bottom: 2px;
}
.mbt {
  margin-top: 2px;
}
.mr5 {
  margin-right: 5px;
}
.ml5 {
  margin-left: 5px;
}
.mb5 {
  margin-bottom: 5px;
}
.mt5 {
  margin-top: 5px;
}
.pl5 {
  padding-left: 5px;
}
.pr5 {
  padding-right: 5px;
}
.pl10 {
  padding-left: 10px;
}
.pr10 {
  padding-right: 10px;
}
.mr10 {
  margin-right: 10px;
}
.ml10 {
  margin-left: 10px;
}
.mt10 {
  margin-top: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.ml15 {
  margin-left: 15px;
}
.mr15 {
  margin-right: 15px;
}
.mb15 {
  margin-bottom: 15px;
}
.mt15 {
  margin-top: 15px;
}
.ml20 {
  margin-left: 20px;
}
.mr20 {
  margin-right: 20px;
}

.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.ml25 {
  margin-left: 25px;
}
.ml30 {
  margin-left: 30px;
}
.mt30 {
  margin-top: 30px;
}
.pr30 {
  padding-right: 30px;
}
.pl30 {
  padding-left: 30px;
}

.mr40 {
  margin-right: 40px;
}
.mr50 {
  margin-right: 50px;
}

.ml50 {
  margin-left: 50px;
}

.cssSlic {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
