@import 'src/styles/variables';
@import 'src/styles/mixins';

.defaultModal-overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
