@import 'src/styles/variables';
@import 'src/styles/mixins';

.storage {
  width: 100%;
  background-color: #efefef;
  overflow-x: auto;
  min-height: 88vh;
  display: flex;
  .serch-input {
    width: 520px;

    ::placeholder {
      color: #afafaf;
    }
  }
  &__line-horizontal {
    height: 1px;
    width: calc(100% - 40px);
    background-color: #efefef;
    margin: 20px 20px;
  }
  &__body {
    min-width: calc(1100px - 300px);
    width: 100%;
    background-color: $text-secondary;
    display: flex;
    flex-direction: column;
    min-height: 88vh;
    overflow: hidden;
  }

  &__title {
    text-align: left;
    padding-left: 18px;
    @include text($text-primary, $font-family, 26px, 19px, 500, normal);
  }
  &__title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  &__input {
    box-sizing: border-box;
    width: 100%;
    padding: 10px 20px 11px 42px;
    background-color: #ffffff;
    outline: none;
    border: 1px solid #efefef;
  }

  &__table {
    padding: 0 20px;
  }

  &__row {
    display: flex;

    border-bottom: 1px solid $theme-color-darkGray;
  }

  &__column {
    border-left: 1px solid $theme-color-darkGray;
    text-align: left;
    @include text($text-primary, $font-family, $f-size20, 23px, normal, normal);
    padding: 0 10px;

    &.storage__project {
      width: 100%;
    }
  }
  &__pagination {
    display: flex;
    &-button {
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
    }
    &-select {
      color: black;
      background-color: #ffffff;
    }
  }
  &__itemWrapper {
    &-outer {
      display: flex;
    }
    &-inner {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
