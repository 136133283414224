.MoveScaleTaskModal {
  padding: 20px;
  width: 300px;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  &__name {
    margin-bottom: 20px;
  }
  &__item {
    margin-bottom: 5px;
  }
}
