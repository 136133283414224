@import 'src/styles/variables';
@import 'src/styles/mixins';

.profile__modal-wrapper {
  width: 220px;
  background-color: $theme-color-black;

  .profile__modal {
    padding-top: 10px;
    width: 181px;
    display: flex;
    min-height: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    .user__note {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 221px;
      height: 30px;
      background-color: $theme-color-lightGray;

      span {
        width: 100%;
        text-align: center;
        @include text($text-primary, $font-family, 13px, 16px, 300, normal);
      }

      input {
        border: none;
        background-color: $theme-color-lightGray;
        outline: none;
        text-align: center;
      }
    }

    .user__actions {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 15px 0 5px 0;

      span {
        @include text($text-secondary, $font-family, $f-size14, 16px, 300, normal);
        padding: 5px 0;
      }

      & span {
        cursor: pointer;
      }

      & span:hover {
        color: $theme-color-darkGray;
      }

      &--remove:hover {
        color: red!important;
      }
    }

    .user__status {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 100%;
      padding-top: 15px;

      input {
        width: 185px;
      }

      button {
        margin-bottom: 15px;

        &:first-child {
          margin-bottom: 1px;
        }
      }
    }
  }

  .user__head {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
    position: relative;

    .avatar__image-upload {
      border-radius: 50%;
      input {
        display: none;
        position: absolute;
        visibility: hidden;
        left: 0;
        width: 60px;
        height: 60px;
        z-index: 1000;
      }
    }
    .avatar__upload {
      position: absolute;
      left: 0;
      top: 0;
      display: none;
      opacity: 0.8;
      pointer-events: all;
      border-radius: 50%;

      z-index: 3;
    }

    .user__head-avatar:hover .avatar__upload {
      display: block;
    }

    .user__head-avatar {
      border-radius: 50%;
      width: 60px;
      height: 60px;
    }

    & img {
      cursor: pointer;
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    & span {
      @include text($text-secondary, $font-family, $f-size20, 23px, 300, normal);
      cursor: default;
    }

    .user__info {
      padding-left: 17px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      word-break: break-word;

      input:focus {
        cursor: auto;
      }

      .user__info-name {
        padding-left: 0;
        padding-right: 0;
        @include text($text-secondary, $font-family, $f-size14, 16.41px, 300, normal);
        cursor: pointer;
        background: transparent;
        border: none;
        outline: none;
        max-width: 100px;
      }
    }
  }
}
