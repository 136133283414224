@import 'src/styles/variables';
@import 'src/styles/mixins';

.file-list {
  width: 100%;
  background-color: #efefef;
  overflow-x: auto;
  min-height: 88vh;
  overflow-y: hidden;
  display: flex;

  &__title {
    @include text($text-primary, $font-family, 18px, 12px, 300, normal);

    &-col {
      width: 35%;
    }
  }

  .horizontal__line {
    width: calc(100% - 40px);
    border-bottom: 1px solid $theme-color-gray;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
  &__body {
    min-width: calc(1100px - 300px);
    width: 100%;
    background-color: $text-secondary;
    display: flex;
    flex-direction: column;
    min-height: 88vh;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    &-name {
      background: #303030;
      color: white;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      padding: 5px;
      cursor: pointer;
    }
    &-wrapper {
      display: flex;
      align-items: flex-end;
      @media (max-width: 1400px) {
        button {
          width: 100px !important;
        }
      }
    }
    &-Checkbox {
      display: flex;
      align-items: flex-end;
    }

    &-filename {
      min-width: 145px;
      padding-left: 20px;
      margin-top: 20px;
      margin-bottom: 8px;
      text-align: left;
      @include text($text-primary, $font-family, 26px, 19px, 500, normal);
      cursor: pointer;
    }
  }

  .task {
    margin-top: 20px;
    margin-right: 20px;
    @include text($text-primary, $font-family, 14px, 12px, 300, normal);

    &__count {
      display: flex;
      height: 45px;
      &-element {
        width: 80px;
        padding: 10px 5px;
      }
      &-number {
        margin-top: 5px;
        @include text($text-primary, $font-family, 18px, 16px, 500, normal);
      }
      &-all {
        background: #f5f5f5;
      }
      &-work {
        background: #fbfbfb;
      }
      &-problem {
        text-align: end;
      }
    }
  }
  .line {
    &__wrapper {
      width: 100%;
      display: flex;
      margin-top: 10px;
    }
    &__problem {
      background-color: #d8839a;
      height: 5px;
      cursor: pointer;
    }
    &__success {
      background-color: #b3e4c1;
      height: 5px;
      cursor: pointer;
    }
    &__process {
      height: 5px;
      background-color: #faf2a7;
      cursor: pointer;
    }
    &__empty {
      background-color: #f5f5f5;
      height: 5px;
      cursor: pointer;
    }
  }
}
