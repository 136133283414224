@import 'src/styles/variables';
@import 'src/styles/mixins';
.TredFileList__table {
  .CellTwoColFile {
    * {
      box-sizing: border-box;
    }
    &__status {
      width: 12px;
      height: 12px;
      margin-right: 5px;
      border-radius: 50%;
      &-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &__editIcons {
      display: none;
      margin-right: 20px;
    }
    &__child {
      display: none;
    }

    &__state2 {
      display: flex;
      box-sizing: inherit;
      padding-top: 8px;
      padding-bottom: 8px;
      &-move {
        margin-right: 30px;
      }

      &-black {
        padding: 8px;
        background-color: #303030;
        color: white !important;
        border-radius: 8px;
        .CellTwoColFile__state2-button,
        .CellTwoColFile__state2-element,
        .CellTwoColFile__state2-select {
          border: none;
          &:hover {
            color: black;
            background-color: #d5edd8;
          }
        }
        .CellTwoColFile__state2-select {
          color: black;
        }
      }

      flex-direction: column;
      overflow-y: auto;
      height: 100%;
      width: 100%;
      cursor: pointer;
      .edit-color {
        display: none;
      }
      &-element {
        &:hover {
          background-color: #d5edd8;
          border-radius: 8px;
        }
      }
      &-button {
        &:hover {
          border: 1px solid #d5edd8;
          border-radius: 8px;
        }
      }
      &-empty {
        height: 36px;
        width: 100%;
      }

      &-element,
      &-button {
        border: 1px solid white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        min-height: 36px;
        height: 36px;
        width: 100%;
        margin-bottom: 5px;
        &:hover {
          .edit-color {
            display: block;
            margin-right: 5px;
          }
          .CellTwoColFile__child {
            display: flex;
          }
          .CellTwoColFile__editIcons {
            display: block;
          }
        }
      }
      &-select {
        background-color: #d5edd8;
        border-radius: 8px;
      }
      &-icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
