@import '../../styles/variables';
@import '../../styles/mixins';

.newTask__main {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  padding: 18px;
  max-width: 400px;
  width: 400px;

  & button {
    margin-top: 10px;
  }

  .newTask__data {
    display: flex;
    justify-content: space-between;

    .project__name {
      margin-bottom: 10px;
    }
  }

  .newTask__title {
    width: 100%;
    display: flex;

    p {
      padding-right: 5px;
    }

    input {
      @include text($text-primary, 'Roboto Condensed', 14px, 16px, 300, normal);
      border: none;
      outline: none;
      width: 205px;
      padding: 0;
    }

    & .appInput__wrapper {
      margin-bottom: 10px;
      width: 100%;
    }
  }
}

.default__button {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
}
