@import 'src/styles/variables';
@import 'src/styles/mixins';

.editText__textarea {
  box-sizing: border-box;
  border: none;
  padding: 0 20px 0 10px;
  margin: 0;
  outline: none;
  resize: none;
  font-family: 'Roboto Condensed', serif;
  line-height: 1;
}
