@import 'src/styles/variables';
@import 'src/styles/mixins';

.reset__password-wrapper {
  box-sizing: border-box;
  width: 300px;
  min-height: 340px;
  padding: 50px 20px;

  .reset__password-title {
    margin-bottom: 40px;
    cursor: default;
    @include text($text-primary, $font-family, $f-size20, 24px, normal, normal);
  }

  .reset__password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .reset__password-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .server__form-error {
    padding-bottom: 20px;
    @include text($text-error, $font-family, $f-size16, 18px, 300, normal);
  }
}
