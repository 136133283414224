@import 'src/styles/variables';
@import 'src/styles/mixins';

.comment {
  display: flex;
  align-items: center;

  &__text,
  &__placeholder {
  padding-left: 10px;
  text-align: left;
  }

  &__placeholder {
  color: $theme-color-darkGray
  }
}
