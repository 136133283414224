@import 'src/styles/variables';
@import 'src/styles/mixins';

nav {
  display: flex;
  justify-content: center;
  align-items: center;

  border-left: 1px solid $text-secondary;
  border-right: 1px solid $text-secondary;
  border-bottom: 1px solid $text-secondary;

  .pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px 10px 10px;

    svg {
      &:hover {
        background-color: $text-primary;
        color: $text-secondary;
        cursor: pointer;

        path {
          fill: white;
        }
      }
    }

    &__prev {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px;

      &.pagination__prev_disabled {
        &:hover {
          background-color: $text-disabled;
          color: $text-secondary;
          cursor: pointer;
        }
      }
    }

    &__next {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px;
      transform: rotate(180deg);

      &.pagination__next_disabled {
        &:hover {
          background-color: $text-disabled;
          color: $text-secondary;
          cursor: pointer;
        }
      }
    }

    &__item {
      width: 20px;
      height: 20px;
      color: $text-primary;

      @include text($text-primary, $font-family, 16px, 21px, 300, normal);
      padding: 6px;

      &_active {
        background-color: $text-primary;
        color: $text-secondary;
      }

      &:hover {
        background-color: $text-primary;
        color: $text-secondary;
        cursor: pointer;
      }

      &_hide {
        pointer-events: none;
        width: 20px;
        height: 20px;
        color: $text-primary;

        @include text($text-primary, $font-family, 16px, 21px, 300, normal);
        padding: 6px;
      }
    }
  }
}
