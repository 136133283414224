@import 'src/styles/variables';
@import 'src/styles/mixins';

.confirmModal {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  padding: 18px;
  width: 350px !important;

  & button {
    margin-top: 10px;
  }

  &--buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
    margin-top: 10px;

    p {
      padding-right: 5px;
    }
  }
}
