@import 'src/styles/variables';
@import 'src/styles/mixins';

.chat {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  width: 100%;
  height: 100%;

  .chat__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .right {
      & .write {
        @include text($theme-color-darkGray, $font-family, $f-size10, 12px, 300, normal);
      }
    }

    .chat__participants {
      display: flex;

      .participants__user,
      .participants__companion {
        cursor: pointer;
        @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
      }
      img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        padding: 0 5px;
      }
    }
  }

  .chat__content {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    max-height: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px;
    margin-top: 10px;
    border: 2px solid $theme-color-darkGray;
    position: relative;

    .chat__messages {
      height: 100%;
      overflow-y: auto;
      padding: 0 10px 10px 10px;
      background-color: $text-secondary;

      .chat__fetch-button {
        margin-top: 20px;
        @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
      }

      > button {
        position: absolute;
        bottom: 60px;
        top: auto;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
      }

      & .chat__message:last-child {
        border: none;
        padding-bottom: 0;
      }
    }

    .chat__absence {
      margin-top: 20px;
      @include text($text-primary, $font-family, $f-size16, 19px, 300, normal);
    }
  }
}
