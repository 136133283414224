@import 'src/styles/variables';
@import 'src/styles/mixins';


.default__button {
  position: relative;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  margin: 1px;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s; /* Safari */
  @include text($text-primary, $font-family, $f-size14, 16px, 300, normal);

  &:hover {
    color: $text-secondary;
    transition-duration: 0.1s;
    background-color: $theme-color-green !important;
  }

  &:active {
    top: 1px;
  }

  &:disabled {
    background-color: $theme-color-darkGray;
    transition: none;
    cursor: default;
    @include text($text-primary, $font-family, $f-size14, 16px, 300, normal);
  }
}

.default__button-bordered {
  position: relative;
  border: 1px solid #efefef;
  align-items: center;
  justify-content: center;
  user-select: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s; /* Safari */
  @include text($text-primary, $font-family, $f-size14, 16px, 300, normal);

  &:hover {
    color: $text-secondary;
    transition-duration: 0.1s;
    border: 1px solid $theme-color-green;
    @include text($text-active, $font-family, $f-size14, 16px, 300, normal);
  }

  &:active {
    top: 1px;
  }

  &:disabled {
    background-color: $theme-color-darkGray;
    transition: none;
    cursor: default;
    @include text($text-primary, $font-family, $f-size14, 16px, 300, normal);
  }
}

.cancel__button {
  position: relative;
  border: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  margin: 1px;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s; /* Safari */
  @include text($text-primary, $font-family, $f-size14, 16px, 300, normal);

  &:hover {
    color: $text-secondary;
    background-color: $theme-color-red !important;
    transition-duration: 0.1s;
    border: 1px solid $theme-color-red;
    @include text($text-secondary, $font-family, $f-size14, 16px, 300, normal);
  }

  &:active {
    top: 1px;
  }

  &:disabled {
    background-color: $theme-color-darkGray;
    transition: none;
    cursor: default;
    @include text($text-primary, $font-family, $f-size14, 16px, 300, normal);
  }
}
