@import 'src/styles/variables';
@import 'src/styles/mixins';

.userSettings {
  color: #303030;
  font-family: Roboto Condensed;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  &__password {
    .appInput__wrapper {
      width: 100%;
    }
    .appInput__field {
      margin-left: auto;
      width: 135px;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 230px;
    margin-bottom: 15px;
    height: 250px;
    justify-content: space-between;
    border: 1px solid #303030;
    padding: 10px;
    background-color: #fbfbfb;
    margin: 10px 20px;
    box-sizing: border-box;
  }
  &__title {
    font-size: 18px;
    margin-bottom: 15px;
  }
  &__tag {
    &-item {
      margin-right: 8px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-wrapper {
      width: 100%;
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding-bottom: 50px;
    }
    &-empty {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #afafaf;
    }
    &-more {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #303030;
    }
  }

  input {
    box-sizing: border-box;
    padding: 3px 0;
    outline: none;
    border: none;
    width: 100%;
    @include text($text-primary, $font-family, $f-size14, 16px, 300, normal);
    border: 1px solid #efefef;
  }
  .appInput__wrapper {
    border: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}
