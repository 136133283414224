.checkbox {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  cursor: pointer;
  &__disable {
    .labelTitle {
      color: #b3b0b0 !important;
    }
    .checkmark {
      border: 1px solid #b3b0b0 !important;
    }
  }
  .labelTitle {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #303030;
    padding-top: 2px;
    margin-right: 5px;
  }

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    border: 1px solid #333;
    vertical-align: middle;
    border-radius: 3px;

    &::after {
      content: '';
      position: absolute;
      display: none;
    }

    input[type='checkbox']:checked + &::after {
      display: block;
      left: 5px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid #333;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}
