@import 'src/styles/variables';
@import 'src/styles/mixins';

.chooseUser__main {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  padding: 18px;
  height: 40vh;
  overflow-y: auto;

  .empty__users-list {
    margin-top: 20px;
    text-align: center;
  }

  .chooseUser__title {
    width: 100%;
    border-bottom: 1px solid $theme-color-darkGray;
    display: flex;
    margin-top: 15px;

    input {
      @include text($text-primary, 'Roboto Condensed', 14px, 16px, 300, normal);
      border: none;
      outline: none;
      width: 250px;
      padding: 0;
    }
  }

  .chooseUser__result {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: start;
    padding: 8px 0 0 0;

    .chooseUser__info {
      width: content-box;
      display: flex;

      img {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 5px;
      }
    }

    .list__item-add {
      transform: rotate(45deg);
      cursor: pointer;

      svg {
        &:hover {
          .crossColor {
            fill: #149939;
          }
        }
      }
    }
  }
}
