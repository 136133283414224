.chronometerWeeks {
  width: 100%;
  &__selector-outer {
    position: relative;
    height: 30px;
  }
  &__selector-inner {
    position: absolute;
    left: 0px;
    right: 0px;
  }
}
