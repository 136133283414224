@import 'src/styles/variables';
@import 'src/styles/mixins';

.CellThreeCol {
  &__status {
    min-width: 12px;
    min-height: 12px;
    margin-right: 5px;
    border-radius: 50%;
    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__child {
    display: none;
  }
  &__state1 {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: 0px 15px;
    cursor: pointer;
    &:hover {
      .CellThreeCol__child {
        display: flex;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &__state2 {
    border: 1px solid white;
    padding-top: 8px;
    padding-bottom: 8px;
    &-black {
      padding: 8px;
      background-color: #303030;
      color: white !important;
      border-radius: 8px;
      .CellThreeCol__state2-button,
      .CellThreeCol__state2-element,
      .CellThreeCol__state2-select {
        border: none;
        &:hover {
          color: black;
          background-color: #d5edd8;
        }
      }
      .CellThreeCol__state2-select {
        color: black;
      }
    }

    display: flex;
    box-sizing: inherit;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    cursor: pointer;
    .edit-color {
      display: none;
    }

    &-element {
      &:hover {
        background-color: #d5edd8;
        border-radius: 8px;
        height: auto;
      }
    }
    &-button {
      border: 1px solid white;
      &:hover {
        border: 1px solid #d5edd8;
        border-radius: 8px;
        width: 100%;
      }
    }
    &-empty {
      height: 36px;
      width: 100%;
    }

    &-element,
    &-button {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 0 15px;
      min-height: 36px;
      height: 36px;
      width: 100%;
      margin-bottom: 5px;
      &:hover {
        .edit-color {
          display: block;
          margin-right: 5px;
        }
        .CellThreeCol__child {
          display: flex;
        }
      }
    }

    &-select {
      background-color: #d5edd8;
      border-radius: 8px;
      height: auto;
      .CellThreeCol__status-wrapper {
        height: auto;
      }
    }

    &-icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
  &__state3 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    position: relative;
    &-black {
      .CellThreeCol__state3-name {
        color: #d5edd8;
      }
    }
    &-percent {
      z-index: 2;
      top: 0;
      left: 0;
      height: 100%;
      position: absolute;
      border-radius: 5px 0px 0px 5px;
    }
    &-name {
      z-index: 3;
    }
    &-gray {
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: #fafafa;
      border-radius: 5px;
    }
  }
}
