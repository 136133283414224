@import 'src/styles/variables';
@import 'src/styles/mixins';

.scaleAccordion {
  cursor: pointer;

  &__arrowDown {
    transform: rotate(270deg);
    opacity: 0;
  }
  &__arrowUp {
    transform: rotate(90deg);
    opacity: 0;
  }
  &__wrapper_arrow {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  &__tasksList {
    overflow-y: auto;
  }
  &__header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: $theme-color-black;
  }
  .default__button {
    border: 1px solid #149939;
    &:hover {
      color: white !important;
    }
  }
  &__span {
    width: 15px;
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-content: center;
    transform: rotate(180deg);
    &.open {
      transform: rotate(0);
    }
  }

  &__title {
    margin: 0 0 0 40px;
    width: 100%;
    text-align: start;
    @include text($text-secondary, $font-family, $f-size16, 20px, 500, normal);
    display: flex;
    align-items: center;
    &-col1 {
      width: $scale-table-col1;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    &-col2 {
      width: $scale-table-col2;
      cursor: pointer;
    }
    &-col3 {
      Button {
        opacity: 0;
      }
      &:hover {
        Button {
          opacity: 1;
        }
      }
    }
  }

  &__taskItem {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $theme-color-darkGray;
  }

  &__taskItemCell {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    margin-bottom: 1px;
    &:hover {
      .scaleAccordion__arrowDown {
        opacity: 1;
        transition: 0.5s;
      }
      .scaleAccordion__arrowUp {
        opacity: 1;
        transition: 0.5s;
      }
    }
    &-item {
      display: flex;
      align-items: center;
    }
    &:nth-of-type(2) {
      justify-content: space-between;
    }
    @include text($text-primary, $font-family, 16px, 19px, 500, normal);
    &:nth-of-type(3) {
      background-color: #303030;
      color: #ffffff;
    }

    .finish_flag {
      margin-right: 7px;
    }
    &.projects {
      width: $scale-table-col1;
      font-size: 16px;
    }
    &.task {
      width: $scale-table-col2;
      border-left: 1px solid $theme-color-gray;
      border-right: 1px solid $theme-color-gray;
    }
    &.name {
      width: $scale-table-col3;
      text-align: left;
    }
  }
  .line {
    &__wrapper {
      display: flex;
      width: 100%;
      margin-left: 20px;
    }
    &__item {
      height: 17px;
    }
    &__success {
      background-color: #b3e4c1;
    }
    &__process {
      background-color: #faf2a7;
    }
    &__empty {
      background-color: #efefef;
    }
    &__problem {
      background-color: #e80025;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }
  }
  &__arrow-wrapper {
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: space-between;
    word-wrap: break-word;
    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 20%;
    }
  }

  &__arrow-up {
    position: relative;
    vertical-align: middle;
    width: 8px;
    height: 7px;
    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 12px 9px 0;
      border-color: #fff transparent transparent transparent;
      display: block;
      width: 0;
      z-index: 1;
    }
    &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 15px 11px 0;
      border-color: #303030 transparent transparent transparent;
      display: block;
      width: 0;
      z-index: 0;
      margin: -1px 0 0 -2px;
    }
  }
  &__arrow-down {
    transform: rotate(180deg);
    margin: 10px 0 0 10px;
  }
  &__wrapper-red {
    padding: 5px;
    width: 100%;
    background-color: #f5f5f5;
    height: 300px;
    position: relative;
    padding: 10px;
    cursor: default;
  }
  &__redItem {
    padding: 5px;
    position: absolute;
    cursor: pointer;
    height: 30px;
    max-width: 200px;
    min-width: 150px;
    display: flex;
    padding: 5px;
    align-items: center;

    &:hover {
      background: #e4e4e4;
      border-radius: 2px;
    }
    &-circle {
      margin-top: 3px;
      background: #e80025;
      border-radius: 3px;
      width: 6px;
      height: 6px;
      min-width: 6px;
      min-height: 6px;
      margin-right: 5px;
      box-sizing: border-box;
    }
  }
}
