@import 'src/styles/variables';
@import 'src/styles/mixins';

.searchInputHeader {
  width: 100%;
  min-width: 240px;
  position: relative;
  &__svg {
    min-width: 20px;
    margin-right: 10px;
  }
  &__selectedText {
    font-weight: bold;
  }
  &__line {
    margin-right: 20px;
    width: 1px;
    height: 20px;
    background-color: #afafaf;
  }
  &__list {
    position: absolute;
    background-color: white;
    width: 100%;
    min-width: 340px;
    top: 40px;
    z-index: 99999;
    color: black;
    text-align: start;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #303030;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 150px);
    box-sizing: border-box;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }
  &__title {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    border-top: 1px solid #efefef;
  }
  &__item {
    height: 45px;
    display: flex;
    align-items: center;
    text-decoration: none !important;
    width: 100%;
    min-width: 340px;
    color: black;
    &:hover {
      background: #efefef;
    }
    padding: 0px 15px;
    cursor: pointer;
  }
  &__wrapper {
    width: 50%;
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  &__input {
    box-sizing: border-box;
    width: 100%;
    padding: 5px 5px 5px 40px;
    background-color: #ffffff;
    outline: none;
    border: none;
    border: 1px solid #efefef;
    border-radius: 2px;
  }

  &__input,
  &__input::placeholder {
    @include text($text-primary, $font-family, $f-size16, 19px, normal, normal);
  }

  &__icon {
    position: absolute;
    top: 5px;
    left: 8px;
    padding: 0;
    border: none;
    background-color: transparent;

    // for safari selector
    @media not all and (min-resolution: 0.001dpcm) {
      top: 8px;
    }

    &:hover {
      cursor: pointer;
      fill: $theme-color-green;
    }
  }
}
