.confirmDeletionModal {
  padding: 20px;

  &__title {
    color: #303030;
    font-family: Roboto Condensed;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }
}
