.ChronometerNote {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px;
  &__wrapTextarea {
    display: flex;
  }
  &__textarea {
    width: 100%;
    margin: 30px;
    min-height: 220px;
    padding: 8px 12px;
    box-sizing: border-box;
    border: 1px solid #868686;
    border-radius: 2px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
    outline: none;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #303030;
  }
}
