@import 'src/styles/variables';
@import 'src/styles/mixins';

.calendarTable {
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &__day {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      border-bottom: 1px solid $theme-color-darkGray;
      height: 31px;
      @include text($text-primary, $font-family, $f-size16, 20px, normal, normal);
      border-right: 1px solid $theme-color-darkGray;

      svg {
        cursor: default;
        pointer-events: none;
      }

      &.today {
        color: $text-secondary;
        background-color: black;
      }

      &:first-child {
        border-left: 1px solid $theme-color-darkGray;
      }
    }

    &.isSub {
      background-color: #fbfbfb;
      justify-content: flex-end;
    }
  }
  &__closeItem {
    height: calc(100% - 4px);
    width: calc(100% - 4px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  &__rowTitle {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    width: 100%;
    padding: 0 4px;
    overflow: hidden;
    border-bottom: 1px solid $theme-color-darkGray;
    height: 31px;
    &--tags {
      display: flex;
      justify-content: 'center';
      align-items: center;
    }

    .linkWrap {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      align-items: center;
      margin-left: 10px;
      a {
        @include text($text-primary, $font-family, $f-size16, 20px, 300, normal);
        text-decoration: none;
        text-align: left;
        word-break: break-all;
        display: flex;
        align-items: center;
        align-items: center;
        &:hover {
          color: $text-active;
        }
      }
    }

    .tagUser {
      .tag__item {
        cursor: pointer !important;
      }
      .tag__text.hover:hover {
        cursor: pointer !important;
      }
    }
    & .tagsList {
      display: flex;

      .tag__item {
        margin-left: 8px;
        cursor: default;

        .tag__text.hover:hover {
          cursor: default;
        }
      }
    }
  }
}
