@import 'src/styles/variables';
@import 'src/styles/mixins';

.accordionItem {
  &__header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: $theme-color-black;

    &:hover {
      cursor: pointer;
    }
    &-inner {
      display: flex;
      align-items: center;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-content: center;
    transform: rotate(180deg);
    &.open {
      transform: rotate(0);
    }
  }

  &__title {
    margin: 0 0 0 20px;
    text-align: left;
    @include text($text-secondary, $font-family, $f-size16, 20px, 500, normal);
  }
}
