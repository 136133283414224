@import 'src/styles/variables';
@import 'src/styles/mixins';

.options-menu {
  .popover {
    display: none;
    position: absolute;
    left: 165px;
    top: 70px;
    background-color: $text-primary;
    border-left: none;
  }

  &__container {
    display: flex;
    align-items: center;
    margin-right: 15px;
    @include text($text-secondary, $font-family, $f-size14, 16px, 400, normal);

    svg {
      width: 20px;
      height: 20px;
      margin: 5px 10px 5px 0;

      &:hover {
        background-color: inherit;
        border-left: none;
      }

      path {
        fill: $text-secondary;
      }
    }

    label {
      &:hover {
        cursor: pointer;
        color: gray;
      }
    }

    .options-menu__children-arrow {
      cursor: pointer;
      border-right: 1px solid $text-secondary;
      border-bottom: 1px solid $text-secondary;
      transform: rotate(-45deg);
      width: 6px;
      height: 6px;
      margin: 13.5px 0 13.5px 20px;
      position: relative;
    }

    .popover:last-child {
      position: absolute;
      left: 194px;
      top: 215px;
      width: max-content;
      margin: 7px 0;
    }
  }

  &__children-container {
    margin: 7px 0;
    text-align: start;
  }
}

.options-menu_open {
  .popover {
    display: flex;
  }
}
