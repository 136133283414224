.TreadItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    &-inner {
      display: flex;
    }
  }
  &__displayNone {
    display: none;
  }
  &__header {
    background-color: #303030;
    color: #fbfbfb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    text-align: start;
    margin-bottom: 20px;
  }
  &__headerHide {
    color: #303030;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 10px;
    text-align: start;
  }
  &__avatar {
    display: flex;
    &-image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
    }
    &-name {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
    }
    &-count {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
    }
    &-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
  &__data {
    display: flex;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: right;

    color: #fbfbfb;
    &-date {
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
      }
    }
    &-number {
      margin-left: 35px;
      margin-right: 20px;
    }
  }

  &__comment {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #303030;
    text-align: start;
    margin-bottom: 20px;
    display: flex;
    &-image {
      height: 400px;
      width: 400px;
    }
  }
  &__ButtonWrapper {
    &-outer {
      opacity: 0;
      display: flex;
      &:hover {
        opacity: 1;
      }
    }
    &-inner {
      display: flex;
      align-items: center;
      margin-right: 15px;
      cursor: pointer;
      svg {
        margin-right: 5px;
      }
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }
  &__line {
    width: 100%;
    height: 1px;
    background-color: #afafaf;
    margin: 20px 0px;
  }
  &__line2 {
    width: 100%;
    height: 1px;
    background-color: #afafaf;
    margin: 30px 0px;
  }
}
