.changeKarmaModal {
  padding: 20px;
  &__title {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #303030;
    margin-bottom: 15px;
  }
  &__buttons {
    display: flex;
    align-items: center;
  }
  &__avatar {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: red;
    &-wrapper {
      display: flex;
      align-items: center;
    }
  }
  &__user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  &__name {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #303030;
    margin-left: 10px;
  }
  &__count {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #149939;
    &-positive {
      color: #149939;
    }
    &-negative {
      color: #ee3944;
    }
  }
}
