.TemplateProjectI {
  position: absolute;
  background-color: white;
  transform: translate(-50%);
  width: 240px;
  padding: 20px;
  z-index: 999;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25), 0 1px 5px rgba(48, 48, 48, 0.1);
  border: 1px solid #e7e6e6;
  .default__button {
    border: none;
  }
  .appInput__wrapper,
  .appInput__field {
    width: 100%;
  }
  &__title {
    display: flex;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #303030;
    margin-bottom: 30px;
  }

  &__errors {
    color: red;
    margin-top: 5px;
    text-align: end;
  }
  &__colorPanel {
    position: absolute;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    padding: 5px;
  }
  .appInput__wrapper {
    margin: 0px;
    border-bottom: 0px;
    margin-left: 5px;
  }

  &__selector2 {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
    .appSelect__wrapper {
      margin-bottom: 0px;
    }
    border-bottom: 1px solid #afafaf;
  }
  &__inputWrapper {
    border: 1px solid #afafaf;
    padding-left: 5px;
    padding-top: 3px;
    padding-bottom: 2px;
    height: 32px;
    width: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  &__addRight {
    width: 10%;
    padding-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 7px;
  }
}
