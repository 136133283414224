@import '../../../styles/variables';
@import '../../../styles/mixins';

.menu-text-actions__font-size-select {
  display: flex;
  align-items: center;

  span {
    @include text($text-secondary, $font-family, $f-size16, 15px, 400, normal);
  }

  .size-icon {
    margin: 0;
    padding-right: 3px;
  }

  .arrow-small-icon {
    margin: 0 3px 0 0;
  }

  &:hover {
    cursor: pointer;
    background-color: $theme-color-darkGray;
  }

  & .options-menu_open {
    display: flex;
    justify-content: center;
    position: absolute;
    max-height: 150px;
    width: 65px;
    top: 70px;
    padding: 5px 0;
    background-color: $theme-color-black;
    overflow-y: scroll;
    overflow-x: hidden;
    border-left: none;
    z-index: 999;

    .popover {
      position: relative;
      left: 0;
      top: 77px;
      justify-content: center;
      align-items: center;
      padding: 5px;

      .options-menu__container {
        margin: 5px 0 5px 0;
        padding: 5px 0;
      }
    }
  }
}

.menu-text-actions__font-styles {
  display: flex;
  align-items: center;
}

.menu-text-actions__align {
  display: flex;
}
