@import 'src/styles/variables';
@import 'src/styles/mixins';

.circlesStats {
  max-width: 860px;
  display: flex;
  align-items: center;
  &__serch {
    font-family: Roboto Condensed, sans-serif;
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
    color: #303030;
    font-style: normal;
    box-sizing: border-box;
    padding: 5px 5px 5px 5px;
    background-color: #ffffff;
    outline: none;
    border: none;
    border: 1px solid #efefef;
    border-radius: 2px;
    margin-left: 5px;
  }
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #747171;
    height: 30px;
    border-radius: 2px;
    background: #dbd9d9;
    padding: 4px 8px;
    box-sizing: border-box;
    &-item {
      font-family: Roboto Condensed;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-right: 8px;
    }
    &-number {
      color: #303030;
      font-family: Roboto Condensed;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 2px;
    }
  }
}
