@import 'src/styles/variables';
@import 'src/styles/mixins';

.CreateChapterModal {
  @include text($text-primary, $font-family, 14px, 16px, 300, normal);
  padding: 18px;
  max-width: 325px !important;
  width: 325px;
  z-index: 10;
  min-width: 325px;
  max-height: 80vh;
  overflow-y: auto;
  .appInput__wrapper {
    input {
      width: 100%;
    }
  }
  &__title {
    font-weight: 400;
  }
  &__inputWrapper {
    margin-top: 30px;
  }
  &__icons {
    margin-right: 10px;
  }
  &__selectorWrapper {
    display: flex;
    align-items: center;
    border-bottom: 1px solid gray;
    box-shadow: 1px 1px 5px rgba(48, 48, 48, 0.1);
    margin-bottom: 30px;
    .appSelect__wrapper {
      margin-bottom: 0;
    }
    .appSelect__control,
    .appSelect__single-value {
      background-color: white !important;
    }
  }
}
