@import 'src/styles/variables';
@import 'src/styles/mixins';

.popover {
  position: absolute;
  display: none;
  flex-direction: column;
  border-left: 5px solid $text-primary;
  padding: 15px 20px;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25), 0 1px 5px rgba(48, 48, 48, 0.1);
  z-index: 10;
}