@import '../../../styles/variables.scss';
.TredItemVerbs {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #303030;
    margin-bottom: 5px;
  }
  position: relative;
  &__body {
    background-color: #f5f5f5;
    padding: 10px;
    //minus title and padding of performers
    height: calc(#{$TredItem__content-right} - 24px - 20px);
    overflow-y: auto;
    &-tooltop {
      max-width: 250px; /* или другое значение ширины */
      white-space: normal;
      word-wrap: break-word;
      word-break: break-all;
      overflow-wrap: break-word;
    }
    &-item {
      width: 100%;
      background: #ffffff;
      border-radius: 2px;
      height: 22px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;

      &__status {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin: 0px 5px;
      }
      &__edit {
        margin-left: auto;
        display: flex;
        align-items: center;
      }
    }
  }
}
