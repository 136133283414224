.Treads {
  position: relative;
  width: 100%;
  height: calc(100vh - 45px);
  overflow-y: auto;
  overflow-x: hidden;
  &__pagination {
    left: 50%;
    position: absolute;
    bottom: 0px;
    transform: translate(-50%, -50%);
  }
  &__cover {
    width: 100%;
    height: calc(100vh - 55px);
    display: flex;
    justify-content: center;
  }

  &__canvas {
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    bottom: 0px;
    background-color: black;
  }
  &__inner {
    max-width: 1430px;
    @media (max-width: 1370px) {
      max-width: 1178px;
    }
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(4, minmax(130px, 200px)) auto;
    display: grid;
    margin-bottom: 55px;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-y: hidden;
    z-index: 1;
    position: relative;
    width: 100%;
    min-height: calc(100% - 20px);
    padding: 10px 0px 0px 0px;
    flex-direction: column;
  }

  .item {
    margin: 5px 5px;
    &__empty {
      grid-column: 2/-2;
      grid-row-start: 2;
      grid-row-end: 4;
      z-index: -2;
    }
  }
}
