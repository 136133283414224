@import 'src/styles/variables';
@import 'src/styles/mixins';

.expensesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  h2 {
    text-align: left;
    padding-left: 18px;
    @include text($text-primary, $font-family, 26px, 19px, 500, normal);
  }

  &__counts {
    display: flex;

    span {
      @include text($text-primary, $font-family, 20px, 24px, 300, normal);
      margin-right: 30px;
    }
  }

  .calendarPicker {
    position: absolute;
    top: 20%;
    left: 34%;
  }
}