@import 'src/styles/variables';
@import 'src/styles/mixins';

.WrapperUserStatisticBlock {
  display: none;
  width: 300px;
  position: absolute;
  z-index: 999999;
  margin-top: -5px;
  margin-left: -180px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.4);
  .userStatisticBlock {
    &:hover {
      .userStatisticBlock__items {
        border-left-color: white;
        border-right-color: white;
      }

      nav {
        border-left-color: white;
        border-right-color: white;
      }

      .userStatisticBlock__line {
        border-bottom: 1px solid white;
      }
    }
  }
}
